import {ConfigurationSpec, ConfigurationValue} from "../../models/configuration";
import _ from "lodash";
import {isArray} from "util";

export function emptyConfigurationValue(c: ConfigurationSpec): ConfigurationValue {
  return {
    name: c.name,
    value: ""
  };
}

export function validateValues(cvs: ConfigurationValue[], cs: ConfigurationSpec, messages: Record<string, string>): string[] {
  let errors: string[] =  [];
  const value: ConfigurationValue | undefined = _.first(cvs.filter((cv) => cv.name === cs.name));

  // if(value === undefined) errors = [...errors, `${cs.name} ${messages["not_found"]}`]

  switch (cs.type) {
    case "string":
      if((value?.value ?? "").toString().length === 0) errors = [`${value?.name ?? cs.name} ${messages["should_not_be_empty"]}`, ...errors]
      break;
    case "integer":
      if(!isNumber(value?.value?.toString() ?? "")) errors = [`${value?.name ?? cs.name} ${messages["should_be_a_number"]}`, ...errors]
      break;
    case "map":
      break;
    case "float":
      if(!isNumber(value?.value?.toString() ?? "")) errors = [`${value?.name ?? cs.name} ${messages["should_be_a_number"]}`, ...errors]
      break;
    case "datetime":
      break;
    case "boolean":
      break;
    case "decimal":
      if(!isNumber(value?.value?.toString() ?? "")) errors = [`${value?.name ?? cs.name} ${messages["should_be_a_number"]}`, ...errors]
      break;
    case "array_string":
      try {
        if(!isArray(JSON.parse(value?.value?.toString() ?? ""))) errors = [`${value?.name ?? cs.name} ${messages["should_be_a_json_valid_string_array"]}`, ...errors]
      } catch (e) {
        errors = [`${value?.name ?? cs.name} ${messages["should_be_a_json_valid_string_array"]}`, ...errors]
      }

      break;
    case "configuration":
      break;

  }

  return errors;
}

function isNumber(value: string | number): boolean
{
  return ((value != null) &&
    (value !== '') &&
    !isNaN(Number(value.toString())));
}

export function updateConfigurationValues(cvs: ConfigurationValue[], cs: ConfigurationSpec, value: string | number, intl: Record<string, string>): ConfigurationValue[] {
  return [...cvs.filter(cv => cv.name !== cs.name), {name: cs.name, value}];
}

export function configToConfigurationValues(config: Object): ConfigurationValue[] {
  let configurationValues: ConfigurationValue[] = [];

  for(const index in Object.keys(config)) {
    configurationValues = [
      ...configurationValues,
      {
        name: Object.keys(config)[index],
        value: Object.values(config)[index].toString()
      }
    ];
  }

  return configurationValues;
}

export function configurationValuesToConfig(configurationValues: ConfigurationValue[]): Object {
  let config: any = {}

  for(const cv of configurationValues) {
    config[cv.name.toLowerCase()] = cv.value;
  }

  return config;
}