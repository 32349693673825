import {LogAction} from "../actions/logs_actions";
import {Log} from "../../models/log";
import {Channel, Socket} from "phoenix";

export type LogState = {
  items: Log[],
  channels: {channel: Channel, name: string}[],
  eventsSubscribed: string[],
  socket: Socket | null;
}

const initialState: LogState = {
  items: [],
  channels: [],
  eventsSubscribed: [],
  socket: null,
};

const LOG_MAX_LIMIT = 80;

export default function(state: LogState = initialState, action: LogAction): LogState {
  switch (action.type) {
    case 'ADD_LOG': {

      if(state.items.filter((l) => l.channel === action.channelName).length > LOG_MAX_LIMIT) {
        const logs: Log[] = [...state.items.filter((l) => l.channel === action.channelName).slice(0, -1), ...state.items.filter((l) => l.channel !== action.channelName)];
        return {
          ...state,
          items: [
            {
              log: action.log,
              channel: action.channelName,
              eventName: action.eventName
            },
            ...logs
          ]
        };
      }

      return {
        ...state,
        items: [
          {
            log: action.log,
            channel: action.channelName,
            eventName: action.eventName
          },
          ...state.items
        ]
      };
    }

    case "UPDATE_SUBSCRIPTIONS":
      return {
        ...state,
        channels: [{channel: action.channel, name: action.channelName}, ...state.channels.filter(c => c.name !== action.channelName)],
        eventsSubscribed: [action.eventName, ...state.eventsSubscribed.filter((e) => e !== action.eventName)],
      }

    case "UPDATE_SOCKET":
      return {
        ...state,
        socket: action.socket
      }

    default:
      return state;
  }
}
