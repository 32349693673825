import {Dispatch, Middleware, MiddlewareAPI, Store} from "redux";
import {
  addLogAction,
  LogAction,
  subscribeToAction,
  updateSocketAction,
  updateSubscriptionsAction
} from "../actions/logs_actions";
import store from "../store";
import {LogState} from "../reducers/log_reducer";
import {Socket} from "phoenix";
import {refreshWebSocket} from "../../repsitory/dashboards_repository";
import {WEB_SOCKET_ADDRESS} from "../../index";

export const logEffects: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch) => ((action: LogAction) => {

    (async () => {
        switch (action.type) {
            case "SUBSCRIBE_TO":
                let state: LogState = store.getState().logs;
                if(state.socket === null) {

                    await subscribeSocket(store);

                    if(store.getState().logs.socket === null) {
                        console.error(`Unable to connect to ${action.channelName} - ${action.eventName}, socket closed`);
                        return;
                    }
                }

                state = store.getState().logs;

                // console.log(`[${action.channelName}][${action.eventName}] Subscribing`);

                //  Avoid duplicate subscriptions
                if(state.eventsSubscribed.find((n) => n === action.eventName) && state.channels.map(c => c.name).find((c => c === action.channelName))) {
                    // console.log(`[${action.channelName}][${action.eventName}] Already subscribed`);
                    return;
                }

                //  Find channel if present
                let storeChannel = state?.channels?.find(c => c.name === action.channelName)?.channel;
                const channelIsConnected = storeChannel !== undefined;

                //  Connect if not present
                if(storeChannel === undefined) {
                    storeChannel = state.socket!.channel(action.channelName);
                }

                //  Subscribe to event
                storeChannel.on(action.eventName, (m) => {
                    // console.log(`[${action.channelName}][${action.eventName}] <- ${JSON.stringify(m)}`);
                    try {
                        if(m.messages[0] !== undefined) {
                            m.messages.forEach((m: any) => store.dispatch(addLogAction(m, action.channelName, action.eventName)))
                        }
                    } catch (e) {
                        console.error(e)
                        store.dispatch(addLogAction(m, action.channelName, action.eventName));
                    }


                });

                //  Connect if channel wasn't present
                if(!channelIsConnected) {
                    storeChannel.join()
                      .receive("ok", resp => {
                          // console.log(`Joined successfully ${action.channelName}`, resp)
                      })
                      .receive("error", resp => {
                          // console.log(`Error on ${action.channelName}: `, resp)
                      });
                }

                // console.log(`[${action.channelName}][${action.eventName}] Subscribed`);

                //  Dispatch update of channel and subscriptions in store
                next(updateSubscriptionsAction(action.channelName, action.eventName, storeChannel));

            default: next(action)
        }
    })()



}) as Dispatch;

export const subscribeSocket = async (store: Store) =>  {
    const s = new Socket(WEB_SOCKET_ADDRESS);
    s.connect({token: `${store.getState().auth.token}`});
    store.dispatch(updateSocketAction(s));

    //  fixed subscriptions
    store.dispatch(subscribeToAction("oban_channel", "new_oban_msg"))
    store.dispatch(subscribeToAction("meters_channel", "meter_count"))
    store.dispatch(subscribeToAction("meters_channel", "meter_in_error"))
    store.dispatch(subscribeToAction("meters_channel", "meter_installed"))
    store.dispatch(subscribeToAction("meters_channel", "meter_managed"))
    store.dispatch(subscribeToAction("gateways_channel", "gateway_alarmed"))
    store.dispatch(subscribeToAction("gateways_channel", "gateway_offline"))
    store.dispatch(subscribeToAction("gateways_channel", "gateway_installed"))
    store.dispatch(subscribeToAction("altior_messages_channel", "new_reading"))

    refreshWebSocket();

}
