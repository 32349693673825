import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useIntl} from 'react-intl';
import Select from 'react-select';
import {useHistory} from "react-router-dom";
import {ErrorComponent} from '../../components/error_component';
import * as vm from './edit_user_page_vm';
import {createEditUser, mkUserState} from './edit_user_page_vm';
import {EditableRow} from "../../components/InfoRow";
import {emptyUserGroup, User} from "../../models/user";
import {UserGroup} from "../../models/user_group";
import {fetchAllGroups, fetchUserGroups} from "../../repsitory/user_repository";


export function CreateEditUserPage() {

  const history = useHistory();
  const user: User = (history?.location?.state as User) || emptyUserGroup();


  const [state, setState] = useState<vm.EditUserPageState>(mkUserState(user));

  const dispatch = useDispatch();
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [initialUserGroups, setInitialUserGroups] = useState<UserGroup[]>([]);
  const [selectValue, setSelectValue] = useState<{label: string, value: string}[]>([]);


  useEffect(() => {
      (async () => {
          const initialUserGroups = (state.id && (await fetchUserGroups(state.id))) || [];
          const allGroups = await fetchAllGroups();
          setGroups(allGroups);
          setInitialUserGroups(initialUserGroups);
          setSelectValue(initialUserGroups.map(ug => ({label: ug.name, value: ug.id})));
          setState({...state, groups: initialUserGroups.map(ug => ug.id)});
      })();
  }, [0, state.id]);


  const intl = useIntl();

  if(state.error !== "") return <ErrorComponent message={state.error}/>;
  if(state.loading) return <p>Loading</p>;

  return (
    <div>
      <div className={"row"}>
        <div className={"col-md-12"}>
          <EditableRow title={"username"} value= {state.username} onEdit={!state.id
            ? (s) => setState({...state, username: s})
            : undefined
          } />
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12"}>
          <EditableRow title={"email"} value= {state.email} onEdit={ !state.id
            ? (s) => setState({...state, email: s})
            : undefined
          } />
        </div>
      </div>

      <div className={"row"}>
        <div className={"col-md-12"}>
          <div className={"mb-4"}>
            <h3>{intl.messages["groups"].toString().toUpperCase()}</h3>
            <Select
                onChange={(g: any) => {
                  setState({...state, groups: g?.map((g: any) => g.value)});
                  setSelectValue(g);
                }}
                value={selectValue}
                isMulti
                options={groups.map((g) => ({ value: g.id, label: g.name }))}
                name="groups"
                className="basic-multi-select"
                classNamePrefix="select"
            />
          </div>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12"}>
          <EditableRow title={intl.messages["badge_id"].toString()} value= {state.badge_id} onEdit={(s) => setState({...state, badge_id: s})} />
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12"}>
          <EditableRow title={intl.messages["status"].toString()} value= {state.status} onEdit={(s) => setState({...state, status: s})} />
        </div>
      </div>

      <div className={"row mt-3"}>
        <div className={"col-md-12 ellipsis mt-1"}>
          <div className={"d-flex flex-row-reverse"}>
            <button className={"p3 primary-button"}  onClick={() => { createEditUser(state, initialUserGroups, setState, dispatch, history) }}>
              {intl.messages['save']}
            </button>
            <button className={"p3 outline-button mr-3"} onClick={() => history.goBack() }>
              {intl.messages['cancel']}
            </button>
            <p className={"p-2 mr-5"}>{ state.validationErrors.join(", ") }</p>
          </div>
        </div>
      </div>
    </div>
  );
}
