import React, {Key} from "react";
import {InputText} from "./input_text";


type Props = {
  title: string,
  value: string,
}

export const InfoRow = (props: Props) => {
  return(
    <div className={"row mb-4"}>
      <div className={"col-md-12 d-flex flex-row"}>
        <h3 className={"flex-grow-1"}>{ (props.title.toString().toUpperCase()) || "missing" }</h3>
        <p className={""}>{ props.value }</p>
      </div>
    </div>
  )
};


type EditableProps = {
  title: string,
  value: string,
  onEdit?: (s: string) => void
  hidden?: boolean
  key?: Key
}

export const EditableRow = (props: EditableProps) => {
  if(props.onEdit === undefined)
  return(
    <div className={"row mb-4"} key={props.key}>
      <div className={"col-md-12"}>
        <h3 className={""}>{ (props.title.toString().toUpperCase()) || "missing" }</h3>
        <p className={"pt-2"}>{ props.value ?? "--" }</p>
      </div>
    </div>
  );

  return(
    <div>
      <h3>{ (props.title.toString().toUpperCase()) || "missing" }</h3>
      <InputText value={props.value} onChange={props.onEdit} hint={""} hidden={props.hidden}/>
    </div>
  );

};