import {TrashIcon} from "../../../components/icons";
import React from "react";
import {Filter, FilterName} from "../../../models/filter";
import {formatValueName} from "../ast/ast";

type Props = {
  filter: Filter,
  onClose: (s: string) => void,
  onClick: (f:Filter) => void,
  className?: string,
  intl: Record<string, string>
  // onAddSibling: () => void
}

const formatter = (name: FilterName) => {
  switch (name) {
    case "date_greater": return (i: string) => {
      const d = new Date(i);
      return (`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`);
    }
    case "date_smaller": return (i: string) => {
      const d = new Date(i);
      return (`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`);
    }
    default: return (i: string) => i
  }
}

export function FilterWidget(props: Props) {

  return <div className={`filter-widget p-05 ${props.className ?? ''}`} onClick={() => props.onClick(props.filter)}>
    <div className={"d-flex flex-row justify-content-between"} style={{marginRight: -5}}>
      <h3 className={"filter-widget-content"}>{props.intl[props.filter.name] ?? formatValueName(props.filter.name, props.intl)}</h3>
      <button className={"icon-button"} onClick={(e) => {
        e.stopPropagation();
        props.onClose(props.filter.name);
      }}>
        <TrashIcon/>
      </button>
    </div>
    <h3 className={"filter-widget-content"}>{
      // @ts-ignore
      formatter(props.filter.name)(props.filter.value)
    }</h3>
  </div>
}