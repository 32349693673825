import React, {useState} from "react";
import {DataSource} from "../ast/ast";
import {TrashIcon} from "../../../components/icons";
import "./data_source_widget.css";
import {useSpring, animated} from 'react-spring';

type Props = {
  selectedDataSource: DataSource
  intl: Record<string, string>
  onRemove: (d: DataSource) => void,
  initialCollapsed: boolean
}

export function DataSourceWidget(props: Props) {

  const [collapsed, setCollapsed] = useState(props.initialCollapsed);
  const width = useSpring({width: collapsed ? 65 : 250})

  let widget;
  if(collapsed) { widget = (
      <div className={`collapsed p-05`} style={{height: 110, width:68}}>
        <h3 className={"data-source-value"}>{
          props.intl[props.selectedDataSource!]
        }</h3>
      </div>
  );
  } else {
    widget =
      <div className={`filter-widget p-05`} style={{height: 110}}>
        <div className={"d-flex flex-row justify-content-between"} style={{marginRight: -5}}>
          <h3 className={"filter-widget-content"}>{props.intl["data_source"]}</h3>
          <button className={"icon-button"} onClick={(e) => {
            e.stopPropagation();
            props.onRemove(props.selectedDataSource);
          }}>
            <TrashIcon/>
          </button>
        </div>
        <h3 className={"filter-widget-content"}>{
          props.intl[props.selectedDataSource!]
        }</h3>
      </div>
  }

  return <animated.div style={width} className={"collapsed-container"}>
    {widget}
    <animated.button className={`icon-button collapsed-button ${collapsed ? "mr-1" : "mr-2"}`}
            onClick={() => setCollapsed(!collapsed)}>{collapsed ? ">" : "<"}</animated.button>
  </animated.div>


}