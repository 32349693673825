import React from "react";

export function AddFilterWidget(props: {onAdd: () => void, text: String, margin_top?: boolean, className?: string}) {
  return <div className={`filter-widget-button add ${(props.margin_top ?? true) ? " right" : " no-margin_top bottom"} ${props.className ?? ""}`} onClick={props.onAdd} >
    <button className={"icon-button"} onClick={(e) => {
      e.stopPropagation();
      props.onAdd();
    }}>
      <h2 className={"filter-widget-content"}>{props.text.toUpperCase()}</h2>
    </button>
  </div>
}