import {confirmAlert} from "react-confirm-alert";
import React, {useRef, useState} from "react";
import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {bigNumberFormatter, titleCase} from "../../../utils/object_formatting";
import {prune_max_len} from "../../../utils/arrays";

export async function showChartModal(intl: Record<string, string>, data: any, x_key: string, y_key: string, header: string) {
  confirmAlert({
    customUI: ({onClose}) =>
      <div
        style={{minHeight: 150, overflowX: "auto", backgroundColor: "white"}}
        className={""}
      >
        <ChartModalWidget intl={intl} header={header} data={data} x_key={x_key} y_key={y_key} close={onClose}/>
      </div>
  });
}

function ChartModalWidget(props: {intl: Record<string, string>, data: any, x_key: string, y_key: string, header: string, close: () => void}, ) {
  return <div style={{width: 1200}} className={"chart_modal p-3 pt-4"}>
    <h2 style={{marginLeft: 50, marginBottom: 30}}>{titleCase(props.header)}</h2>
      <ResponsiveContainer width={"100%"} height={450} className={"dashboard-chart"}>
        <LineChart data={prune_max_len(props.data, 400)}>
          <Line type="monotone" dataKey={props.y_key} stroke="#797979" />
          <XAxis dataKey={props.x_key} />
          <YAxis stroke={"#797979"} tickFormatter={bigNumberFormatter} />
          <Tooltip labelStyle={{color: "black"}}/>
        </LineChart>
      </ResponsiveContainer>
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.close()
        }}>{props.intl["close"]}</button>
      </div>
    </div>
    </div>
}
