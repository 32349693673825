import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useIntl} from "react-intl";
import {Group} from "../../../models/group";
import {Link} from "react-router-dom";
import moment from "moment";
import {PaginatedListComponent} from "../../../components/paginated_list/paginated_list_component";
import {fetchPaginatedCollection, remoteFetchData} from "../../../repsitory/generic_repository";
import {showFirmwareCreationModal} from "./firmware_creation_page";
import DatePicker from "react-datepicker";
import _ from "lodash";
import {confirmAlert} from "react-confirm-alert";

import {NotSelectedIcon, SelectedIcon, TrashIcon} from "../../../components/icons";
import {deleteFirmware} from "./firmware_page_vm";
import {createScheduleFirmwareJob, ScheduleFirmwareState} from "./schedule_firmware_vm";
import {history} from "../../../index";
import {AltiorMeterType} from "../../../models/altior_meter_type";
import * as vm from "../edit_meter_page_vm";
import AsyncSelect from "react-select/async";


export function ScheduleFrimwareUpdate() {
  const intl = useIntl();
  const [groups, setGroups] = useState<Group[]>([]);
  const [state, setState] = useState<ScheduleFirmwareState>({
    groupId: -1,
    meters: 0,
    start: new Date(),
    stop: new Date(),
    jobSchedule: new Date(),
    error: "",
    firmwareId: -1,
  });

  const [initialPage, setInitialPage] = useState(0);
  const [altiorMeterTypes, setAltiorMeterTypes] = useState<AltiorMeterType[]>([]);

  const [deviceTypeSelectValue , setDeviceTypeSelectValue ] = useState<{value: string, label: string}>({value: state.deviceTypeId ?? "-1", label: "loading"})

  useEffect(() => {
    (async () => {
      vm.fetchMeterTypes().then((mt) => {
        if(typeof mt === "string") {
          setState({...state, error: mt});
        } else {
          setAltiorMeterTypes(mt);

          const deviceTypeId: string = (state.deviceTypeId ?? _.first(mt)?.deviceTypeId ?? "-1");
          const label = _.first(mt?.filter((mt) => mt.deviceTypeId === deviceTypeId) ?? [])?.name ?? "Error fetching device types";

          setDeviceTypeSelectValue({ label, value: deviceTypeId})
          setState({...state, deviceTypeId: _.clone(state.deviceTypeId)})
        }
      })
    })()
  }, [0]);

  return (<div>
    <div className={"row"}>
      <div className={"col-md-12 pt-1"}>
        <h3 className={"mt-3"}>{intl.messages["default_group"].toString().toUpperCase()}</h3>
        <AsyncSelect
          defaultValue={{label: "", value: -1}}
          onChange={(g: any) => setState({...state, groupId: g.value })}
          isMulti={false}
          cacheOptions
          defaultOptions
          loadOptions={ (input: string) => {
            return remoteFetchData<Group[]>(`/api/v1/group?name=${input}`).then((gr) => {
              if(typeof gr !== "string") {
                setGroups(gr);
                return gr.map((g) => ({ value: g.attributes.id, label: g.attributes.name }));
              } else {
                setState({...state, error: intl.messages["cannot_fetch_meter_groups"].toString()})
                return [];
              }
            })
          } }

          name="groups"
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
    </div>
    <div className={"row mt-3"}>
      <div className={"col-md-12"}>
        <div className={"mb-2"}>
          <h3>{intl.messages["device_type_id"].toString().toUpperCase()}</h3>
          <Select
            value={ deviceTypeSelectValue }
            onChange={(g: any) => {
              setState({...state, deviceTypeId: g.value});
              setDeviceTypeSelectValue(g);
            }}
            isMulti={false}
            options={altiorMeterTypes?.map((g: any) => ({ value: g.deviceTypeId, label: g.name }))}
            name="device type id"
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12 pt-1"}>
        <h3 className={"mt-3"}>{intl.messages["meters"].toString().toUpperCase()}</h3>
        <p className={"mt-2"}>{ _.first(groups.filter(g => g.id === state.groupId.toString()))?.attributes?.metersCount ?? "" }</p>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12"}>
        <h3 className={"mt-3"}>{intl.messages["start"].toString().toUpperCase()}</h3>
        <input
          className="form-control"
          type="date"
          data-date-format="DD/MM/YYYY"
          defaultValue={state.start.toDateString()}
          value={moment(state.start).format("YYYY-MM-DD")}
          onChange={ (s) => setState({...state, start: new Date(s.target.value)}) }
        />
      </div>
    </div>
    <div className={"row mt-2"}>
      <div className={"col-md-12"}>
        <h3 className={"mt-3"}>{intl.messages["stop"].toString().toUpperCase()}</h3>
        <input
          className="form-control"
          type="date"
          data-date-format="DD/MM/YYYY"
          defaultValue={state.stop.toDateString()}
          value={moment(state.stop).format("YYYY-MM-DD")}
          onChange={ (s) => setState({...state, stop: new Date(s.target.value)}) }
        />
      </div>
    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12"}>
        <h3> {intl.messages["schedule_date"].toString().toUpperCase()} </h3>
        <DatePicker
          className={"form-control"}
          selected={state.jobSchedule}
          onChange={ (date: Date) => setState({...state, jobSchedule: date})}
          showTimeSelect
          dateFormatCalendar={"dd-MM-yyyy"}
          timeFormat="HH:mm"
          timeIntervals={5}
          timeCaption="time"
          dateFormat="dd-MM-yyyy h:mm aa"
        />
      </div>
    </div>
    <h3 className={"mt-4"}>{intl.messages["Select firmware from list"].toString().toUpperCase()}</h3>
    <PaginatedListComponent
      headers={
        ["id", "name", "selected", "actions"]
      }
      initialPage={initialPage}
      rowBuilder={
        (firmware: any) => [
          <p>{firmware.id}</p>,
          <p>{firmware.name}</p>,
          <div className={"pl-2"}>{(firmware.id === state.firmwareId) ? <SelectedIcon/> : <NotSelectedIcon/>}</div>,
          <button className={"icon-button"} onClick={(e) => {
            e.stopPropagation();
            confirmAlert({
              title: intl.messages["confirm"].toString(),
              message: intl.messages["this_action_cant_be_undone"].toString(),
              buttons: [
                {
                  label: 'Yes',
                  onClick: async () => {
                    setState({...state, error: await deleteFirmware(firmware.id)});
                    setInitialPage(0);
                    setInitialPage(1);
                  }
                },
                {
                  label: 'No',
                  onClick: () => {}
                }
              ]
            });

          }}>
            <TrashIcon/>
          </button>
        ]
      }
      fetchCollection={
        fetchPaginatedCollection<any>("/api/v1/firmware")
      }
      perPage={8}
      onTap={ (g) => {
        setState({...state, firmwareId: g.id})
      } }
    />


    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row justify-content-between"}>
        <div>
          <button
            className={"primary-button"}
            onClick={() => showFirmwareCreationModal({
              intl: intl.messages as any, onClose(): void {
                setInitialPage(1);
                setInitialPage(0);
              }
            })}
          >
            {intl.messages["new_firmware"].toString()}
          </button>
        </div>

        {state.error && <p className={"error-message"}>{state.error}</p>}

        <div className={"d-flex flex-row-reverse"}>
          <button
            className={"primary-button ml-2"}
            onClick={ () => createScheduleFirmwareJob(state, setState, history, intl.messages as any) }
          >
            {intl.messages["schedule"].toString()}
          </button>
          <Link className={"outline-button"} to={"/meters"}>{"Cancel"}</Link>
        </div>

      </div>
    </div>


  </div>);
}