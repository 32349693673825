import {Dispatch, Middleware, MiddlewareAPI} from "redux"
import {createUpdateDashboard, deleteDashboard, fetchDashboards} from "../../repsitory/dashboards_repository";
import {DashboardAction, editDashboardAction} from "../actions/dashboard_actions";


export const dashboardEffects: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch) => ((action: DashboardAction) => {

  switch (action.type) {
    case "FETCH_DASHBOARDS":
      next(action);
      console.log("Fetching dashboards");

      fetchDashboards().then((dashboards) => {
        if(typeof dashboards !== "string") {
          api.dispatch({
            type: "SET_DASHBOARDS",
            dashboards
          })
        }
      })
      break;
    case "CREATE_DASHBOARD":
      createUpdateDashboard(action.dashboard).then((r) => {
        if (typeof r === "string") {
          next({...action, dashboard: {...action.dashboard, error: r}});
        } else {
          next({...action, dashboard: {...action.dashboard, id: r.id}});
        }
      })
      break;
    case "EDIT_DASHBOARD":
      createUpdateDashboard(action.newDashboard).then((r) => {
        if (typeof r === "string") {
          next({...action, newDashboard: {...action.newDashboard, error: r}});
        } else {
          next(action);
        }
      })
      break;
    case "DELETE_DASHBOARD":
      deleteDashboard(action.dashboard).then((r) => {
        if(r) {
          next(action);
        } else {
          next(editDashboardAction(action.dashboard, {...action.dashboard, error: "Cannot delete"}))
        }
      }); break;


    default: return next(action)
  }

}) as Dispatch;