import {FilterCollection, FilterCollectionChartReducer} from "../../../../models/filter";
import {formatValueName} from "../../ast/ast";
import {TrashIcon} from "../../../../components/icons";
import React, {useEffect, useRef, useState} from "react";
import {queryReducedChartValue, queryReducedValue, reducedValueQueryUrl} from "../../reduce_filter_vm";
import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {bigNumberFormatter, formatHeader, formatObject, titleCase} from "../../../../utils/object_formatting";
import _ from "lodash";
import {showChartModal} from "../../modals/chart_modal";
import {prune, prune_max_len} from "../../../../utils/arrays";

export function ReduceChartFilter(props: {filterCollection: FilterCollection, intl: Record<string, string>, onClose: () => void}) {


  const [values, setValues] = useState([]);

  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      const result = await queryReducedChartValue(reducedValueQueryUrl(props.filterCollection));
      if(typeof result === "string") return setError(result.replace("_", " "));
      if(typeof result.attributes === "string") return setError(result.attributes.replace("_", " "));
      console.log(result.map((i: any) => i.attributes));
      setValues(result.map((i: any) => i.attributes).map((i: any) => {
        //  @ts-ignore
        i[props.filterCollection.reducer.x_column_name] = formatObject(i[props.filterCollection.reducer.x_column_name])
        return i
      }));
    })();
  }, [props.filterCollection])

  if(props.filterCollection.reducer?.type !== "FILTER_COLLECTION_CHART_REDUCER") return <p>Unsupported Reducer Type</p>


  return <div className={`filter-widget reduce-widget p-05 `} style={{width: 300}}>
    <div className={"d-flex flex-row justify-content-between"} style={{marginRight: -5}}>
      <h3 className={"filter-widget-content"}>
        {`${props.intl[props.filterCollection.reducer!.y_column_name]} vs ${titleCase(formatHeader(props.filterCollection.reducer.x_column_name))}`}
      </h3>
      <button className={"icon-button"} onClick={(e) => {
        e.stopPropagation();
        props.onClose();
      }}>
        <TrashIcon/>
      </button>
    </div>
    {error && <p className={"error-message"} style={{color: "#ff6f6f"}}>{error}</p>}
    {error === "" && <div onClick={() => {
      // @ts-ignore
      const header = `${props.intl[props.filterCollection.reducer!.y_column_name]} vs ${formatHeader(props.filterCollection.reducer.x_column_name)}`;
      // @ts-ignore
      showChartModal(props.intl, values, props.filterCollection.reducer.x_column_name, props.filterCollection.reducer!.y_column_name, header)
    }}>
      <ResponsiveContainer width={"100%"} height={80} className={"dashboard-chart"}>
        <LineChart data={prune_max_len(values, 10)}>
          <Line type="monotone" dataKey={props.filterCollection.reducer.y_column_name} stroke="#eee" />
          <XAxis dataKey={props.filterCollection.reducer.x_column_name} stroke={"#eee"} />
          <YAxis stroke={"#eee"} tickFormatter={bigNumberFormatter} />
        </LineChart>
      </ResponsiveContainer>
    </div>}

  </div>
}