import {LatLng} from "leaflet";
import {remoteFetchData} from "./generic_repository";
import {ClusterResponse} from "../models/cluster_response";
import {GeoJSONFeature} from "../models/geo_json";
import {Popup} from "react-leaflet";
import React from "react";
import {fetchMeter} from "./meter_repository";
import {history} from "../index";
import {ClusterPopup} from "../components/cluster_popup/cluster_popup";
import {Meter} from "../models/meter";
import {green, orange, red} from "../utils/colors";


function colorForMeterState(meterState: string): string {
  switch (meterState) {
    case "managed": return green
    case "installed": return orange
    default: return "#5a5a5a"
  }
}

function colorForRSSI(rssi: number): string {
  if(rssi > -85) return green
  if(rssi > -98) return orange
  return red
}

export async function fetchMetersCluster(ne_lat: number, ne_lng: number, sw_lat: number, sw_lng: number, shouldExpandCluster: boolean = false) {
  //  viewport borders
  const ne = new LatLng(ne_lat, ne_lng);
  const sw = new LatLng(sw_lat, sw_lng);

  //  diagonal
  const distance = ne.distanceTo(sw);
  console.log(`distance: ${distance}`);
  const c = await remoteFetchData<ClusterResponse>(`/api/v1/meter/cluster?ne_lat=${ne_lat}&ne_lng=${ne_lng}&sw_lat=${sw_lat}&sw_lng=${sw_lng}&zoom_level=${distance / 60 }`);

  if(typeof c === "string") return c;

  let meters: Meter[] = [];
  if(shouldExpandCluster) {
    const clusters = c.attributes.clusters;
    const result =  await Promise.all(clusters.map((cl) => remoteFetchData<ClusterResponse>(`/api/v1/meter/cluster/${cl.cluster_id}?ne_lat=${ne_lat}&ne_lng=${ne_lng}&sw_lat=${sw_lat}&sw_lng=${sw_lng}&zoom_level=${distance / 60}`)))
    try{
      //  @ts-ignore
      meters = result.flatMap(r => r.attributes.meters.map(m => ({attributes: m})))
    } catch (e) {}

  }

  const fetched_clusters = [
    ...c.attributes.meters.map(m => ({
      feature: {
        ...m.coordinates,
        properties: {meter: true},
      },
      popUp: (_: GeoJSONFeature) => <Popup><button className={"flat-button"} onClick={async () => {
        const meter = await fetchMeter(m.id);
        if(typeof meter === "string") return;
        history.push(`/meters/${meter.attributes.ldn}/edit`, meter)
      }}>
        <div className={"row"} style={{textAlign: "left"}}>
          <div className={"col-md-12"}>{m.ldn}</div>
          <div className={"col-md-12"}>{m.address}</div>
          <div className={"col-md-12"} style={{color: colorForMeterState(m.state)}}>{m.state}</div>
          <div className={"col-md-12"} style={{color: colorForRSSI(m.rssi!)}}>{`RSSI: ${m.rssi ?? "--"}`}</div>
        </div>
      </button></Popup>
    })),
    ...c.attributes.clusters.map(m => ({
          feature: {
            ...m.center,
            properties: {cluster: true, itemCount: m.count, clusterId: m.cluster_id}
          },
          popUp: (_: GeoJSONFeature) => <ClusterPopup clusterId={m.cluster_id} meters={meters}/>  }
      )
    ),
  ];
  return fetched_clusters;
}

export const fetchMeterGroupsCluster = (groupId: number | string) => async (ne_lat: number, ne_lng: number, sw_lat: number, sw_lng: number, shouldExpandCluster: boolean = false) => {
  //  viewport borders
  const ne = new LatLng(ne_lat, ne_lng);
  const sw = new LatLng(sw_lat, sw_lng);

  //  diagonal
  const distance = ne.distanceTo(sw);
  console.log(`distance: ${distance}`);
  const c = await remoteFetchData<ClusterResponse>(`/api/v1/group/${groupId}/meter?ne_lat=${ne_lat}&ne_lng=${ne_lng}&sw_lat=${sw_lat}&sw_lng=${sw_lng}&zoom_level=${distance / 60}`)

  if(typeof c === "string") return c;

  let meters: Meter[] = [];
  if(shouldExpandCluster) {
    const clusters = c.attributes.clusters;
    const result =  await Promise.all(clusters.map((cl) => remoteFetchData<ClusterResponse>(`/api/v1/group/${groupId}/meter/cluster/${cl.cluster_id}?&zoom_level=${distance / 60}`)))
    try{
      //  @ts-ignore
      meters = result.flatMap(r => r.attributes.meters.map(m => ({attributes: m})))
    } catch (e) {}

  }

  const fetched_clusters = [
    ...c.attributes.meters.map(m => ({
      feature: {
        ...m.coordinates,
        properties: {meter: true},
      },

      popUp: (_: GeoJSONFeature) => <Popup><button className={"flat-button"} onClick={async () => {
        const meter = await fetchMeter(m.id);
        if(typeof meter === "string") return;
        history.push(`/meters/${meter.attributes.ldn}/edit`, meter)
      }}>
        <div className={"row"} style={{textAlign: "left"}}>
          <div className={"col-md-12"}>{m.ldn}</div>
          <div className={"col-md-12"}>{m.address}</div>
          <div className={"col-md-12"} style={{color: colorForMeterState(m.state)}}>{m.state}</div>
          <div className={"col-md-12"} style={{color: colorForRSSI(m.rssi!)}}>{`RSSI: ${m.rssi}`}</div>
        </div>
      </button></Popup>
    })),
    ...c.attributes.clusters.map(m => ({
          feature: {
            ...m.center,
            properties: {cluster: true, itemCount: m.count}
          },
          popUp: (_: GeoJSONFeature) => <ClusterPopup clusterId={m.cluster_id} meters={meters}/> }
      )
    ),
  ];
  return fetched_clusters;
}

export const fetchMeterGatewayCluster = (gatewayId: number) => async (ne_lat: number, ne_lng: number, sw_lat: number, sw_lng: number, shouldExpandCluster: boolean = false) => {
  //  viewport borders
  const ne = new LatLng(ne_lat, ne_lng);
  const sw = new LatLng(sw_lat, sw_lng);

  //  diagonal
  const distance = ne.distanceTo(sw);
  console.log(`distance: ${distance}`);
  const c = await remoteFetchData<ClusterResponse>(`/api/v1/gateway/${gatewayId}/meter?ne_lat=${ne_lat}&ne_lng=${ne_lng}&sw_lat=${sw_lat}&sw_lng=${sw_lng}&zoom_level=${distance / 60}`)

  if(typeof c === "string") return c;

  let meters: Meter[] = [];
  if(shouldExpandCluster) {
    const clusters = c.attributes.clusters;
    const result =  await Promise.all(clusters.map((cl) => remoteFetchData<ClusterResponse>(`/api/v1/gateway/${gatewayId}/meter/cluster/${cl.cluster_id}?&zoom_level=${distance / 60}`)))
    try{
      //  @ts-ignore
      meters = result.flatMap(r => r.attributes.meters.map(m => ({attributes: m})))
    } catch (e) {}

  }

  if(!c?.attributes?.meters) return [];



  const fetched_clusters = [
    ...c.attributes.meters.map(m => {
      console.log(m.rssi)
      return ({
        feature: {
          ...m.coordinates,
          properties: {rssi: m.rssi, meter: true},
        },

        popUp: (_: GeoJSONFeature) => <Popup>
          <button className={"flat-button"} onClick={async () => {
            const meter = await fetchMeter(m.id);
            if (typeof meter === "string") return;
            history.push(`/meters/${meter.attributes.ldn}/edit`, meter)
          }}>
            <div className={"row"} style={{textAlign: "left"}}>
              <div className={"col-md-12"}>{m.ldn}</div>
              <div className={"col-md-12"}>{m.address}</div>
              <div className={"col-md-12"} style={{color: colorForMeterState(m.state)}}>{m.state}</div>
              <div className={"col-md-12"} style={{color: colorForRSSI(m.rssi!)}}>{`RSSI: ${m.rssi}`}</div>
            </div>
          </button>
        </Popup>
      });
    }),
    ...c.attributes.clusters.map(m => ({
          feature: {
            ...m.center,
            properties: {cluster: true, itemCount: m.count, rssi: m.rssi}
          },
          popUp: (_: GeoJSONFeature) => <ClusterPopup clusterId={m.cluster_id} meters={meters}/> }
      )
    ),
  ];
  return fetched_clusters;
}
