import {Dashboard} from "../../models/dashboard/dashboard";

type SetDashboards = {
  type: "SET_DASHBOARDS",
  dashboards: Dashboard[]
}

type DeleteDashboard = {
  type: "DELETE_DASHBOARD",
  dashboard: Dashboard
}

type CreateDashboard = {
  type: "CREATE_DASHBOARD",
  dashboard: Dashboard
}

type EditDashboard = {
  type: "EDIT_DASHBOARD",
  oldDashboard: Dashboard,
  newDashboard: Dashboard,
}

type FetchDashboards = {
  type: "FETCH_DASHBOARDS"
}

export type DashboardAction = SetDashboards | DeleteDashboard | CreateDashboard | EditDashboard | FetchDashboards;

export function fetchDashboardsAction() {
  return {
    type: "FETCH_DASHBOARDS"
  };
}

export function editDashboardAction( oldDashboard: Dashboard, newDashboard: Dashboard) {
  return {
    type: "EDIT_DASHBOARD",
    oldDashboard,
    newDashboard,
  };
}