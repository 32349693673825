import {Dispatch, Middleware, MiddlewareAPI} from "redux"
import {remoteFetchData} from "../../repsitory/generic_repository";
import {setStatConfigsAction, StatConfigActions} from "../actions/stat_config_actions";
import {StatConfig} from "../../models/stat_config";
import {setMeterStatsErrorAction} from "../actions/meters_stats_actions";


export const statConfigEffects: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch) => ((action: StatConfigActions) => {

  switch (action.type) {
    case "FETCH_STATS_CONFIGS":
      next(action); //  set loading
      remoteFetchData<{attributes: StatConfig}[]>("/api/v1/stat_config").then((s) => {
        if(typeof s === "string") {
          api.dispatch(setMeterStatsErrorAction(s));
          return;
        }
        api.dispatch(
          setStatConfigsAction(
            [
              ...s.map((s) => s.attributes),
              // {
              //   "target": {
              //     "websocket": "test_chart",
              //   },
              //   "name": "test_chart",
              //   "type": "chart",
              //   "id": 10000
              // }
            ]
          )
        );
      })
      break;

    default: next(action)
  }

}) as Dispatch;