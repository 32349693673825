import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from 'react-intl';
import {Group} from "../../models/group";
import {InputText} from "../../components/input_text";
import {EyeIcon, EyeOffIcon, MoreVerticalIcon, TrashIcon} from "../../components/icons";
import {InfoRow} from "../../components/InfoRow";
import {Link, useHistory} from "react-router-dom";
import * as vm from './meters_page_vm'
import {getDefaultBlacklistedGroups, getLastValue, MetersPageState, setDefaultBlacklistedGroups} from './meters_page_vm'
import {ErrorComponent} from "../../components/error_component";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Log} from "../../models/log";
import {AppState} from "../../store/store";
import {Leafleet} from "../../components/leafleet_map/leafleet_map";
import {fallbackMapCenter} from "../../index";
import {Popup} from "react-leaflet";
import {fetchMetersCluster} from "../../repsitory/meter_cluster_repository";
import {PaginatedListComponent} from "../../components/paginated_list/paginated_list_component";
import {fetchPaginatedCollectionNoAttributes} from "../../repsitory/generic_repository";
import _ from "lodash"; // Import css

export function MetersPage() {
  const [groups, setGroups] = useState<Group[]>([]);

  const logs: Log[] = useSelector((s: AppState) => s.logs.items);

  const [state, setState] = useState<MetersPageState>({error: "", loading: false, query: ""});
  const [initialPage, setInitialPage] = useState(0);

  const [blacklistedGroups, setBlacklistedGroups] = useState<string[]>(getDefaultBlacklistedGroups() ?? []);

  console.log(groups);

  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const mapHeight = 300;

  if(state.error !== "") return <ErrorComponent message={state.error}/>;


  return (
    <div>
      <InputText
        hint={ intl.messages["search_meters"].toString() }
        value={state.query}
        onChange={ (s) => setState({...state, query: s}) }
        title={intl.messages['search'].toString().toUpperCase()}
        searchButton={<button className={"p3 primary-button ml-2"}  onClick={() => {
          if(state.query !== "") vm.searchMeter(state, setState, history, intl.messages as any)
        }}>
          {intl.messages['search'].toString().toUpperCase()}
        </button>}
      />

      {state.error !== "" && <div className={"error-message"}>{state.error}</div>}

      <div className={"row  mt-5"}>
        <div className={"col-md-4"}>
          <div className={"d-flex flex-column justify-content-between"} style={{height: mapHeight}}>
            <div>
              <InfoRow title={ intl.messages["total_meters"].toString() } value={ getLastValue(logs, "meter_count") }/>
              <InfoRow title={ intl.messages["installed"].toString() } value={ getLastValue(logs, "meter_installed") }/>
              <InfoRow title={ intl.messages["managed"].toString() } value={ getLastValue(logs, "meter_managed") }/>
              <InfoRow title={ intl.messages["meters-in-error"].toString() } value={ getLastValue(logs, "meter_in_error") }/>
            </div>

            <div className={"row"}>
              <div className={"col-md-6"}>
                <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/meters/high-frequency-update"}>
                  <div className={"p3 outline-button"}>
                    {intl.messages['high frequency read']}
                  </div>
                </Link>
              </div>
              <div className={"col-md-6"}>
                <Link to={ "/meters/fw-update" } style={{ textDecoration: 'none', color: '#fff' }}>
                  <div className={"p3 outline-button"}>
                    {intl.messages['OTA firmware update']}
                  </div>
                </Link>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/meters/load"}>
                  <div className={"p3 outline-button"}>
                  {intl.messages['load_meters']}
                  </div>
                </Link>
              </div>
              <div className={"col-md-6"}>
                <Link to={ "/meters/new" } style={{ textDecoration: 'none', color: '#fff' }}>
                  <div className={"p3 outline-button"}>
                    {intl.messages['add_meter']}
                  </div>
                </Link>
              </div>
            </div>
          </div>

        </div>
        <div className={"col-md-8"}>
          <Leafleet
            fallbackMapCenter={fallbackMapCenter}
            fetchClusters={fetchMetersCluster}
            staticMapItems={
              groups
                .filter(g => (g?.attributes?.geom ?? null) != null)
                .filter(g => !(_.first(blacklistedGroups.filter(blg => blg === g.id)))) //  remove blacklisted
                .map(g => ({feature: g.attributes.geom!, popUp: (_) => <Popup>
                  <p onClick={() => history.push(`/groups/${g.attributes.name}/edit`, g)}>{g.attributes.name}</p>
                </Popup>}))
            }
          />
        </div>
      </div>
      <div style={ {width:"100%", backgroundColor:"gray", height:"1px", marginTop: "2rem", marginBottom: "1rem"} }/>
      <div className={"d-flex flex-row mt-4"}>
        <h3>
          {intl.messages['groups'].toString().toUpperCase()}
        </h3>
      </div>
      <div className={"row"}>
        <div className={"col-md-12 ellipsis mt-1"}>
          <PaginatedListComponent
            fetchCollection={fetchPaginatedCollectionNoAttributes<Group>(`/api/v1/group`, (g) => {
              if(typeof g === "string") return setState({...state, error: g})
              setGroups(g);
            })}
            headers={
            ["id", "description", "managed", "enrolled", "visible", "color", "actions"]
          }
            initialPage={initialPage}
            rowBuilder={
            (g: Group) => {
              const isGroupVisible = !(_.first(blacklistedGroups.filter(blg => blg === g.id)));
              return [
                <p>{g.id}</p>,
                <p>{g.attributes.name}</p>,
                <p>{g?.attributes?.managed?.toString() || "0"}</p>,
                <p>{g?.attributes?.enrolled?.toString() || "0"}</p>,
                <button className={'icon-button'} onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  let blGroup: string[];
                  if (isGroupVisible) {
                    blGroup = [...blacklistedGroups, g.id!]
                  } else {
                    blGroup = blacklistedGroups.filter(blg => blg !== g.id)
                  }
                  setBlacklistedGroups(blGroup);
                  setDefaultBlacklistedGroups(blGroup);
                }}>
                  {isGroupVisible && <EyeIcon/>}
                  {!isGroupVisible && <EyeOffIcon/>}
                </button>,
                <div style={{width: 18, height: 18, borderRadius: 40, backgroundColor: g.attributes.geom?.properties?.color ?? "#3C4B64"}} />,
                <div style={{marginTop: "-6px"}} className={"d-flex"}>
                  <button className={"icon-button"} onClick={(e) => {
                    e.stopPropagation();
                    confirmAlert({
                      title: intl.messages["confirm"].toString(),
                      message: intl.messages["this_action_cant_be_undone"].toString(),
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => {
                            vm.destroyGroup(g.attributes.id!, dispatch, state, setState, intl.messages as any);
                            setTimeout(() => {
                              setInitialPage(1);
                              setInitialPage(0);
                            }, 100)
                          }
                        },
                        {
                          label: 'No',
                          onClick: () => {
                          }
                        }
                      ]
                    });

                  }}>
                    <TrashIcon/>
                  </button>
                  <button className={"icon-button"}>
                    <MoreVerticalIcon/>
                  </button>
                </div>,
              ];
            }
            }
          onTap={ (g: Group) => {
            history.push(`/groups/${g.attributes.name}/edit`, g);
          }
          }
          />
          <div className={"d-flex flex-row-reverse"}>
            <Link to={ "/groups/new" } style={{ textDecoration: 'none', color: '#fff' }}>
              <div className={"p3 primary-button"}>
                {intl.messages['add_group']}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
