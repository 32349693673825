import {Filter, FilterCollection} from "../../models/filter";

export function addOr(fs: Filter[][], f: Filter, row_index: number) {
  return fs.map((fs, i) => i === row_index ? [...fs, f] : fs)
}

export function remove(fs: Filter[][], row_index: number, columnIndex: number = 1) {
  return fs
    .map((fs, i) => i === row_index
      ? fs.filter((f, j) => j !== columnIndex)
      : fs
    )
    .filter((fs) => fs.length > 0)
}


export function addAnd(fs: Filter[][], f: Filter) {
  return [...fs, [f]]
}
