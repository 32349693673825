import {StorageConfiguration} from "../models/storage_configuration";
import {put, putRaw, remoteFetchAttributes} from "./generic_repository";
import {ConnectionButtonState} from "../components/test_connection_button/test_connection_button";

export function fetchStorageConfiguration(): Promise<StorageConfiguration | string> {
  return remoteFetchAttributes<StorageConfiguration>("/api/v1/storage-configuration")
}

export async function updateStorageConfiguration(storageConfiguration: StorageConfiguration, intl: Record<string, string>, skip_test_connection?: boolean): Promise<StorageConfiguration | string> {
  const result = await putRaw({
    "jsonapi": {
      "version": "1.0"
    },
    "data": {
      "type": "storageConfiguration",
      "skip_test_connection": skip_test_connection,
      "attributes": {
        ...storageConfiguration,
      }
    }
  },"/api/v1/storage-configuration");



  if(result.status !== 204) return intl["Configuration not valid"];

  return storageConfiguration;
}
