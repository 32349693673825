import React from "react";
import * as H from "history";
import {postFormData} from "../../../repsitory/generic_repository";

export type LoadMetersState = {
  metersInfo: File | null,
  metersKeys: File | null,
  deviceGroupId?: number
  deviceTypeId?: number,
  importType: string,
  error: string
}

function validate(s: LoadMetersState, messages: Record<string, string>): string[] {
  let errors: string[] = [];
  if(s.metersInfo === undefined && s.metersKeys === undefined) errors = [...errors, messages["please_upload_at_least_one_file"]]
  if(s.deviceTypeId === undefined ) errors = [...errors, messages["please_select_device_type"]]
  if(s.deviceGroupId === undefined) errors = [...errors, messages["please_select_group"]]

  return errors;
}

export async function uploadMeters(state: LoadMetersState, setState:React.Dispatch<React.SetStateAction<LoadMetersState>>, history: H.History, messages: Record<string, string>) {
  const errors = validate(state, messages);
  if(errors.length > 0) {
    setState({...state, error: errors.join(" ")});
    return
  }

  if(state.metersInfo === null) {
    const dataKeys = new FormData();
    dataKeys.append("import_type", state.importType);
    dataKeys.append("keys_file", state.metersKeys!);
    const keysResponse = await postFormData(dataKeys, "/api/v1/import_meter_keys");
    if(keysResponse.status !== 200 && keysResponse.status !== 204) setState({...state, error: messages["cant_import_keys"]})
  } else {
    const dataMeter = new FormData();
    dataMeter.append("group_id", state?.deviceGroupId?.toString() ?? "");
    dataMeter.append("device_type_id", state?.deviceTypeId?.toString() ?? "");
    dataMeter.append("meters_file", state.metersInfo!);
    if(state.metersKeys != null) dataMeter.append("keys_file", state.metersKeys!);
    const meterResponse = await postFormData(dataMeter, "/api/v1/meter/massive_import");
    if(meterResponse.status !== 200 && meterResponse.status !== 204) setState({...state, error: messages["cant_import_meters"]})

  }

}