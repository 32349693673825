import {Gateway} from "../../models/gateway";

export const FETCH_GATEWAYS = 'FETCH_GATEWAYS';
export const SET_GATEWAYS = 'SET_GATEWAYS';
export const SET_GATEWAYS_ERROR = 'SET_GATEWAYS_ERROR';

export interface GatewaysAction {
  type: string,
  gateways?: Gateway[]
  error?: string
}

export const fetchGatewaysAction = () => ({
  type: FETCH_GATEWAYS,
});

export const setGatewaysAction = (gateways: Gateway[]) => ({
  type: SET_GATEWAYS,
  gateways
});

export const setGatewaysErrorAction = (error: string) => ({
  type: SET_GATEWAYS_ERROR,
  error
});