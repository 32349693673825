import {Crs} from "./crs";
import {GeoJSONPolygon} from "./geo_json";

export interface Geom {
  type: string;
  crs: Crs;
  coordinates: number[][][];
}

export interface GroupAttributes {
  updatedAt?: Date;
  name: string;
  insertedAt?: Date;
  id?: number;
  geom: GeoJSONPolygon | null;
  metersCount?: number;
  enrolled?: number;
  managed?: number;
  tags: any
}

export interface Group {
  type: string;
  id?: string;
  attributes: GroupAttributes;
}

export const emptyGroup: () => Group = () => { return {
  type: "group",
  attributes: {
    name: "",
    metersCount: 0,
    enrolled: 0,
    managed: 0,
    geom: null,
    tags: {}
  }
} };