import {Dashboard} from "../../models/dashboard/dashboard";
import {DashboardAction} from "../actions/dashboard_actions";

export type DashboardsState = {
  dashboards: Dashboard[]
}

export default function(state: DashboardsState = { dashboards: [] }, action: DashboardAction): DashboardsState {
  switch (action.type) {
    case "SET_DASHBOARDS":
      return { ...state, dashboards: action.dashboards };
    case "DELETE_DASHBOARD":
      return { ...state, dashboards: state.dashboards.filter(d => d.id !== action.dashboard.id) };
    case "CREATE_DASHBOARD":
      return { ...state, dashboards: [ action.dashboard, ...state.dashboards] };
    case "EDIT_DASHBOARD":
      return { ...state, dashboards: [ action.newDashboard, ...(state.dashboards.filter(d => d.id !== action.oldDashboard.id))] };
    default: return state;
  }
}

// const dashboards: Dashboard[] = [
//   {
//     name: "dashboard test",
//     widgets: [
//       {
//         type: "numeric_value",
//         name: "Meters Count",
//         key: "a",
//         data_grid: {x: 1, y: 0, w: 3, h: 1, minW: 2, maxW: 4, minH: 1, maxH: 1},
//         config: {},
//         channelName: "meters_channel",
//         eventName: "meter_count"
//       },
//     ]
//   },
// ];