import React, {Dispatch} from "react";

import * as H from 'history'
import {post, put} from "../../repsitory/generic_repository";
import {User} from "../../models/user";
import {UserGroup} from "../../models/user_group";
import * as _ from 'lodash';
import {addUserToGroup, removeUserToGroup} from "../../repsitory/user_repository";

//  Page state
export type EditUserPageState = {
  username: string
  id: string
  badge_id: string
  email: string
  groups: string[]
  last_login: string
  status: string
  validationErrors: string[]
  loading: boolean
  error: string
}

const validate = (state: EditUserPageState) => {
  let errors: string[] = [];
  return errors;
};




//  Page effects
export const createEditUser = async(state: EditUserPageState, oldUserGroups: UserGroup[], setState: React.Dispatch<React.SetStateAction<EditUserPageState>>, dispatch: Dispatch<any>, history: H.History) =>  {

  const validation = validate(state);

  if(validation.length > 0) {
    setState({...state, validationErrors: validation});
    return;
  } else {
    setState({...state, loading: true})
  }


  const data = {
      "data": {
        "attributes": {
          "username": state.username,
          "email": state.email,
          "attributes": {
            "badge_id": state.badge_id,
            "status": state.status
          },
          // "groups": state.groups
        },
        "type": "user"
      }
    };

  try {
    let result;
    let userId: string;

    if(state.id === undefined || state.id === "") {
      result = await post(data,  '/api/v1/user');
      userId = result.data.attributes.id;
    } else {
      result = await put(data,  `/api/v1/user/${state.id}`);
      userId = state.id
    }


    if(result.errors !== undefined) {
      setState({...state, error: JSON.stringify(result), loading: false})
      return;
    }

    const groupSyncResult = await syncGroups(oldUserGroups.map(g => g.id), state.groups, userId);

    setState({...state, loading: false, error: groupSyncResult ? "" : "Cannot sync groups"});

    if(groupSyncResult) { history.push('/users'); }

  } catch (e) {
    setState({...state, error: e.toString(), loading: false})
  }
};

export async function syncGroups(oldGroupIds: string[], newGroupsIds: string[], userId: string): Promise<boolean> {
  return (await Promise.all([
    addGroups(oldGroupIds, newGroupsIds, userId),
    removeGroups(oldGroupIds, newGroupsIds, userId)
  ])).reduce((a, b) => a && b);
}

export async function addGroups(oldGroupIds: string[], newGroupsIds: string[], userId: string): Promise<boolean> {
  return (await Promise
    .all(_.difference(newGroupsIds, oldGroupIds)
    .map((groupId: string) => addUserToGroup(userId, groupId)))
  ).reduce((p, n) => p && n, true);
}

export async function removeGroups(oldGroupIds: string[], newGroupsIds: string[], userId: string): Promise<boolean> {
  return (await Promise
    .all(_.difference(oldGroupIds, newGroupsIds)
    .map((groupId: string) => removeUserToGroup(userId, groupId)))
  ).reduce((p, n) => p && n, true);
}


export const mkUserState: (u: User) => EditUserPageState = (user: User) => ({
  username: user.username || "",
  id: user.id || "",
  badge_id: (user?.additionalAttributes?.badge_id && user?.additionalAttributes?.badge_id[0]) || "",
  email: user.email || "",
  groups: user.groups || [],
  last_login: "??",
  status: (user?.additionalAttributes?.status && user?.additionalAttributes?.status[0]) || "",
  validationErrors: [],
  loading: false,
  error: ""
});