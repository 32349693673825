import {TitleValueWidget} from "../common/title_value_widget";
import React, {Component} from "react";
import {WidgetConfig} from "../../../../../models/dashboard/widgetConfig";
import {queryReducedValue} from "../../../../data_explorer_page/reduce_filter_vm";
import {LoaderComponent} from "../../../../../components/loading_widget";
import {timer} from 'rxjs';
import {Subscription} from "@reactivex/rxjs/dist/package";

type Props = {
  widget: WidgetConfig,
  intlMessages: Record<string, any>
}

type NumericTypeWidgetState = {
  value: string
  handle?: Subscription
}

export function NumericTypeDEXWidget(props: Props) {
  return <NumericTypeWidgetClass intlMessages={props.intlMessages} widget={props.widget}/>
}

class NumericTypeWidgetClass extends Component<Props, NumericTypeWidgetState>{

  constructor(props: Props) {
    super(props);
    this.setState({value: ""});
  }

  async update(widget: WidgetConfig) {
    this.setState({...this.state, value: await extractValue(widget)})
  }

  componentDidMount() {
    const t = timer(25, 30000);
    const handle = t.subscribe(() => this.update(this.props.widget))
    this.setState({
      handle
    })
  }

  componentWillUnmount() {
    this?.state?.handle?.unsubscribe();
    this.setState({...this.state, handle: undefined})
  }

  render() {
    const name = this.props.intlMessages[this.props.widget.name] ?? this.props.widget.name;



    if(!this?.state?.value) return (<div className={"d-flex flex-column justify-content-between title-value-widget"}>
      <div className={"row"}>
        <div className={"col-md-12"}>
          <h3 style={{color: "#fff"}} className={"m-2"}> name </h3>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12 d-flex flex-row justify-content-between align-items-center"}>
          <LoaderComponent height={80} width={80}/>
        </div>
      </div>
    </div>)

    let value = this!.state!.value;

    try{
      value = Number(value).toFixed(2);
    } catch (e) {}

    return <TitleValueWidget
      title={name}
      value={value}
    />
  }

}

export async function extractValue(widgetConfig: WidgetConfig): Promise<string> {

  const source = widgetConfig.source;

  if(source.type !== "WEBSOCKET") {
    return queryReducedValue(source.pollingUrl);
  }

  return '--'
}
