import {confirmAlert} from "react-confirm-alert";
import React, {useState} from "react";
import Select from "react-select";
import _ from "lodash";

import {DataSource, formatDataSource, dataSources} from "../ast/ast";

export async function showDataSourceCreation(intl: Record<string, string>, onCreate: (dataSource: DataSource) => void, only?: string) {

  confirmAlert({
    customUI: ({onClose}) =>
      <div
        style={{minHeight: 150, backgroundColor: "white"}}
        className={"configuration-editor p-3 pt-4"}
      >
        <CreateDataSouceWidget intl={intl} onCreate={(f) => {
          onCreate(f);
          onClose();
        }} only={only} close={onClose}/>
      </div>
  });
}

function CreateDataSouceWidget(props: {intl: Record<string, string>,  onCreate: (dataSource: DataSource) => void, only?: string, close: () => void}, ) {

  const [selectedPrototype, setSelectedPrototype] = useState<DataSource>(_.first(dataSources)!)

  return <div>
    <div className={"row "}>
      <div className={"col-md-12 mb-4"}>
        <h3>{props.intl["data_source"].toString().toUpperCase()}</h3>
        <Select
          value={ {label: formatDataSource(selectedPrototype, props.intl), value: selectedPrototype} }
          onChange={(t: any) => setSelectedPrototype(t.value)}
          isMulti={false}
          options={dataSources.map((ds => ({value: ds, label: formatDataSource(ds, props.intl)})))}
          name="device type id"
          className="basic-multi-select mb-4"
          classNamePrefix="select"
        />

      </div>
    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.onCreate(_.cloneDeep(selectedPrototype));
        }}>{props.intl["ok"]}</button>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.close()
        }}>{props.intl["close"]}</button>
      </div>
    </div>
  </div>
}
