import {ColumnName, DataSource, ReducerOperator} from "../pages/data_explorer_page/ast/ast";

export type FilterName =
  |"gateway_id"
  |"ldn"
  |"pdr"
  |"group"
  |"with_errors"
  |"date_greater"
  |"date_smaller"
  |"max_network_greater"
  |"max_network_smaller"
  |"min_network_greater"
  |"min_network_smaller"


export type Filter = {
  name: FilterName,
  value: string
}

/*
Permutation of the original array
*/
export type ColumnTransform = number[]


export type FilterCollection = {
  filters: Filter[][],
  visibleColumns: boolean[], // Relative to original index of columns whether an element is visible or not [] means all visible
  columnTransform: ColumnTransform, // Transformation to column order
  dataSource: DataSource,
  reducer: FilterCollectionReducer | FilterCollectionChartReducer | null
  name: string,
  id: number,
  editable?: boolean
}

export type FilterCollectionReducer = {
  type: "FILTER_COLLECTION_REDUCER"
  column_name: string
  operator: ReducerOperator
}

export type FilterCollectionChartReducer = {
  type: "FILTER_COLLECTION_CHART_REDUCER"
  x_column_name: string
  y_column_name: ColumnName
}

export const emptyFilterCollection: FilterCollection = {name: "", id: -1, filters:[], columnTransform: [], visibleColumns:[], dataSource: null, reducer: null};