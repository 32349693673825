import {WidgetConfig} from "./widgetConfig";

export type Dashboard = {
  name: string,
  id?: number
  error?: string
  widgets: WidgetConfig[]
}

export function emptyDashboard(dashboards: Dashboard[] = []): Dashboard {

  const names = dashboards.map(d => d.name);

  return ({
    name: dashboardName(names),
    widgets: []
  })
}

function dashboardName(dashboards: string[], index: number = 0, name: string = "new dashboard"): string {
  if(!dashboards.includes(name)) return name;
  return dashboardName(dashboards, index+1, `${name.replace((` (${index})`), "")} (${index+1})`)
}