import {GeoJSONFeature, GeoJSONPoint, GeoJSONPolygon, MapItem} from "../../models/geo_json";
import React, {ReactNode} from "react";
import {Circle, Marker, Polygon} from "react-leaflet";
import L from "leaflet";
import './leafleet_map.css'


export const RenderMapItem = (zoom: number) => (mapItem: MapItem) => {
  switch (mapItem.feature.geometry.type) {
    case "Point":
      return RenderGeoJSONPoint(mapItem.feature as GeoJSONPoint, mapItem.popUp, zoom);
    case "Polygon":
      return RenderGeoJSONPolygon(mapItem.feature as GeoJSONPolygon, mapItem.popUp);
  }
}

export function RenderGeoJSONPoint(point: GeoJSONPoint, popUp: (feature: GeoJSONFeature) => ReactNode | null, zoom: number) {

  if(point.properties.rssi) return RenderGeoJSONPointRssi(point, popUp, point.properties.rssi);
  if(point.properties.radius) return RenderGeoJSONCircle(point, popUp, point.properties.radius);
  if(point.properties.cluster) return RenderGeoJSONCluster(point, popUp, zoom)
  if(point.properties.meter ?? false) return RenderMeterMarker(point, popUp, zoom);
  return RenderGeoJSONMarker(point, popUp, zoom);
}

export function RenderGeoJSONMarker(point: GeoJSONPoint, popUp: (feature: GeoJSONFeature) => ReactNode | null, zoom: number, ) {
  const popup = popUp(point);

  return <Marker
    key={JSON.stringify(point)}
    icon={ L.divIcon({
      className: "",
      html: `<div style="height: 30px; width: 30px; margin-left: -14px; margin-top: -30px; color: ${point.properties.color ?? 'blue'}">
                ${ markerIcon(point.properties.markerColor ?? 'rgba(60, 75, 100, 80)')} 
              </div>`
    }) }
    position={
      {lat: point.geometry.coordinates[1], lng: point.geometry.coordinates[0]}
    }
  >
    {popup && popup}
  </Marker>
}


export function RenderMeterMarker(point: GeoJSONPoint, popUp: (feature: GeoJSONFeature) => ReactNode | null, zoom: number) {
  const popup = popUp(point);


  return <Marker
    key={JSON.stringify(point)}
    icon={ L.divIcon({
      className: "",
      html: `<svg width="24" height="35" viewBox="0 0 51 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 25.5C0 11.2929 11.2929 0 25.5 0C39.7071 0 51 11.2929 51 25.5C51 44.4429 25.5 72.8571 25.5 72.8571C25.5 72.8571 0 44.4429 0 25.5ZM38.3104 20.6692L40.5142 17.3546L40.5321 17.3367C42.3285 20.1072 43.3244 23.3202 43.4097 26.621C43.4951 29.9218 42.6666 33.182 41.0158 36.0417C40.7001 36.5885 40.2455 37.0422 39.6981 37.3568C39.1506 37.6715 38.5298 37.8358 37.8983 37.8333H13.0837C12.4584 37.8296 11.8449 37.6622 11.3043 37.3479C10.7637 37.0336 10.3147 36.5832 10.0021 36.0417C8.01289 32.5612 7.24842 28.5141 7.83097 24.5478C8.41352 20.5815 10.3094 16.9252 13.2154 14.1636C16.1214 11.4021 19.8696 9.69497 23.8604 9.31526C27.8512 8.93555 31.8541 9.90522 35.2288 12.0692L31.9142 14.2729C29.1586 12.8945 26.0321 12.4407 22.9984 12.9788C19.9646 13.5169 17.185 15.0183 15.0716 17.2604C12.9582 19.5025 11.6235 22.3659 11.2655 25.4262C10.9074 28.4864 11.545 31.5806 13.0837 34.25H37.9163C39.1002 32.1955 39.7559 29.8794 39.8247 27.5093C39.8935 25.1391 39.3732 22.7888 38.3104 20.6692ZM24.1368 30.3874C23.7017 30.2071 23.3065 29.9427 22.9738 29.6096C22.6406 29.2768 22.3763 28.8816 22.196 28.4466C22.0156 28.0116 21.9228 27.5453 21.9228 27.0744C21.9228 26.6035 22.0156 26.1372 22.196 25.7022C22.3763 25.2672 22.6406 24.872 22.9738 24.5392L38.185 14.3983L28.0442 29.6096C27.7114 29.9427 27.3162 30.2071 26.8812 30.3874C26.4462 30.5677 25.9799 30.6605 25.509 30.6605C25.0381 30.6605 24.5718 30.5677 24.1368 30.3874Z" fill="#3C4B64"/>
</svg>
`
    }) }
    position={
      {lat: point.geometry.coordinates[1], lng: point.geometry.coordinates[0]}
    }
  >
    {popup && popup}
  </Marker>
}

export function RenderGeoJSONCluster(point: GeoJSONPoint, popUp: (feature: GeoJSONFeature) => ReactNode | null, zoom: number) {
  const popup = popUp(point);
  console.log(zoom);

  let isMaxZoom = false;
  try {
    isMaxZoom = document.getElementsByClassName("leaflet-control-zoom-in")[0].className.includes("disabled");
  } catch (e) {}

  return <Marker
    key={JSON.stringify(point)}
    onclick={() => {
      if(!isMaxZoom) {
        document.getElementsByClassName("leaflet-control-zoom-in")[0].dispatchEvent(new Event('click'))
      }
    }}
    icon={ L.divIcon({
      className: "",
      html: `<div style="height: 30px; width: 30px; margin-left: -14px; margin-top: -30px; color: ${point.properties.color ?? 'blue'}">
                ${ clusterIcon(point.properties.markerColor ?? 'rgba(60, 75, 100, 80)', point.properties.itemCount) } 
              </div>`
    }) }
    position={
      {lat: point.geometry.coordinates[1], lng: point.geometry.coordinates[0]}
    }
  >
    {popup && isMaxZoom && popup}
    {/*{popup}*/}
  </Marker>
}

export function RenderGeoJSONPointRssi(point: GeoJSONPoint, popUp: (feature: GeoJSONFeature) => ReactNode | null, rssi: number) {
  const popup = popUp(point);
  return <Marker
    key={JSON.stringify(point)}
    icon={ L.divIcon({
      className: "",
      html: `<div style="height: 30px; width: 30px; margin-left: -14px; margin-top: -30px; color: ${point.properties.color ?? 'blue'}">
                ${ point.properties.cluster ? clusterIcon(point.properties.color ?? 'rgba(60, 75, 100, 80)', `${rssi} dB`) : markerIconRssi(point.properties.color ?? 'rgba(60, 75, 100, 80)', `${rssi} dB`)} 
              </div>`
    }) }
    position={
      {lat: point.geometry.coordinates[1], lng: point.geometry.coordinates[0]}
    }
  >
    {popup && popup}
  </Marker>
}

export function RenderGeoJSONCircle(point: GeoJSONPoint, _: (feature: GeoJSONFeature) => ReactNode | null, radius: number) {
  return <Circle
      id={"circle"}
      center={ {lat: point.geometry.coordinates[1], lng: point.geometry.coordinates[0]}}
      radius={radius}
      color={"#3C4B64"}
      fillColor={"rgba(60,75,100,0.99)"}
      weight={1}
    />;
}



function markerIcon(color: string): string {
  return `<svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 24 24" fill="${color}" width="40"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>`
}

function markerIconRssi(color: string, rssi: number | string): string {
  return `
    <div class="marker-rssi-container">
      <svg class="marker-rssi-marker" xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 24 24" fill="${color}" width="40">
        <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
      </svg>
      <p class="marker-rssi-tag">${rssi}</p>
    </div>

`
}

function clusterIcon(color: string, items: number | string | undefined): string {
  return `<div class="cluster">${items ?? ""}</div>`
}

export function RenderGeoJSONPolygon(polygon: GeoJSONPolygon, popUp: (feature: GeoJSONFeature) => ReactNode | null) {
  const popup = popUp(polygon);
  const color = polygon?.properties?.color ?? "rgba(60, 75, 100, 80)"
  return <Polygon
    key={JSON.stringify(polygon)}
    color={color}
    fillColor={color}
    positions={
      polygon.geometry.coordinates[0].map((c: number[]) => ({lat: c[1], lng: c[0]}))
    }
  >
    {popup && popup}
  </Polygon>
}

/*
<svg pointer-events="none" class="leaflet-zoom-animated" width="421" height="480" viewBox="-35 -40 421 480" style="transform: translate3d(-35px, -40px, 0px);"><defs>
    <radialGradient id="grad1" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
      <stop offset="0%" style="stop-color:rgb(60, 75, 100);stop-opacity:0.8"></stop>
        <stop offset="70%" style="stop-color:rgb(60, 75, 100);stop-opacity:0.4"></stop>
      <stop offset="100%" style="stop-color:rgb(60, 75, 100);stop-opacity:0"></stop>
    </radialGradient>
</defs><g><path class="leaflet-interactive" stroke="#3C4B64" stroke-opacity="1" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="url(#grad1)" fill-opacity="0.8" fill-rule="evenodd" d="M46.23220337741077,200.12110441504046a129,129 0 1,0 258,0 a129,129 0 1,0 -258,0 "></path></g>
</svg>
  */