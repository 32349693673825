import {httpDelete, post, putRaw, remoteFetchData} from "./generic_repository";
import {Filter, FilterCollection} from "../models/filter";
import {DataSource} from "../pages/data_explorer_page/ast/ast";

export async function createFilterCollection(d: FilterCollection): Promise<{ id: string } | string> {
  console.log("Persisting filter collection")
  const data = {
    "data": {
      "attributes": {
        "filter": JSON.stringify(d),
        "name": d.name
      },
      "type": "meterReadingsFilter",
    }
  };

  try {
    let id = (await post(data,  `/api/v1/user/meter-readings-filter`)).data.id;
    return { id };
  } catch (e) {
    console.log(e);
    return e.toString();
  }
}

export async function updateFilterCollection(d: FilterCollection): Promise<{ id: number } | string> {
  console.log("Persisting filter collection")
  const data = {
    "data": {
      "attributes": {
        "filter": JSON.stringify(d),
        "name": d.name
      },
      "type": "meterReadingsFilter",
    }
  };

  try {
    let  id = d.id;
    (await putRaw(data,  `/api/v1/user/meter-readings-filter/${d.id}`));
    return { id };
  } catch (e) {
    console.log(e);
    return e.toString();
  }
}

export async function deleteFilter(d: FilterCollection): Promise<boolean> {
  return (await httpDelete(`/api/v1/user/meter-readings-filter/${d.id}`)).status === 204;
}

export async function fetchFilterCollections(): Promise<FilterCollection[] | string> {
  const result = await remoteFetchData<{ attributes: {filter: string, name: string, id: number}, id: string }[] | null>("/api/v1/user/meter-readings-filter");

  if(typeof result !== "string" && result !== null) {
    return result.map((d) => {
      return {...(JSON.parse(d.attributes.filter)) as FilterCollection, id: Number(d.id), name: d.attributes.name};
    }).reverse();
  } else {
    return result ?? [];
  }

}
