import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import {EditableRow} from "../../components/InfoRow";
import {JobSchedule} from "../../components/job_schedule_form/job_schedule_form_vm";
import {JobScheduleForm} from "../../components/job_schedule_form/job_schedule_form";
import {AdminFtpPageState, createJob, testSftpConnection, testSftpConnectionRaw} from "./admin_ftp_page_vm";
import {history} from "../../index";
import {TestConnectionButton} from "../../components/test_connection_button/test_connection_button";
import {fetchStorageConfiguration} from "../../repsitory/storage_configuration_repository";
import DatePicker from "react-datepicker";


export function AdminFtpPage() {

  const [state, setState] = useState<AdminFtpPageState>({
    address: "", password: "", upload: "", user: "", jobSchedule: {
      type: "cron",
      cron: "0 0 * * *",
    },
    error: "",
    storageConfig: {
      address: "",
      password: "",
      user: "",
      type: "",
      dbName: "",
      ftpHost: "",
      ftpPort: 22,
      ftpUser: "",
      ftpPassword: "",
      uploadFolder: ""
    },
    date: new Date()
  });

  useEffect(() => {
    (async() => {
      fetchStorageConfiguration().then((r) => {
        if(typeof r === "string") return setState({...state, error: r});
        setState({...state, storageConfig: r})
      })
    })();
  }, [0]);

  const intl = useIntl();
  return (
    <div>
      <EditableRow title={intl.messages["dest_address"].toString()}
                   value={ state?.storageConfig?.ftpHost ?? '' }
                   onEdit={(s) => setState({...state, storageConfig: {...state.storageConfig, ftpHost: s}})}
      />
      <EditableRow title={intl.messages["dest_port"].toString()}
                   value={ state?.storageConfig?.ftpPort?.toString() ?? "22" }
                   onEdit={
                     (s) => {
                       const n = Number(s);
                       if(!isNaN(n)) {
                         setState({...state, storageConfig: {...state.storageConfig, ftpPort: n}});
                       }
                     }
                   }
      />
      <EditableRow title={intl.messages["user"].toString()} value={ state?.storageConfig?.ftpUser ?? '' }
                   onEdit={(s) => setState({...state, storageConfig: {...state.storageConfig, ftpUser: s}})}
      />
      <EditableRow title={intl.messages["password"].toString()} value={ state?.storageConfig?.ftpPassword ?? '' }
                   onEdit={(s) => setState({...state, storageConfig: {...state.storageConfig, ftpPassword: s}})} hidden/>


      <EditableRow title={intl.messages["upload"].toString()} value={ state?.storageConfig?.uploadFolder ?? '' }
                   onEdit={(s) => setState({...state, storageConfig: {...state.storageConfig, uploadFolder: s}})}/>

      <div className={"row"}>
        <div className={"col-md-12"}>
          <h3> {intl.messages["schedule_time"].toString().toUpperCase()} </h3>
          <DatePicker
            className={"form-control"}
            selected={state.date}
            onChange={ (date: Date) => {
              console.log(`${date.getSeconds()} ${date.getMinutes()} ${date.getUTCHours()} * *`);
              setState({
                ...state,
                date,
                jobSchedule: {type: "cron", cron: `${date.getMinutes()} ${date.getUTCHours()} * * *`}
              });
            }}
            showTimeSelect
            showTimeSelectOnly
            dateFormatCalendar={"dd-MM-yyyy"}
            timeFormat="HH:mm"
            timeIntervals={5}
            timeCaption="time"
            dateFormat="h:mm aa"
          />
        </div>
      </div>

      <div className={"row mt-3"}>
        <div className={"col-md-12 ellipsis mt-1 d-flex justify-content-between"}>
          <TestConnectionButton
            intl={intl.messages as any}
            onTap={async () => testSftpConnection({
                host_name: state.storageConfig.ftpHost,
              port: state.storageConfig.ftpPort,
              user: state.storageConfig.ftpUser,
              folder: state.storageConfig.uploadFolder,
              password: state.storageConfig.ftpPassword
              }, intl.messages as any)}
            key={JSON.stringify(state.storageConfig)}/>
          <div className={"d-flex flex-row-reverse"}>
            <button className={"p3 primary-button"}  onClick={() => {
              createJob(state, setState, history, intl.messages as any)
            }}>
              {intl.messages['save']}
            </button>
            <Link to={ "/admin" } style={{ textDecoration: 'none', color: '#fff' }}>
              <div className={"p3 outline-button mr-3"}>
                {intl.messages['cancel']}
              </div>
            </Link>
            <p style={{marginRight: "1rem"}} className={"error-message"}>{state.error}</p>
          </div>
        </div>
      </div>
    </div>
  );
}