import {Dispatch, Middleware, MiddlewareAPI} from "redux"
import {FETCH_METERS} from "../actions/meters_actions";
import {remoteFetchAttributes} from "../../repsitory/generic_repository";
import {MetersStats} from "../../models/meter_stats";
import {setMeterStatsAction, setMeterStatsErrorAction} from "../actions/meters_stats_actions";


export const metersStatsEffects: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch) => ((action: any) => {


  switch (action.type) {
    case FETCH_METERS:
      console.log("[MetersStats effect] fetch metersStats");
      remoteFetchAttributes<MetersStats>("/api/v1/meter_stats?stats[]=count&stats[]=installed&stats[]=enrolled&stats[]=managed&stats[]=in_error&stats[]=never_seen&stats[]=seen_last_24h&stats[]=seen_last_week&stats[]=seen_last_billing_period")
        .then((m) => {
          if(typeof m === "string") {
            console.log("[MetersStats effect] error" + m);
            api.dispatch(setMeterStatsErrorAction(m));
          } else {
            api.dispatch(setMeterStatsAction(m));
            console.log("[Meterstats effect] success");
          }
          next(action)
        })
        .catch(e => {
          api.dispatch(setMeterStatsErrorAction(e));
          console.log("[MetersStats effect] error" + e);
          next(action)
        }); break;

    default: next(action)
  }

}) as Dispatch;