import {Command, ConfigurationValue} from "../../models/configuration";
import {post, postRaw, remoteFetchData} from "../../repsitory/generic_repository";


export async function fetchGatewayCommands(gatewayId: number | string): Promise<Command[] | string> {
  const result = await remoteFetchData<any[]>(`/api/v1/gateway/${gatewayId}/command`);
  if(typeof result === "string") return result;

  return result.map((item: any) => {
    return ({
      name: item.id,
      params: [{name: item.attributes.name, type: item.attributes.valueType}]
    });
  });

}

export async function fetchMeterCommands(meterId: number | string): Promise<Command[] | string> {
  const result = await remoteFetchData<any>(`/api/v1/meter/${meterId}/command`);
  if(typeof result === "string") return result;

  return result.map((command: any) => ({
    name: command.id,
    params: [ {name: command.attributes.name, type: command.attributes.valueType} ]
  }));
}


export const execGatewayCommand = (id: number | string) => async (command: Command, paramValues: ConfigurationValue[]) => {

  const data = {
    data: {
      type: "command",
      attributes: {
        attribute_name: command.name,
        attribute_value: paramValues[0]?.value ?? null
      }
    }
  }

  const result = await postRaw(data, `/api/v1/gateway/${id}/command`)
  if(result.status !== 204) return result.body;

}

export const execMeterCommand = (id: number | string) => async (command: Command, paramValues: ConfigurationValue[]) => {

  const data = {
    data: {
      type: "command",
      attributes: {
        attribute_name: command.name,
        attribute_value: paramValues[0]?.value ?? null
      }
    }

  }

  const result = await postRaw(data, `/api/v1/meter/${id}/command`)
  if(result.status !== 204) return result.body;

}

