import {get, getBody} from "../repsitory/generic_repository";

// @ts-ignore
import * as ObjectsToCsv from "objects-to-csv";

export function downloadJsonFile(json: any, name?: string) {

  const b = new Blob([JSON.stringify(json)], {type: "octet/stream"})
  const url = URL.createObjectURL(b);
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.href = url;
  a.download = name ?? "config.json";
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);

}

export async function downloadCsvFile(data: any[], name: string) {
  const csv = await (new ObjectsToCsv(data)).toString();
  const b = new Blob([csv], {type: "octet/stream"})
  const url = URL.createObjectURL(b);
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.href = url;
  a.download = name ?? "config.json";
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}