import {applyMiddleware, combineReducers, createStore} from "redux";

import i18n from "./reducers/18n_reducer"
import {Language} from "../i18n/i18n";
import metersReducer from "./reducers/meters_reducer";
import {metersEffects} from "./effects/meter_effects";
import gatewaysReducers from "./reducers/gateways_reducers";
import {gatewaysEffects} from "./effects/gateways_effects";
import metersStatsReducer, {MeterStatsState} from "./reducers/meters_stats_reducer";
import logsReducer, {LogState} from "./reducers/log_reducer";
import {metersStatsEffects} from "./effects/meters_stats_effects";
import authReducer, {AuthState} from "./reducers/auth_reducer";
import {authEffects} from "./effects/auth_effects";
import dashboardReducer, {DashboardsState} from "./reducers/dashboards_reducer";
import gatewayStatsReducer, {GatewayStatsState} from "./reducers/gateway_stats_reducer";
import {gatewayStatsEffects} from "./effects/gateway_stats_effects";
import {dashboardEffects} from "./effects/dashboards_effects";
import {composeWithDevTools} from 'redux-devtools-extension';
import {logEffects} from "./effects/log_effects";
import statConfigReducer, {StatConfigState} from "./reducers/stat_config_reducer";
import {statConfigEffects} from "./effects/stat_config_effects";

export type AppState = {
  i18n: {
    lang: Language
  },
  logs: LogState,
  auth: AuthState,
  dashboard: DashboardsState,
  meterStats: MeterStatsState,
  gatewayStats: GatewayStatsState,
  statConfig: StatConfigState
}

export default createStore(
  combineReducers({
    i18n: i18n,
    meters: metersReducer,
    gateways: gatewaysReducers,
    meterStats: metersStatsReducer,
    logs: logsReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    gatewayStats: gatewayStatsReducer,
    statConfig: statConfigReducer
  }),
  composeWithDevTools(applyMiddleware(
    metersEffects,
    gatewaysEffects,
    metersStatsEffects,
    authEffects,
    gatewayStatsEffects,
    dashboardEffects,
    logEffects,
    statConfigEffects
  )),

);