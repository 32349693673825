import {GatewayStats} from "../../models/gateway_stats";

export type FetchGatewaysStats = {
  type: 'FETCH_GATEWAYS_STATS'
}
export const fetchGatewaysStatsAction = () => ({type: 'FETCH_GATEWAYS_STATS'})

export type SetGatewaysStats = {
  type: 'SET_GATEWAYS_STATS',
  gatewayStats: GatewayStats
}
export const setGatewaysStatsAction = (gatewayStats: GatewayStats) => ({
  type: 'SET_GATEWAYS_STATS',
  gatewayStats
})

export type SetGatewaysStatsError = {
  type: 'SET_GATEWAYS_STATS_ERROR',
  error: string
}

export const setGatewaysStatsErrorAction = (error: string) => ({
  type: 'SET_GATEWAYS_STATS_ERROR',
  error
})

export type GatewayStatsActions =
  | FetchGatewaysStats
  | SetGatewaysStats
  | SetGatewaysStatsError

