import {TitleValueWidget} from "../common/title_value_widget";
import React, {useState} from "react";
import {WidgetConfig} from "../../../../../models/dashboard/widgetConfig";
import {Log} from "../../../../../models/log";
import * as _ from "lodash";

type Props = {
  widget: WidgetConfig,
  logs: Log[]
}

export function NumericTypeWidget(props: Props, intlMessages: Record<string, any>) {
  const name = intlMessages[props.widget.name] ?? props.widget.name;

  return <TitleValueWidget
    title={name}
    value={extractValue(props.logs, props.widget)}
  />
}

export function extractValue(logs: Log[], widgetConfig: WidgetConfig): string {

  const source = widgetConfig.source;

  if(source.type !== "WEBSOCKET") return "Not supported"

  const log: Log | undefined = _.first(logs.filter(l => l.channel === source.channelName && l.eventName === source.eventName));

  try {
    if(log === undefined) return window.sessionStorage.getItem(`${source.channelName} ${source.eventName}`) ?? "--"

    const value = log.log.values[0][0].toString();

    window.sessionStorage.setItem(`${source.channelName} ${source.eventName}`, value);

    return value;
  } catch (e) {
    return '--'
  }
}
