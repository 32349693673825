import _ from "lodash";
import {ceil, floor} from "lodash-es";

export function swap<T>(array: T[], moveIndex: number, toIndex: number): T[]{
  /* #move - Moves an array item from one position in an array to another.
     Note: This is a pure function so a new array will be returned, instead
     of altering the array argument.
    Arguments:
    1. array     (String) : Array in which to move an item.         (required)
    2. moveIndex (Object) : The index of the item to move.          (required)
    3. toIndex   (Object) : The index to move item at moveIndex to. (required)
  */
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length)
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length)
    ];
  }
  return array;
}

export function permute<T>(array: T[], permuter: number[]) {
    return permuter.reduce((r:T[], v, i) => {
      r[i] = array[permuter[i]]
      return r
    }, [])
}

export function removeIds<T>(array: T[], remove: number[]) {
  return array.filter((t, i) => !remove.includes(i))
}

/*
* filters array, taking 1 / n itmes
* */
export function prune<T>(array: T[], factor: number) {
  return array.filter((_, i) => i % factor === 0);
}

export function prune_max_len<T>(array: T[], max_len: number) {
  const len = array.length;
  const factor = ceil(len / max_len);
  return prune(array, factor);
}