import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {createBrowserHistory} from 'history';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './store/store';
import {Route, Router, Switch} from 'react-router-dom';
import {Layout} from "./components/layout";
import {MetersPage} from "./pages/meters_page/meters_page";
import "./App.css";
import {menuRoutes} from "./models/menu_item_type";
import {I18n} from "./components/I18n";
import {GatewaysPage} from "./pages/gateways_page/gateways_page";
import {EditGroupPage} from "./pages/meter_group_page/edit_group_page";
import {EditMeterPage} from "./pages/meters_page/edit_meter_page";
import {DashboardsIndexPage} from "./pages/dashboard_page/dashboards_index_page/dashboards_index_page";
import {CreateMeterPage} from "./pages/meters_page/create_meter_page";
import {LoginPage} from "./pages/login_page/login_page";
import {UsersPage} from "./pages/users_page/users_page";
import {UsersGroupPage} from "./pages/user_groups_page/users_group_page";
import {CreateEditUserPage} from "./pages/users_page/create_edit_user_page";
import {CreateEditUserGroupPage} from "./pages/user_groups_page/create_edit_group_page";
import {AdminPage} from "./pages/admin_panel/admin_panel_page";
import {AdminFtpPage} from "./pages/admin_panel/admin_ftp_page";
import {AdminStoragePage} from "./pages/admin_panel/admin_storage_page";
import {LoadMetersPage} from "./pages/meters_page/load_meters_page/load_meters_page";
import {ScheduleFrimwareUpdate} from "./pages/meters_page/fw-update/schedule_firmware_update_page";
import {ScheduleHighFrequencyRead} from "./pages/meters_page/schedule_high_frequency_page/schedule_high_frequency_page";
import {CreateGatewayPage} from "./pages/gateways_page/create_gateway_page";
import {EditGatewayPage} from "./pages/gateways_page/edit_gateway_page";
import {OwnerConfigurationPage} from "./pages/admin_panel/admin_owner_config_page";
import {AdminDigitalTwinPage} from "./pages/admin_panel/admin_digital_twin_page";
import {DataExplorerPage} from "./pages/data_explorer_page/data_explorer_page";


export const history = createBrowserHistory();

//          export const BASE_URL = "https://swarm-api.inkwelldata.net";

// export const BASE_URL = "https://swarm-api.inkwelldata.net";
// export const APP_BASE_URL = "http://192.168.1.32:3000";
// export const WEB_SOCKET_ADDRESS = "wss://swarm-api.inkwelldata.net/socket";

export const BASE_URL = process.env.NODE_ENV !== 'production'
  ? "http://192.168.1.32:4002"
  : "https://swarm-api.inkwelldata.net";

export const APP_BASE_URL = process.env.NODE_ENV !== 'production'
? "http://192.168.1.32:3000"
: "https://swarm.inkwelldata.net";

export const WEB_SOCKET_ADDRESS = process.env.NODE_ENV !== 'production'
? "ws://192.168.1.32:4002/socket"
: "wss://swarm-api.inkwelldata.net/socket";

export const fallbackMapCenter = {"lat":45.416668,"lng":11.866667};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18n>
        <Router history={history}>
          <Switch>
            <Route path={ menuRoutes["Dashboards"] }>
              <Layout selectedItem={"Dashboards"}>
                <DashboardsIndexPage/>
              </Layout>
            </Route>
            <Route path={ "/gateways/new" }>
              <Layout selectedItem={"Network"}>
                <CreateGatewayPage/>
              </Layout>
            </Route>
            <Route path={ "/gateways/:id/edit" }>
              <Layout selectedItem={"Network"}>
                <EditGatewayPage/>
              </Layout>
            </Route>
            <Route path={ menuRoutes["Network"] }>
              <Layout selectedItem={"Network"}>
                <GatewaysPage/>
              </Layout>
            </Route>
            <Route path={ "/meters/new" }>
              <Layout selectedItem={"Meters"}>
                <CreateMeterPage/>
              </Layout>
            </Route>
            <Route path={ "/meters/load" }>
              <Layout selectedItem={"Meters"}>
                <LoadMetersPage/>
              </Layout>
            </Route>
            <Route path={ "/meters/fw-update" }>
              <Layout selectedItem={"Meters"}>
                <ScheduleFrimwareUpdate/>
              </Layout>
            </Route>
            <Route path={ "/data-explorer/:id" }>
              <Layout selectedItem={"Data Explorer"}>
                <DataExplorerPage/>
              </Layout>
            </Route>
            <Route path={ "/data-explorer" }>
              <Layout selectedItem={"Data Explorer"}>
                <DataExplorerPage/>
              </Layout>
            </Route>
            <Route path={ "/meters/high-frequency-update" }>
              <Layout selectedItem={"Meters"}>
                <ScheduleHighFrequencyRead/>
              </Layout>
            </Route>
            <Route path={ "/meters/:id/edit" }>
              <Layout selectedItem={"Meters"}>
                <EditMeterPage/>
              </Layout>
            </Route>
            <Route path={ menuRoutes["Meters"] }>
              <Layout selectedItem={"Meters"}>
                <MetersPage/>
              </Layout>
            </Route>
            <Route path={ "/groups/new" }>
              <Layout selectedItem={"Meters"}>
                <EditGroupPage/>
              </Layout>
            </Route>
            <Route path={ "/groups/:id/edit" }>
              <Layout selectedItem={"Meters"}>
                <EditGroupPage/>
              </Layout>
            </Route>
            <Route path={ "/admin/ftp" }>
              <Layout selectedItem={"Admin"}>
                <AdminFtpPage/>
              </Layout>
            </Route>
            <Route path={ "/admin/storage" }>
              <Layout selectedItem={"Admin"}>
                <AdminStoragePage/>
              </Layout>
            </Route>
            <Route path={ "/admin/owner-config" }>
              <Layout selectedItem={"Admin"}>
                <OwnerConfigurationPage/>
              </Layout>
            </Route>
            <Route path={ "/admin/digital-twin" }>
              <Layout selectedItem={"Admin"}>
                <AdminDigitalTwinPage/>
              </Layout>
            </Route>
            <Route path={ menuRoutes["Admin"] }>
              <Layout selectedItem={"Admin"}>
                <AdminPage/>
              </Layout>
            </Route>
            <Route path={ "/users/new" }>
              <Layout selectedItem={"Users"}>
                <CreateEditUserPage/>
              </Layout>
            </Route>
            <Route path={ "/users/:id/edit" }>
              <Layout selectedItem={"Users"}>
                <CreateEditUserPage/>
              </Layout>
            </Route>
            <Route path={ menuRoutes["Users"] }>
              <Layout selectedItem={"Users"}>
                <UsersPage/>
              </Layout>
            </Route>
            <Route path={ "/user-groups/new" }>
              <Layout selectedItem={"Groups"}>
                <CreateEditUserGroupPage/>
              </Layout>
            </Route>
            <Route path={ "/user-groups/:id/edit" }>
              <Layout selectedItem={"Groups"}>
                <CreateEditUserGroupPage/>
              </Layout>
            </Route>
            <Route path={ "/user-groups" }>
              <Layout selectedItem={"Groups"}>
                <UsersGroupPage/>
              </Layout>
            </Route>
            <Route path={ "/login" }>
              <LoginPage/>
            </Route>
            <Route path={ "/login/:token" }>
              <LoginPage/>
            </Route>
            <Route>
              <LoginPage/>
              {/*<EditablePolygonMap*/}
              {/*  onEdit={console.log}*/}
              {/*  fallbackMapCenter={{lat: 42.7, lng: 12.12}}*/}
              {/*  // polygonCoordinates={[{"lat":42.698333572536775,"lng":12.223320007324219},{"lat":42.71321864876737,"lng":12.034492492675783},{"lat":42.67511582354274,"lng":12.119979858398438}]}*/}
              {/*/>*/}
              {/*<EditableMarkerMap onEdit={console.log} fallbackMapCenter={{"lat":42.698333572536775,"lng":12.223320007324219}} markerCoordinates={{"lat":42.698333572536775,"lng":12.223320007324219}}/>*/}
              {/*<Leafleet*/}
              {/*  onViewPortChange={console.log}*/}
              {/*fallbackMapCenter={*/}
              {/*  {*/}
              {/*    lat: 51.505,*/}
              {/*    lng: -0.09*/}
              {/*  }*/}
              {/*}*/}
              {/*staticMapItems={*/}
              {/*  geoJsonCluesterMakers.features.map((item) => ({*/}
              {/*    feature: item,*/}
              {/*    popUp: (_) => <Popup>*/}
              {/*      A pretty CSS3 popup. <br/> Easily customizable.*/}
              {/*    </Popup>*/}
              {/*  }))*/}
              {/*}*/}
              {/*  editableItem={point}*/}
              {/*  onEdit={console.log}*/}
              {/*/>*/}
              {/*<CustomizedTreeView items={demoMap}/>*/}
            </Route>
          </Switch>
        </Router>
      </I18n>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

//  TODO enable in production to handle cache
serviceWorker.unregister();

