import {
  FETCH_METERS_STATS,
  MeterStatsAction,
  SET_METERS_STATS,
  SET_METERS_STATS_ERROR
} from "../actions/meters_stats_actions";
import {MetersStats} from "../../models/meter_stats";

export type MeterStatsState = {
  error: string,
  loading: boolean,
  meterStats: MetersStats | undefined
}

const initialState: MeterStatsState = {
  meterStats: undefined,
  error: "",
  loading: false

};

export default function(state  = initialState, action: MeterStatsAction): MeterStatsState {
  switch (action.type) {
    case SET_METERS_STATS: {
      return {
        meterStats: action.meterStats,
        error: "",
        loading: false
      };
    }
    case SET_METERS_STATS_ERROR: {
      return {
        ...state,
        error: action.error || "",
        loading: false
      };
    }
    case FETCH_METERS_STATS: {
      return {
        ...state,
        loading: true
      }
    }
    default:
      return state;
  }
}
