import {GatewayApplicationConfig, GatewayConfig,} from "../../../models/gateway_config";
import {ApplicationType} from "../../../models/application_type";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {IndexTable} from "../../../components/index_table";
import {showConfigurationEditor} from "../../../components/configuration_editor/configuration_editor";
import {
  applicationTypeFromGatewayApplicationConfig,
  gatewayApplicationConfigFromApplicationType,
} from "./gateway_config_vm";
import {
  configToConfigurationValues,
  configurationValuesToConfig
} from "../../../components/configuration_editor/configuration_editor_vm";
import {ConfigurationValue} from "../../../models/configuration";
import {confirmAlert} from "react-confirm-alert";
import {TrashIcon} from "../../../components/icons";
import Select from "react-select";
import _ from "lodash";

type Props = {
  config: GatewayConfig,
  applicationTypes: ApplicationType[]
  onConfigurationChange: (gc: GatewayConfig) => void
}

export function GatewayApplicationConfigPanel(props: Props) {

  const intl = useIntl();
  const [creationMode, setCreationMode] = useState(false);

  return <div className={"col-md-6"}>
    <h3>{intl.messages["app_config"].toString().toUpperCase()}</h3>
    <div className={"log-panel ellipsis"}>
      <IndexTable
        headers={['Name', 'Version', 'Actions']}
        columns={props.config.application ?? []}
        paginationVisible={false}
        onTap={(applicationConfig: GatewayApplicationConfig) => {
          showConfigurationEditor({
            configurationSpecs: applicationTypeFromGatewayApplicationConfig(applicationConfig, props.applicationTypes)?.attributes?.configurations ?? [],
            configurationValues: configToConfigurationValues(applicationConfig.configurations),
            onClose(): void {},
            intl: intl.messages as any,
            onSave(c: ConfigurationValue[]): void {
              const newHardwareConfig = {...applicationConfig, configurations: configurationValuesToConfig(c)}



              props.onConfigurationChange({
                ...props.config,
                application: [
                  ...(props?.config?.application?.filter((ghc) => JSON.stringify(ghc) !== JSON.stringify(applicationConfig)) ?? []),
                  newHardwareConfig
                ]
              })
            },
            title: applicationConfig.name
          })
        }}
        rowBuilder={
          (h: GatewayApplicationConfig) => [
            <p> {h.name} </p>,
            <p> {h.version} </p>,
            <button className={"icon-button"} onClick={(e) => {
              e.stopPropagation();
              confirmAlert({
                title: intl.messages["confirm"].toString(),
                message: intl.messages["this_action_cant_be_undone"].toString(),
                buttons: [
                  {
                    label: 'Yes',
                    onClick: async () => {
                      props.onConfigurationChange({
                        ...props.config,
                        application: props.config.application.filter((hc) => JSON.stringify(hc) !== JSON.stringify(h))
                      })
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {}
                  }
                ]
              });

            }}>
              <TrashIcon/>
            </button>
          ]
        }
      />
      { creationMode &&
      <div className={"mb-5 mr-2"}>
          <h3>{intl.messages["select_config_type"].toString().toUpperCase()}</h3>
          <Select
              value={ {label: "", value: ""} }
              onChange={(g: any) => {
                const type: ApplicationType | undefined = _.first(props.applicationTypes.filter((at) => at.id === g.value));
                setCreationMode(false);
                if(type) {
                  props.onConfigurationChange({
                    ...props.config,
                    application: [
                      ...(props?.config?.application ?? []),
                      gatewayApplicationConfigFromApplicationType(type)
                    ]
                  });
                }
              }}
              isMulti={false}
              options={ props.applicationTypes.map((at) => ({ label: at.attributes.name, value: at.id })) }
              name="device type id"
              className="basic-multi-select"
              classNamePrefix="select"
          />
      </div>
      }

      <div className={"d-flex flex-row-reverse"}>
        {
          creationMode
            ? <button className={"p3 primary-button mr-2 mt-5"}  onClick={() => { setCreationMode(false) }}>
              {intl.messages['cancel']}
            </button>
            : <button className={"p3 primary-button mr-2"}  onClick={() => { setCreationMode(true) }}>
              {intl.messages['add']}
            </button>
        }
      </div>

    </div>
  </div>

}