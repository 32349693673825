import React, {useEffect, useMemo, useState} from "react";
import './data_explorer.css';
import { useIntl } from "react-intl";
import {showFilterCreation} from "./modals/filter_creation_modal";
import {CustomConnector} from "./and_connector";
import {DataSource, eventFilterAst, eventFilterToString, filtersAstFromFilterMatrix} from "./ast/ast";
import { AddFilterWidget } from "./filter_widgets/add_filter_widget";
import { FiltersOrWidget } from "./filter_widgets/filters_or_widget";
import {DataExplorerPageHeader} from "./data_explorer_page_header/data_explorer_page_header";
import {DataSourceWidget} from "./filter_widgets/data_source_widget";
import {showDataSourceCreation} from "./modals/data_source_creation_modal";
import {emptyFilterCollection, Filter, FilterCollection} from "../../models/filter";
import { fetchPaginatedCollection } from "../../repsitory/generic_repository";
import {showExportFileDialog} from "../../components/export_file_dialog";
import {DynamicPaginatedListComponent} from "../../components/paginated_list/dynamic_paginated_list_component";
import {CogIcon} from "../../components/icons";
import {showColumnOrderModal} from "./columns_edit_modal/columns_edit_modal";
import {applyColumnTransform} from "../../contexts/filter_collection_context";
import {Divider} from "../../components/divider";
import {addAnd, addOr, remove} from "./data_explorer_page_vm";
import {AddOrReduceFilterWidget} from "./filter_widgets/add_or_reduce_filter_widget";
import {showDataReducerCreation} from "./modals/data_reducer_creation_modal";
import {ReduceFilter} from "./filter_widgets/reduce_filter/reduce_filter";
import _ from "lodash";
import {showDataChartReducerCreation} from "./modals/data_chart_reducer_creation_modal";

//  If data source is set there's no need to choose it
export function DataExplorerPage(props: {fixedDataSource?: DataSource}) {

  const intl = useIntl();

  const [filterCollection, setFilterCollection] = useState<FilterCollection>({...emptyFilterCollection, dataSource: props.fixedDataSource ?? null})

  const [columnNames, setColumnNames] = useState<string[]>([]);

  const should_display_data_source = filterCollection.dataSource !== null && !props.fixedDataSource;

  return <div>
    <div className={"row"}>
      <div className={"col-md-12"}>
        <DataExplorerPageHeader
          fixedDataSource={props.fixedDataSource}
          filterCollection={filterCollection}
          onCollectionSelected={(collection) => setFilterCollection(collection)}
        />
      </div>
    </div>
    <div className={"row mt-2"}>
      <div className={"widget-container"}>

        {filterCollection.dataSource === null && <AddFilterWidget
              onAdd={
                () => showDataSourceCreation(intl.messages as any, (d) => setFilterCollection({...filterCollection, dataSource: d}))
              } text={"INIT"}/>
        }

        { should_display_data_source &&
        <DataSourceWidget
          initialCollapsed={(filterCollection?.filters?.length ?? 0) > 0}
            selectedDataSource={filterCollection.dataSource}
            intl={intl.messages as any}
            onRemove={() => setFilterCollection(emptyFilterCollection)}
        />
        }

        {filterCollection.filters.map((fs: Filter[], i) => <FiltersOrWidget
            key={`fs${i}`}
            filters={fs}
            intl={intl.messages as any}
            onClose={(f, j) => setFilterCollection({...filterCollection, filters: remove(filterCollection.filters, i, j), })}
            onClick={(f) => console.log(f)}
            onAddSibling={(n) => showFilterCreation(
              intl.messages as any,
              (f) => setFilterCollection({...filterCollection, filters: addOr(filterCollection.filters, f, i)}),
              filterCollection.dataSource
            )}
          />).flatMap((w, i) => {
            return i === 0 ? [
              <CustomConnector
                key={"aaa"}
                name={"FILTER"}
                left_arity={1}
                right_arity={ filterCollection.filters.length > 0 ? filterCollection.filters[0].length : 1 }
              />, w
            ] : [
              <CustomConnector
                key={"aa" + i}
                left_arity={filterCollection.filters[i].length}
                right_arity={ filterCollection.filters.length > i + 1 ? filterCollection.filters[i + 1].length : 1 }
              />,
              w
              ]
          })
        }
        {filterCollection.dataSource !== null && !filterCollection.reducer  && [
          <CustomConnector
            key={"r"}
            left_arity={(_.last(filterCollection.filters) ?? [0]).length}
            right_arity={ 3 }
            rightCompact
            name={"ADD"}
          />,
          <AddOrReduceFilterWidget onAdd={() => {
          showFilterCreation(
            intl.messages as any,
            (f) => setFilterCollection({...filterCollection, filters: addAnd(filterCollection.filters, f)}),
            filterCollection.dataSource
          );
        }} addText={ "AND"}
          onReduce={() => {
            showDataReducerCreation(
              intl.messages as any,
              filterCollection.dataSource,
              (reducer) => setFilterCollection({...filterCollection, reducer})
            )
          }}
          reduceText={"REDUCE"}
         onReduceChart={() => {
           showDataChartReducerCreation(
             intl.messages as any,
             filterCollection.dataSource,
             columnNames,
             (reducer) => setFilterCollection({...filterCollection, reducer})
           )
         }}
         reduceChartText={"PLOT"}
        />]
        }

        {/*Actual show of reduce component*/}
        {filterCollection.dataSource !== null && filterCollection.reducer  &&
        [
          <CustomConnector
            key={"r"}
            left_arity={(_.last(filterCollection.filters) ?? [0]).length}
            right_arity={ 1 }
            name={"REDUCE"}
          />,
          <ReduceFilter filterCollection={filterCollection} intl={intl.messages as any} onClose={() => setFilterCollection({...filterCollection, reducer: null})}/>
          ]
        }


      </div>
    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex justify-content-between"}>
        <p style={{marginTop: 10}}>{eventFilterToString(eventFilterAst(filterCollection))}</p>
        { filterCollection.dataSource !== null && <button className={"outline-button"} style={{height: 36}} onClick={
          () => showColumnOrderModal(
            intl.messages as any,
            applyColumnTransform(columnNames, filterCollection.columnTransform),
            (order: number[], visibleColumns: boolean[]) => {setFilterCollection({...filterCollection, columnTransform: order, visibleColumns})},
            filterCollection.visibleColumns,
            filterCollection.columnTransform
          )
        }>
          <div className={"d-flex"}>
              <p className={"mr-2"} style={{marginTop: 1}}>{intl.messages["columns_order"].toString()}</p>
              <CogIcon/>
          </div>
        </button>}
      </div>
    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12 scroll-x"}>
        <Divider className={"mt-0 mb-3"}/>
        { !filterCollection.dataSource && <h2>{intl.messages["please-select-a-filter-by-pressing-init"]}</h2>}
        { filterCollection?.dataSource && <DynamicPaginatedListComponent
          visibleColumns={ filterCollection.visibleColumns }
          columnPermutations={filterCollection.columnTransform}
          onHeaders={(h) => setColumnNames(h)}
          key={JSON.stringify(filterCollection)}
          fetchCollection={fetchPaginatedCollection<any>(
            `/api/v1/events?q=${encodeURIComponent(JSON.stringify(eventFilterAst(filterCollection)))}`
          )}
          perPage={10}
        />}
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12 d-flex justify-content-end"}>
        {filterCollection.dataSource && <button
          style={{marginTop: -25}}
          onClick={() => showExportFileDialog(intl.messages as any, eventFilterAst(filterCollection))}
          className={"outline-button"}>{intl.messages["export_current_selection"].toString()}
        </button> }
      </div>
    </div>
  </div>
}

