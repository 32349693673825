import {Dispatch, Middleware, MiddlewareAPI} from "redux"
import {FETCH_GATEWAYS, setGatewaysAction, setGatewaysErrorAction} from "../actions/gateways_actions";
import {Gateway} from "../../models/gateway";
import {remoteFetchData} from "../../repsitory/generic_repository";


export const gatewaysEffects: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch) => ((action: any) => {


  switch (action.type) {
    case FETCH_GATEWAYS:
      console.log("[Gateways effect] fetch gateways");
      remoteFetchData<Gateway[]>("/api/v1/gateway")
        .then((m) => {
          if(typeof m === "string") {
            console.log("[Gateways effect] error" + m);
            api.dispatch(setGatewaysErrorAction(m));

          } else {
            api.dispatch(setGatewaysAction(m));
            console.log("[Gateways effect] success");
          }
          next(action)
        })
        .catch(e => {
          api.dispatch(setGatewaysErrorAction(e));
          console.log("[Gateways effect] error" + e);
          next(action)
        });

    default: next(action)
  }

}) as Dispatch;