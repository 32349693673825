import React from "react";

type Props = {
    title: string,
    value: string
}

export function TitleValueWidget(props: Props) {
    return (<div className={"d-flex flex-column justify-content-between title-value-widget"}>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <h3 style={{color: "#fff"}} className={"m-2"}> {props?.title?.toUpperCase() ?? ""} </h3>
            </div>
        </div>
        <div className={"row"}>
            <div className={"col-md-12 d-flex flex-row-reverse align-items-end"}>
                <p className={"dashboard-value"}> {props.value} </p>
            </div>
        </div>
    </div>);
}