import React from 'react';

type Props = {
  hint?: string
  title?: string
  onChange: (s: string) => void
  value: string

};

export function UnderLineInputText(props: Props) {

  return (
    <div>
      {props.title && <div className={"row"}>
          <div className={"col-md-12"}>
              <h3>{ props.title }</h3>
          </div>
      </div>}
      <div className={"row"}>
        <div className={"col-md-12"}>
          <div className={"d-flex justify-content-between"}>
            <input
              type={"text"}
              className="underline-input"
              aria-label="Text input with radio button"
              onChange={event => props.onChange(event.target.value)}
              placeholder={props.hint || ""}
              value={props.value || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
