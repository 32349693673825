import React from "react";
import {JobSchedule, mkJobSchedule} from "./job_schedule_form_vm";
import {useIntl} from 'react-intl';
import {InputText} from "../input_text";
import Select from "react-select";
// @ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker-override.css";


type Props = {
  jobSchedule: JobSchedule,
  setJobSchedule: (js: JobSchedule) => void
}

export function JobScheduleForm(props: Props) {

  const intl = useIntl();

  return <div>
    <div className={"row mb-3"}>
      <div className={"col-md-12"}>
        <div className={"mb-2"}>
          <h3>{intl.messages["operation_type"].toString().toUpperCase()}</h3>
          <Select
            value={ {label: intl.messages[props.jobSchedule.type], value: props.jobSchedule.type} }
            onChange={(g: any) => {
              props.setJobSchedule(mkJobSchedule(g.value))
            }}
            isMulti={false}
            options={["job", "cron"].map((g: any) => ({ value: g, label: intl.messages[g] }))}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </div>
    </div>
    {props.jobSchedule.type === "cron" && <div className={"row"}>
      <div className={"col-md-12"}>
        <h3> {intl.messages["cron"].toString().toUpperCase()} </h3>
        <InputText
          value={props.jobSchedule.cron}
          onChange={
            c => props.setJobSchedule({type: "cron", cron: c})
          }
        />
      </div>
    </div>}
    {props.jobSchedule.type === "job" && <div className={"row"}>
      <div className={"col-md-12"}>
        <h3> {intl.messages["schedule_date"].toString().toUpperCase()} </h3>
          <DatePicker
            className={"form-control"}
            selected={props.jobSchedule.date}
            onChange={ (date: Date) => props.setJobSchedule({type: "job", date})}
            showTimeSelect
            dateFormatCalendar={"dd-MM-yyyy"}
            timeFormat="HH:mm"
            timeIntervals={5}
            timeCaption="time"
            dateFormat="dd-MM-yyyy h:mm aa"
          />
      </div>
    </div>}
  </div>
}