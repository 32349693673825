import React, {ReactNode} from 'react';
import {useSelector} from "react-redux";
import {AppState} from "../store/store";
import messages, {Language} from "../i18n/i18n";
import {IntlProvider} from "react-intl";

type Props = {
  children: ReactNode
};

export function I18n(props: Props) {
  const lang: Language = useSelector((s: AppState) => s.i18n.lang);
  return (
    <IntlProvider locale={lang} messages={messages[lang]} >
      {props.children}
    </IntlProvider>
  );
}
