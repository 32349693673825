import React from "react";

import * as H from 'history'
import {post, putStatusCode} from "../../repsitory/generic_repository";
import {UserGroup} from "../../models/user_group";
import * as _ from "lodash";
import {removeUserPermissionFromGroup} from "../../repsitory/user_permission_repository";
import {UserPermission} from "../../models/user_permission";

//  Page state
export type EditGroupPageState = {
  name: string
  id: string
  description: string
  authorizations: string[]
  validationErrors: string[]
  loading: boolean
  error: string
}

const validate = (state: EditGroupPageState) => {
  let errors: string[] = [];
  return errors;
};


//  Page effects
export const createEditUserGroup = async(state: EditGroupPageState, oldUserPermissions: UserPermission[], setState: React.Dispatch<React.SetStateAction<EditGroupPageState>>, history: H.History) =>  {

  const validation = validate(state);

  if(validation.length > 0) {
    setState({...state, validationErrors: validation});
    return;
  } else {
    setState({...state, loading: true})
  }



  try {
    let result;
    let groupId = state.id;

    if(state.id === undefined || state.id === "") {

      const data = {
        "data": {
          "attributes": {
            "name": state.name,
            "attributes": { "description": [state.description] },
            "id": state.id || undefined
          },
          "type": "user_group"
        }
      };

      result = await post(data,  '/api/v1/user/group');
      groupId = result.data.attributes.id;


    } else {

      const data = {
        "data": {
          "attributes": {
            "attributes": { "description": [state.description] },
            "id": state.id || undefined
          },
          "type": "user_group"
        }
      };

      result = await putStatusCode(data,  `/api/v1/user/group/${groupId}`);
    }

    if(result.errors !== undefined) {
      setState({...state, error: JSON.stringify(result), loading: false})
      return
    }

    const syncUserPermissionSuccess = await syncUserPermissions(
      oldUserPermissions.map(up => up.id),
      state.authorizations,
      groupId
    );

    setState({...state, loading: false, error: syncUserPermissionSuccess ? "" : "Cannot sync user permissions"});
    history.push('/user-groups');

  } catch (e) {
    setState({...state, error: e.toString(), loading: false})
  }
};

export async function syncUserPermissions(oldUserPermissionIds: string[], newUserPermissionIds: string[], groupId: string): Promise<boolean> {

  console.log(`About to sync`);

  return (await Promise.all([
    addUserPermissions(oldUserPermissionIds, newUserPermissionIds, groupId),
    removeUserPermissions(oldUserPermissionIds, newUserPermissionIds, groupId)
  ])).reduce((a, b) => a && b);
}

export async function addUserPermissions(oldUserPermissionIds: string[], newUserPermissionIds: string[], groupId: string): Promise<boolean> {

  const permissionsToAdd = _.difference(newUserPermissionIds, oldUserPermissionIds);

  if(permissionsToAdd.length === 0) { return true; }

  console.log(`About to add ${permissionsToAdd}`);

  const data = {"data": {
    "attributes": {
      "permissions": permissionsToAdd
    },
    "type": "user_group"
  }};
  const response = (await putStatusCode(data, `/api/v1/user/group/${groupId}/permission`));

  console.log(response);
  return response === 204;
}

export async function removeUserPermissions(oldUserPermissionIds: string[], newUserPermissionIds: string[], groupId: string): Promise<boolean> {
  console.log(`About to remove ${_.difference(oldUserPermissionIds, newUserPermissionIds)}`);
  return (await Promise
          .all(_.difference(oldUserPermissionIds, newUserPermissionIds)
              .map((userPermissionId: string) => removeUserPermissionFromGroup(userPermissionId, groupId)))
  ).reduce((p, n) => p && n, true);
}



export const mkUserGroupState: (u: UserGroup) => EditGroupPageState = (userGroup: UserGroup) => ({
  name: userGroup.name || "",
  id: userGroup.id || "",
  description: _.first(userGroup?.additionalAttributes?.description ?? []) ?? "",
  authorizations: [],
  validationErrors: [],
  loading: false,
  error: ""
});