import {FilterCollection} from "../../models/filter";
import {eventFilterAst, reducedEventFilterAst} from "./ast/ast";
import {remoteFetchData} from "../../repsitory/generic_repository";

export function removeReduce(f: FilterCollection) {
  return {...f, reducer: null}
}

export async function queryReducedValue(url: string): Promise<string> {
  const result = await remoteFetchData<any>(url);

  try {
    // @ts-ignore
    const v = Object.values(result[0].attributes)[0];
    if(typeof v === "number") {
      try{
        return Number(v).toFixed(2)
      } catch (e) {
        return v.toFixed(2)
      }
    }
    // @ts-ignore
    return v.toString();
  } catch (e) {
    return "Error"
  }

}

export function reducedValueQueryUrl(f: FilterCollection): string {
  return `/api/v1/events?q=${encodeURIComponent(JSON.stringify(reducedEventFilterAst(f)))}`;
}

export async function queryReducedChartValue(url: string): Promise<any> {
  return await remoteFetchData<any>(url);
}