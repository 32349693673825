import {GatewayStatsActions,} from "../actions/gateways_stats_actions";
import {GatewayStats} from "../../models/gateway_stats";


export type GatewayStatsState = {
  error: string,
  loading: boolean,
  gatewayStats: GatewayStats | undefined
}

const initialState: GatewayStatsState = {
  gatewayStats: undefined,
  error: "",
  loading: false

};

export default function(state  = initialState, action: GatewayStatsActions): GatewayStatsState {
  switch (action.type) {
    case "FETCH_GATEWAYS_STATS":
      return {...state, loading: true, error: ""}
    case "SET_GATEWAYS_STATS":
      return {...state, loading: false, error: "", gatewayStats: action.gatewayStats}
    case "SET_GATEWAYS_STATS_ERROR":
      return {...state, loading: false, error: action.error, }
    default: return state
  }
}
