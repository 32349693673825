import {JobSchedule} from "../components/job_schedule_form/job_schedule_form_vm";
import {httpDelete, postRaw} from "./generic_repository";
import {Job} from "../models/job";

export async function scheduleJob(jobSchedule: JobSchedule, params: any, workerId?: number) {

  let data: any = {
    "data": {
      "attributes": {
        "additionaParameters": params,
        "worker_id": workerId ?? 1,
        "type": "job"
      },
      "type": "job"
    }
  };

  switch (jobSchedule.type) {
    case "cron":
      data.data.attributes.type = "cron";
      data.data.attributes.cron = jobSchedule.cron;
      break;
    case "job":
      data.data.attributes.type = "job";
      data.data.attributes.scheduled_at = jobSchedule.date.toISOString()
      break;
  }

  return postRaw(data, "/api/v1/job")

}

export async function deleteJob(job: Job) {
  return httpDelete(`/api/v1/job/${job.id.toString()}/type/${job.type}`);
}