import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Link, useHistory} from "react-router-dom";
import {ErrorComponent} from '../../components/error_component';
import * as vm from './edit_gateway_page_vm';
import {
  backHaulToGroupOption,
  backhaulWithId,
  calculateRadius,
  createEditGateway,
  fetchAltiorGatewayTypes,
  fetchApplicationStatus,
  fetchApplicationTypes,
  fetchBackHauls,
  fetchHardwareStatus,
  fetchHardwareTypes,
  mkGatewayState
} from './edit_gateway_page_vm';
import {EditableRow} from "../../components/InfoRow";
import {gpsFromGeoJsonPoint} from "../../service/address_from_coordinates";
import {emptyGateway, Gateway} from "../../models/gateway";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import {showConfigurationEditor} from "../../components/configuration_editor/configuration_editor";
import {
  configToConfigurationValues,
  configurationValuesToConfig
} from "../../components/configuration_editor/configuration_editor_vm";
import {Command, ConfigurationValue} from "../../models/configuration";
import {Divider} from "../../components/divider";
import {GatewayConfigPanel} from "./gateway_config/gateway_config_panel";
import {showGatewayConfigImportDialog} from "../../components/gateway_config_import_dialog";
import {showAlert} from "../../components/alerts/alert";
import {downloadJsonFile} from "../../service/download_json_file";
import {CommandInvocationWidget} from "../../components/command_invocation_widget/command_invocation_widget";
import {
  execGatewayCommand,
  fetchGatewayCommands
} from "../../components/command_invocation_widget/command_invocation_view_model";
import {Leafleet} from "../../components/leafleet_map/leafleet_map";
import {fallbackMapCenter} from "../../index";
import {GeoJSONPoint, MapItem} from "../../models/geo_json";
import {EyeIcon, EyeOffIcon} from "../../components/icons";
import {fetchMeterGatewayCluster} from "../../repsitory/meter_cluster_repository";


export function EditGatewayPage() {

  const history = useHistory();
  const gateway: Gateway = (history?.location?.state as Gateway) ?? emptyGateway();

  const [state, setState] = useState<vm.EditGatewayPageState>(mkGatewayState(gateway));
  const [deviceTypeSelectValue , setDeviceTypeSelectValue ] = useState<{value: string, label: string}>({value: state.deviceTypeId ?? "", label: "loading"})
  const [commands, setCommands] = useState<Command[]>([]);

  const intl = useIntl();

  useEffect(() => {
    (async () => {

      const hardwareTypes = await fetchHardwareTypes();
      if(typeof hardwareTypes === "string") { setState({...state, error: hardwareTypes}); return; }

      const hardwareStatus = await fetchHardwareStatus();
      if(typeof hardwareStatus === "string") { setState({...state, error: hardwareStatus}); return; }

      const applicationTypes = await fetchApplicationTypes();
      if(typeof applicationTypes === "string") { setState({...state, error: applicationTypes}); return; }

      const applicationStatus = await fetchApplicationStatus();
      if(typeof applicationStatus === "string") { setState({...state, error: applicationStatus}); return; }

      const altiorGatewayTypes = await fetchAltiorGatewayTypes();
      if(typeof altiorGatewayTypes === "string") { setState({...state, error: altiorGatewayTypes}); return; }

      const backHauls = await fetchBackHauls();
      if(typeof backHauls === "string") { setState({...state, error: backHauls}); return; }

      if(gateway.id !== undefined) {
        const commands = await fetchGatewayCommands(gateway.id);
        if(typeof commands === "string") { setState({...state, error: commands}); return; }
        setCommands(commands);
      }


      setState({...state, applicationStatus, hardwareTypes, hardwareStatus, applicationTypes, backHauls, altiorGatewayTypes});


      const type = _.first(altiorGatewayTypes?.filter((agt) => agt.attributes.deviceTypeId === state.deviceTypeId) ?? []) ?? _.first(altiorGatewayTypes);
      setDeviceTypeSelectValue({label: type?.attributes?.name ?? "", value: type?.attributes?.deviceTypeId?.toString() ?? ""})

    })();
  }, [0])

  const radioCoverageCircle: MapItem[] = (() => {
    const radius = calculateRadius(state);
    if(typeof radius === "string" || (!state.radioCoverageVisible)) return [];
    return [{feature: {...state.coordinates!, properties: {...state!.coordinates!.properties, radius}}, popUp: (_: any) => null}]
  })();


  if(state.error !== "") return <ErrorComponent message={state.error}/>;
  if(state.loading) return <p>Loading</p>;


  return (
    <div>
      <div className={"row"}>
        <div className={"col-md-3"}>
          <EditableRow title={"serial"} value= {state.serial} onEdit={(s) => setState({...state, serial: s})}/>
        </div>
        <div className={"col-md-3"}>
          <EditableRow title={intl.messages["identifier"].toString()}  value={state.identifier} onEdit={(s) => setState({...state, identifier: s})} />
        </div>
        <div className={"col-md-3"}>
          <h3> {intl.messages["install_date"].toString().toUpperCase()} </h3>
          <input
            className="form-control"
            type="date"
            data-date-format="DD/MM/YYYY"
            defaultValue={state.installDate.toDateString()}
            value={moment(state.installDate).format("YYYY-MM-DD")}
            onChange={ (s) => setState({...state, installDate: new Date(s.target.value)}) }
          />
        </div>
        <div className={"col-md-3"}>

          <div className={"row"}>
            <div className={"col-md-4 pr-0"}>
              <h3>{ intl.messages["lat"].toString().toUpperCase()} </h3>
              <input
                type='number'
                className='form-control mb-2'
                value={state?.coordinates?.geometry?.coordinates[1]?.toString() || ""}
                onChange= {(evt) => {
                  if(state.coordinates) {
                    const coordinates = {
                      ...state.coordinates,
                      geometry: {
                        ...state.coordinates.geometry,
                        coordinates: [state.coordinates.geometry.coordinates[0], Number(evt.target.value)]
                      }
                    };
                    setState({...state, coordinates})
                    // gpsFromGeoJsonPoint(coordinates).then(location => setState({...state, coordinates, location}))
                  }
                }}
              />
            </div>
            <div className={"col-md-4 pr-0 pl-0"}>
              <h3>{intl.messages["lng"].toString().toUpperCase()}</h3>
              <input
                type='number'
                className='form-control mb-2'
                value={state?.coordinates?.geometry?.coordinates[0]?.toString() || ""}
                onChange= {(evt) => {
                  if(state.coordinates) {
                    const coordinates = {
                      ...state.coordinates,
                      geometry: {
                        ...state.coordinates.geometry,
                        coordinates: [Number(evt.target.value), state.coordinates.geometry.coordinates[1]]
                      }
                    };
                    setState({...state, coordinates})
                    // gpsFromGeoJsonPoint(coordinates).then(location => setState({...state, coordinates, location}))
                  }
                }}
              />
            </div>
            <div className={"col-md-4 pl-0"}>
              <h3>{intl.messages["height"].toString().toUpperCase()}</h3>
              <input
                type='number'
                className='form-control mb-2'
                value={state?.height?.toString() || "0"}
                onChange= {(evt) => {
                  setState({...state, height: Number(evt.target.value)})
                }}
              />
            </div>
          </div>

        </div>
      </div>

      <div className={"row"}>
        <div className={"col-md-9 d-flex flex-column justify-content-between"} style={{marginBottom: -8}}>
          <div className={"row"}>
            <div className={"col-md-4"}>
              <EditableRow title={"battery"} value= {state?.battery?.toString()} />
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["link_qos"].toString()} value={state.backhaulQos} />
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["storage"].toString()}  value={state.storage}  />
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-4"}>
              <div className={"mb-2"}>
                <h3>{intl.messages["device_type_id"].toString().toUpperCase()}</h3>
                <p className={"mt-2 pt-3"}>{deviceTypeSelectValue.label}</p>
                {/*<Select*/}
                {/*  value={ deviceTypeSelectValue }*/}
                {/*  onChange={(g: any) => {*/}
                {/*    setState({...state, deviceTypeId: g.value});*/}
                {/*    setDeviceTypeSelectValue(g);*/}
                {/*  }}*/}
                {/*  isMulti={false}*/}
                {/*  options={state.altiorGatewayTypes?.map((g: AltiorGatewayType) => ({ value: g.attributes.deviceTypeId, label: g.attributes.name }))}*/}
                {/*  name="device type id"*/}
                {/*  className="basic-multi-select select-sizing"*/}
                {/*  classNamePrefix="select"*/}
                {/*/>*/}
              </div>
            </div>
            <div className={"col-md-4"}>
              <div className={"mb-2"}>
                <h3>{intl.messages["backhaul"].toString().toUpperCase()}</h3>
                <Select
                  value={ backHaulToGroupOption(_.first(state.backHauls.filter((bh) => bh.id === state?.backhaulId?.toString()))) }
                  onChange={(g: any) => {
                    setState({...state, backhaulId: g.value, backhaulConfig: {}});
                  }}
                  isMulti={false}
                  options={(state.backHauls?.map(backHaulToGroupOption))}
                  name="device type id"
                  className="basic-multi-select select-sizing"
                  classNamePrefix="select"
                />
              </div>
            </div>
            <div className={"col-md-4"} onClick={() => showConfigurationEditor({
              configurationSpecs: backhaulWithId(state.backhaulId, state.backHauls)?.attributes?.configurations ?? [],
              configurationValues: configToConfigurationValues(state.backhaulConfig),
              onClose(): void {
                console.log("on close");
              },
              onSave(c: ConfigurationValue[]): void {
                setState({...state, backhaulConfig: configurationValuesToConfig(c)})
              },
              title: `Backhaul config ${state.backhaulId}`,
              intl: intl.messages as any
            })}>
              <EditableRow title={intl.messages["config"].toString()} value={JSON.stringify(state.backhaulConfig)} onEdit={(s) => setState({...state, backhaulConfig: s})} />
            </div>
          </div>

          <div className={"row"}>

            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["status"].toString()}  value={state.status} onEdit={(s) => setState({...state, status: s})} />
            </div>
            <div className={"col-md-8"}>
              <EditableRow title={ intl.messages["location"].toString() } value={state.location} onEdit={(s) => setState({...state, location: s})}/>
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-12"}>
              <EditableRow title={intl.messages['description'].toString()} value={state.description} onEdit={(s) => setState({...state, description: s})}/>
            </div>
          </div>

          <div className={"row mb-4"}>
            <div className={"col-md-12"}>
              <h3>{intl.messages["send_command"].toString().toUpperCase()}</h3>
              <CommandInvocationWidget
                commands={commands}
                intl={intl.messages as any}
                executor={execGatewayCommand(gateway.id ?? "")}
              />
            </div>
          </div>

        </div>
        <div className={"col-md-3"}>
          <div className={"row"}>
            <div className={"col-md-12 d-flex flex-row"}>
              <h3 className={"flex-grow-1"} style={{minWidth:0}}>{intl.messages["show radio coverage"].toString().toUpperCase()}</h3>
              <button className={'icon-button mb-2'} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setState({...state, radioCoverageVisible: !state.radioCoverageVisible})
              }}>
                {state.radioCoverageVisible && <EyeIcon/>}
                {!state.radioCoverageVisible && <EyeOffIcon/>}
              </button>
            </div>
          </div>
          <div className={"mb-3"}>
            <Leafleet
              key={JSON.stringify(state.coordinates)}
              height={400}
              map_id={"gateways-edit"}
              editableItem={{...state.coordinates!, properties: {...state!.coordinates!.properties, markerColor: "#666666"}}}
              fetchClusters={fetchMeterGatewayCluster(gateway.id!)}
              staticMapItems={radioCoverageCircle}
              fallbackMapCenter={fallbackMapCenter}
              onEdit={ async (dot) => {
                if(dot) {
                  setState({...state, coordinates: dot as GeoJSONPoint, location: await gpsFromGeoJsonPoint(dot as GeoJSONPoint)});
                }
              } }
            />
          </div>
        </div>
      </div>

      <Divider className={"mt-3 mb-4"}/>
      <GatewayConfigPanel
        config={state.config}
        hardwareTypes={state.hardwareTypes}
        applicationTypes={state.applicationTypes}
        onConfigurationChange={ (gc) => setState({...state, config: gc}) }
      />

      <div className={"row mt-2"}>
        <div className={"col-md-12 ellipsis mt-1"}>
          <div className={"d-flex justify-content-between"}>
            <div className={"d-flex flex-row"}>
              <button className={"p3 outline-button mr-3"}  onClick={() => {
                showGatewayConfigImportDialog((v) => setState({...state, config: JSON.parse(v)}), intl.messages as any)
              }}>
                {intl.messages['import']}
              </button>
              <button className={"p3 outline-button"}  onClick={() => {
                navigator?.clipboard?.writeText(JSON.stringify(state.config));
                showAlert(intl.messages["copied-to-clipboard"].toString(), "", {
                  secondaryButtonText: intl.messages["download"].toString(),
                  secondaryButtonCallback: () => {
                    downloadJsonFile(state.config);
                  }
                })

              }}>
                {intl.messages['export']}
              </button>
            </div>
            <p className={"p-2 mr-5"}>{ state.validationErrors.join(", ") }</p>
            <div className={"d-flex flex-row"}>
              <Link to={ "/gateways" } style={{ textDecoration: 'none', color: '#fff' }}>
                <div className={"p3 outline-button mr-3"}>
                  {intl.messages['close']}
                </div>
              </Link>
              <button className={"p3 primary-button"}  onClick={() => { createEditGateway(state, setState, history, intl.messages as any) }}>
                {intl.messages['save']}
              </button>
            </div>


          </div>
        </div>
      </div>

    </div>
  );
}
