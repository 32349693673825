import * as h from "history";
import {fetchStorageConfiguration, updateStorageConfiguration} from "../../repsitory/storage_configuration_repository";
import {ConnectionButtonState} from "../../components/test_connection_button/test_connection_button";
import {postRaw, putRaw} from "../../repsitory/generic_repository";
import {showDBSetupDialog} from "../../components/db_setup_dialog";

export type AdminStoragePageState = {
  address: string;
  user: string
  password: string
  error: string,
  type: string,
  dbName: string
  loading: boolean
}

export async function getInitialState(): Promise<AdminStoragePageState> {
  const result = await fetchStorageConfiguration();
  if(typeof result === "string") return {error: result, address: "", user: "", password: "", type: "", dbName: "", loading: false};

  return {
    error: "",
    loading: false,
    ...result
  }
}

export async function testConnection(params: {"address": string, "db_name": string, "password": string, "user": string}, intl: Record<string, string>): Promise<ConnectionButtonState> {


  switch (await testConnectionRaw(params, intl)) {
    case "ok": return "success"
    case "err": return "error"
    case "setup_required":  return "success"

    default: return "error"
  }
}

export async function testConnectionRaw(params: {"address": string, "db_name": string, "password": string, "user": string}, intl: Record<string, string>): Promise<string> {
  const response = await postRaw(params, '/api/v1/test-connection');
  if(typeof response == "string") {
    return "error";
  }
  return await response.text();
}

export async function updateConfiguration(state: AdminStoragePageState, history: h.History, intl: Record<string, string>): Promise<AdminStoragePageState> {
  const result = await putRaw({
    "jsonapi": {
      "version": "1.0"
    },
    "data": {
      "type": "storageConfiguration",
      "attributes": {
        ...state
      }
    }
  },"/api/v1/storage-configuration");

  if(result.status !== 204 && result.status !== 200)  return {...state, error: intl["Configuration not valid"], loading: false};

  const resp = await testConnectionRaw({"address": state.address, "db_name": state.dbName, "password": state.password, "user": state.user}, intl)

  if(resp === "setup_required") {
    (await showDBSetupDialog(async () => {
      return await postRaw("", '/api/v1/setup-db');
    }, intl));
  }

  history.goBack();

  return {...state, ...result, loading: false}
}
