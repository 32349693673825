import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from "react-redux";
import {AppState} from "../../store/store";
import {InfoRow} from "../../components/InfoRow";
import {Divider} from "../../components/divider";
import {Link} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {TrashIcon} from "../../components/icons";
import {fetchSinglePageCollection} from "../../repsitory/generic_repository";
import {PaginatedListComponent} from "../../components/paginated_list/paginated_list_component";
import {Job} from "../../models/job";
import {deleteJob} from "../../repsitory/job_repository";
import {ErrorComponent} from "../../components/error_component";
import {getLastValue} from "../meters_page/meters_page_vm";
import {Log} from "../../models/log";
import {AltiorMeterType} from "../../models/altior_meter_type";
import {AltiorGatewayType} from "../../models/altior_gateway_type";
import {fetchAltiorGatewayTypes} from "../gateways_page/edit_gateway_page_vm";
import * as vm from "../meters_page/edit_meter_page_vm";
import _ from "lodash";
import {fetchMeterTypes} from "../meters_page/edit_meter_page_vm";
import {IndexTable} from "../../components/index_table";


export function AdminPage() {
  const intl = useIntl();
  const [initialPage, setInitialPage] = useState(0);
  const [error, setError] = useState("");

  const logs: Log[] = useSelector((s: AppState) => s.logs.items);



  return (
    <div>
      <div className={"row"}>
        <div className={"col-md-6 p-2 d-flex flex-column"}>
          <div className={"log-panel-unlimited m-2 flex-grow-1"}>
            <h2 className={"mb-5"}>Meters</h2>
            <InfoRow title={ intl.messages["total_meters"].toString() } value={ getLastValue(logs, "meter_count") }/>
            <InfoRow title={ intl.messages["installed"].toString() } value={ getLastValue(logs, "meter_installed") }/>
            <InfoRow title={ intl.messages["managed"].toString() } value={ getLastValue(logs, "meter_managed") }/>
            <InfoRow title={ intl.messages["meters-in-error"].toString() } value={ getLastValue(logs, "meter_in_error") }/>
            {/*<InfoRow title={intl.messages["groups"].toString().toUpperCase()} value={meterGroups?.groups?.length?.toString() || "--"}/>*/}
          </div>
          <div className={"log-panel-unlimited m-2 pb-1"}>
            <h2 className={"mb-5"}>Owner</h2>
            <div className={"row mb-3"}>
              <div className={"col-md-12 d-flex flex-row"}>
                <h3 className={"flex-grow-1"}>{ intl.messages["owner-config"].toString().toUpperCase() }</h3>
                <Link className={"outline-button"} to={"/admin/owner-config"}>
                  {intl.messages["edit"].toString()}
                </Link>
              </div>

            </div>
          </div>
        </div>
        <div className={"col-md-6 p-2"}>
          <div className={"log-panel-unlimited m-2"}>
            <h2 className={"mb-5"}>System</h2>

            <div className={"row mb-3"}>
              <div className={"col-md-12 d-flex flex-row"}>
                <h3 className={"flex-grow-1"}>{ intl.messages["servers"].toString().toUpperCase() }</h3>
                <Link className={"outline-button"} to={"/admin/ftp"}>
                  {intl.messages["edit"].toString()}
                </Link>
              </div>

            </div>
            <div className={"row mb-3"}>
              <div className={"col-md-12 d-flex flex-row"}>
                <h3 className={"flex-grow-1"}>{ intl.messages["storage"].toString().toUpperCase() }</h3>
                <Link className={"outline-button"} to={"/admin/storage"}>
                  {intl.messages["edit"].toString()}
                </Link>
              </div>
            </div>
          </div>
          <div className={"log-panel-unlimited m-2"}>
            <h2 className={"mb-5"}>Network</h2>
            <InfoRow title={ intl.messages["gateway_alarmed"].toString() } value={ getLastValue(logs, "gateway_alarmed") }/>
            <InfoRow title={ intl.messages["gateway_offline"].toString() } value={ getLastValue(logs, "gateway_offline") }/>
            <InfoRow title={ intl.messages["gateway_installed"].toString() } value={ getLastValue(logs, "gateway_installed") }/>
          </div>
          <div className={"log-panel-unlimited m-2"}>
            <h2 className={"mb-5"}>Digital Twin</h2>
            <div className={"d-flex flex-row justify-content-between"}>
              <h3 className={"flex-grow-1"}>{ intl.messages["digital_twin"].toString().toUpperCase() }</h3>
              <Link className={"outline-button"} to={"/admin/digital-twin"}>
                {intl.messages["show"].toString()}
              </Link>
            </div>

          </div>
        </div>

      </div>
      <Divider/>
      <h2>Planned Operations</h2>
      <PaginatedListComponent
        headers={
          ["type", "state", "description", "actions"]
        }
        initialPage={initialPage}
        rowBuilder={
          (job: Job) => [
            <p>{job.type}</p>,
            <p>{job.state}</p>,
            <p>{job.description ?? "--"}</p>,
            <button className={"icon-button"} onClick={(e) => {
              e.stopPropagation();
              confirmAlert({
                title: intl.messages["confirm"].toString(),
                message: intl.messages["this_action_cant_be_undone"].toString(),
                buttons: [
                  {
                    label: 'Yes',
                    onClick: async () => {
                      const result = await deleteJob(job);
                      if(result.status > 300) setError(intl.messages["cannot-destroy-job"].toString)
                      setInitialPage(0);
                      setInitialPage(1);
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {}
                  }
                ]
              });

            }}>
              <TrashIcon/>
            </button>
          ]
        }
        fetchCollection={
          fetchSinglePageCollection<Job>("/api/v1/job")
        }
        perPage={8}
        onTap={ (g) => {
          // history.push(`/gateways/${g.id}/edit`, g);
        } }
      />
      {error !== "" && <ErrorComponent message={error}/>}
    </div>
  );
}

