import {GatewayApplicationConfig, GatewayHardwareConfig} from "../../../models/gateway_config";
import {HardwareType} from "../../../models/hardware_type";
import _ from "lodash";
import {ApplicationType} from "../../../models/application_type";

export function hardwareTypeFromGatewayHardwareConfig(gh: GatewayHardwareConfig, types: HardwareType[]): HardwareType | undefined {
  return _.first(types.filter((i) => i.attributes.version === gh.version && i.attributes.port === gh.port && i.attributes.name === gh.name))
}

export function applicationTypeFromGatewayApplicationConfig(gh: GatewayApplicationConfig, types: ApplicationType[]): ApplicationType | undefined {
  return _.first(types.filter((i) => i.attributes.version === gh.version && i.attributes.name === gh.name))
}

export function gatewayHardwareConfigFromHardwareType(ht: HardwareType): GatewayHardwareConfig {
  return {
    configurations: {},
    name: ht.attributes.name,
    port: ht.attributes.port,
    version: ht.attributes.version,
  }
}

export function gatewayApplicationConfigFromApplicationType(ht: ApplicationType): GatewayApplicationConfig {
  return {
    configurations: {},
    name: ht.attributes.name,
    version: ht.attributes.version,
  }
}