import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Group} from "../../models/group";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import {useHistory} from "react-router-dom";
import {ErrorComponent} from '../../components/error_component';
import * as vm from './edit_meter_page_vm';
import {createEditMeter, mkMeterState} from './edit_meter_page_vm';
import {EditableRow} from "../../components/InfoRow";
import {gpsFromGeoJsonPoint} from "../../service/address_from_coordinates";
import {emptyMeter, Meter} from "../../models/meter";
import moment from 'moment';
import {AltiorMeterType} from "../../models/altior_meter_type";
import _ from "lodash";
import {Leafleet} from "../../components/leafleet_map/leafleet_map";
import {GeoJSONPoint} from "../../models/geo_json";
import {fallbackMapCenter} from "../../index";
import {remoteFetchData} from "../../repsitory/generic_repository";


export function CreateMeterPage() {

  const history = useHistory();
  const meter: Meter = (history?.location?.state as Meter) || emptyMeter();

  const [state, setState] = useState<vm.EditMeterPageState>(mkMeterState(meter));

  const [altiorMeterTypes, setAltiorMeterTypes] = useState<AltiorMeterType[]>([]);
  const [deviceTypeSelectValue , setDeviceTypeSelectValue ] = useState<{value: string, label: string}>({value: state.deviceTypeId ?? "", label: "loading"})

  const intl = useIntl();

  useEffect(() => {
    (async () => {
      vm.fetchMeterTypes().then((mt) => {
        if(typeof mt === "string") {
          setState({...state, error: mt});
        } else {
          setAltiorMeterTypes(mt);
          const fm: AltiorMeterType | undefined = _.first(mt);
          if(!fm) return;

          setDeviceTypeSelectValue({label: `${fm.name} ${fm.version}`, value: fm.deviceTypeId.toString()});
          setState({...state, deviceTypeId: fm.deviceTypeId});
        }
      })
    })()
  }, [0]);

  if(state.error !== "") return <ErrorComponent message={state.error}/>;
  if(state.loading) return <p>Loading</p>;

  return (
    <div>

      <div className={"row"}>
        <div className={"col-md-9 d-flex flex-column justify-content-between"}>
          {/*<div className={"row"}>*/}
          {/*  <div className={"col-md-4"}>*/}
          {/*    <EditableRow title={"model_type"} value= {state.modelType} />*/}
          {/*  </div>*/}
          {/*  <div className={"col-md-4"}>*/}
          {/*    <EditableRow title={intl.messages["fw_version"].toString()}  value={state.fwVersion} />*/}
          {/*  </div>*/}
          {/*  <div className={"col-md-3"}>*/}
          {/*    <EditableRow title={intl.messages["rf_config"].toString()}  value={state.rfConfig} />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className={"row"}>*/}
          {/*  <div className={"col-md-4"}>*/}
          {/*    <EditableRow title={"rf_interface"} value= {state.rfInterface}/>*/}
          {/*  </div>*/}
          {/*  <div className={"col-md-4"}>*/}
          {/*    <EditableRow title={intl.messages["current_key"].toString()}  value={state.currentKey} hidden />*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className={"row"}>
            <div className={"col-md-4"}>
              <EditableRow title={"serial"} value= {state.serial} onEdit={(s) => setState({...state, serial: s})}/>
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["contract"].toString()}  value={state.contract} onEdit={(s) => setState({...state, contract: s})} />
            </div>
            <div className={"col-md-4"}>
              <h3> {intl.messages["install_date"].toString().toUpperCase()} </h3>
              <input
                className="form-control"
                type="date"
                data-date-format="DD/MM/YYYY"
                defaultValue={state.installDate.toDateString()}
                value={moment(state.installDate).format("YYYY-MM-DD")}
                onChange={ (s) => setState({...state, installDate: new Date(s.target.value)}) }
              />
            </div>
          </div>

          <div className={"row"}>

            <div className={"col-md-4"}>
              <EditableRow title={intl.messages['ldn'].toString()} value={state.ldn}  onEdit={(s) => setState({...state, ldn: s})}/>
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages['manufacturer'].toString()} value={state.manufacturer}  onEdit={(s) => setState({...state, manufacturer: s})}/>
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["pdr"].toString()}  value={state.pdr} onEdit={(s) => setState({...state, pdr: s})} />
            </div>
          </div>


          <div className={"row"}>
            <div className={"col-md-4"}>
              <div className={"mb-2"}>
                <h3>{intl.messages["groups"].toString().toUpperCase()}</h3>
                <AsyncSelect
                  defaultValue={state.groups}
                  onChange={(g: any) => setState({...state, groups: g})}
                  isMulti
                  cacheOptions
                  defaultOptions
                  loadOptions={ (input: string) => {
                    return remoteFetchData<Group[]>(`/api/v1/group?name=${input}`).then((gr) => {
                      if(typeof gr !== "string") {
                        return gr.map((g) => ({ value: g.attributes.id, label: g.attributes.name }));
                      } else {
                        setState({...state, error: intl.messages["cannot_fetch_meter_groups"].toString()})
                        return [];
                      }
                    })
                  } }

                  name="groups"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>

            <div className={"col-md-8"}>
              <EditableRow title={ intl.messages["location"].toString() } value={state.location} onEdit={(s) => setState({...state, location: s})}/>
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-12"}>
              <div className={"mb-2"}>
                <h3>{intl.messages["device_type_id"].toString().toUpperCase()}</h3>
                {/*<Select*/}
                {/*  defaultValue={(state.deviceTypeId ?? _.first(altiorMeterTypes)?.deviceTypeId) as any}*/}
                {/*  onChange={(g: any) => setState({...state, deviceTypeId: g.value})}*/}
                {/*  isMulti={false}*/}
                {/*  options={altiorMeterTypes?.map((g) => ({ value: g.deviceTypeId, label: g.name }))}*/}
                {/*  name="device type id"*/}
                {/*  className="basic-multi-select"*/}
                {/*  classNamePrefix="select"*/}
                {/*/>*/}
                <Select
                  value={ deviceTypeSelectValue }
                  onChange={(g: any) => {
                    setState({...state, deviceTypeId: g.value});
                    setDeviceTypeSelectValue(g);
                  }}
                  isMulti={false}
                  options={altiorMeterTypes?.map((amt: AltiorMeterType) => ({ value: amt?.deviceTypeId?.toString(), label: `${amt.name} ${amt.version}` }))}
                  name="device type id"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>
          </div>


          <div className={"row mt-3"}>
            <div className={"col-md-12"}>
              <EditableRow title={intl.messages['description'].toString()} value={state.description} onEdit={(s) => setState({...state, description: s})}/>
            </div>

          </div>

        </div>
        <div className={"col-md-3"}>

          <div className={"row"}>

            <div className={"col-md-12"}>

              <div className={"row"}>
                <div className={"col-md-12 d-flex flex-row"}>
                  <div className={"flex-grow-1"}>
                    <h3>{ intl.messages["lat"].toString().toUpperCase()}</h3>
                    <input
                      type='number'
                      className='form-control mb-2 '
                      value={state?.coordinates?.geometry?.coordinates[1]?.toString() || ""}
                      onChange= {(evt) => {
                        if(state.coordinates) {
                          const coordinates = {
                            ...state.coordinates,
                            geometry: {
                              ...state.coordinates.geometry,
                              coordinates: [state.coordinates.geometry.coordinates[0], Number(evt.target.value)]
                            }
                          };
                          setState({...state, coordinates})
                          // gpsFromGeoJsonPoint(coordinates).then(location => setState({...state, coordinates, location}))
                        }
                      }}
                    />
                  </div>
                  <div className={"flex-grow-1"}>
                    <h3>{ intl.messages["lng"].toString().toUpperCase()}</h3>
                    <input
                      type='number'
                      className='form-control mb-2 flex-grow-1'
                      value={state?.coordinates?.geometry?.coordinates[0]?.toString() || ""}
                      onChange= {(evt) => {
                        if(state.coordinates) {
                          const coordinates = {
                            ...state.coordinates,
                            geometry: {
                              ...state.coordinates.geometry,
                              coordinates: [Number(evt.target.value), state.coordinates.geometry.coordinates[1]]
                            }
                          };
                          setState({...state, coordinates})
                          // gpsFromGeoJsonPoint(coordinates).then(location => setState({...state, coordinates, location}))
                        }
                      }}
                    />
                  </div>
                  <div className={"flex-grow-1"}>
                    <h3>{ intl.messages["height"].toString().toUpperCase()}</h3>
                    <input
                      type='number'
                      className='form-control mb-2 flex-grow-1'
                      value={state?.height?.toString() || "0"}
                      onChange= {(evt) => {
                        setState({...state, height: Number(evt.target.value)})
                      }}
                    />
                  </div>




                </div>
              </div>
            </div>
          </div>

          <div className={"mb-3"}>
            <Leafleet
              key={JSON.stringify(state.coordinates)}
            height={328}
            map_id={"meters-create"}
            editableItem={state.coordinates}

            fallbackMapCenter={fallbackMapCenter}
            onEdit={ async (dot) => {

              if(dot) {
                setState({...state, coordinates: dot as GeoJSONPoint, location: await gpsFromGeoJsonPoint(dot as GeoJSONPoint)});
              }

            } }
          /></div>
        </div>
      </div>

      <div className={"row"}>
        <div className={"col-md-12 ellipsis mt-1"}>
          <div className={"d-flex flex-row-reverse"}>
            <button className={"p3 primary-button"}  onClick={() => { createEditMeter(state, setState, history, intl.messages as any) }}>
              {intl.messages['save']}
            </button>
            <button  className={"p3 outline-button mr-3"} onClick={ () => history.goBack() } >
                {intl.messages['cancel']}
            </button>
            <p className={"p-2 mr-5"}>{ state.validationErrors.join(", ") }</p>
          </div>
        </div>
      </div>
    </div>
  );
}