import {confirmAlert} from "react-confirm-alert";
import React, {useState} from "react";
import {ClearIcon} from "./icons";
import {Divider} from "./divider";

export function showGatewayConfigImportDialog(onDone: (a: any) => void, intl: Record<string, string>) {

  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({onClose}) =>
    <GatewayConfigImportDialog
      onDone={(v) => {
        if(v !== null) { onDone(v); }
        onClose();
      }}
     intl = {intl}
    />
  });
}

function GatewayConfigImportDialog(props: {onDone: (a: any) => void, intl: Record<string, string>}) {
  const [value, setValue] = useState("");

  return <div
    style={{minHeight: 400, backgroundColor: "white"}}
    className={"configuration-editor p-3 d-flex flex-column justify-content-between pt-4"}
    onDrop={(e) => {
      e.preventDefault();
      const files = e.dataTransfer.items;

      if(files.length === 0 || files[0].kind !== "file") return;

      const file = files[0].getAsFile();

      const fileReader = new FileReader();
      fileReader.readAsText(file as Blob);

      fileReader.onload = function() {
        console.log(fileReader.result);
        setValue(fileReader?.result?.toString() ?? "");
      };
    }}
  >
    <div className={"row d-flex flex-row justify-content-between"}>
      <h3 className={"dashboard-editor-title"}>{props.intl["import_gateway_config"].toUpperCase()}</h3>
      <button className={"icon-button dashboard-editor-icon"} onClick={() => props.onDone(null)}><ClearIcon/></button>
    </div>
    <Divider className={"mt-1 mb-3"}/>
    <div className={"row"}>
      <div className="col-md-12">
        { value === "" && <h3>{props.intl["type_or_drag_file"].toUpperCase()}</h3>}
        <textarea style={{minHeight: 300, backgroundColor: "white"}} className="form-control" id="exampleFormControlTextarea1" value={value} rows={3} onChange={(e) => setValue(e.target.value)}/>

      </div>
    </div>
    <div className={"row d-flex justify-content-end mr-1 mt-3"}>
      <button
        onClick={() => props.onDone(null)}
        className={"p3 outline-button mr-3"}
      >{props.intl["close"]}</button>
      <button className={"p3 primary-button"} style={{marginRight: -5}}  onClick={() => {
        props.onDone(value);
      }}>
        {props.intl['import']}
      </button>
    </div>
  </div>

}