import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FeatureGroup, Map, ScaleControl, TileLayer,} from 'react-leaflet';
import {debounceTime} from 'rxjs/operators';
import './leafleet_map.css'

import {GeoJSONFeature, GeoJSONPoint, MapItem, ViewPort} from "../../models/geo_json";
import {BehaviorSubject} from "@reactivex/rxjs/dist/package";
import {RenderEditor} from "./leafleet_editor";
import {RenderMapItem} from "./leaflet_geojson";
import {fallbackMapCenter} from "../../index";
import {getOwnerConfiguration} from "../../pages/admin_panel/admin_owner_config_page_vm";


type Props = {
  height?: number,
  map_id?: string
  staticMapItems?: MapItem[]
  fallbackMapCenter: {lat: number, lng: number}
  debounceTimeMs?: number
  editableItem?: GeoJSONFeature
  onEdit?: (g: GeoJSONFeature | null) => void
  fetchClusters?: (ne_lat: number, ne_lng: number, sw_lat: number, sw_lng: number, shouldFetchClusters: boolean) => Promise<MapItem[] | string>
  mapCenter?: GeoJSONPoint
}

export function Leafleet(props: Props) {

  const ref = useRef<any>("map");

  const subject = useMemo(() => new BehaviorSubject<ViewPort | null>(null), [0])
  const clusters = useMemo(() => new BehaviorSubject<MapItem[]>([]), [0])

  const [defaultCenter, setDefaultCenter] = useState({lat: fallbackMapCenter.lat, lng: fallbackMapCenter.lng});

  const [editableItem, setEditableItem] = useState(props.editableItem ?? null);

  // const [cluster, setCluster] = useState<MapItem[]>([]);

  const prevZoom = window.localStorage.getItem(props.map_id ?? 'map');

  let zoomValue = 12;

  if(prevZoom) zoomValue = Number(prevZoom);

  const center: {lat: number, lng: number} = (() => {

    if(props.mapCenter) {

      return {lat: props.mapCenter.geometry.coordinates[1], lng: props.mapCenter.geometry.coordinates[0]}
    }

    if(editableItem) {
      switch (editableItem.geometry.type) {
        case "Point":
          return {lat: editableItem.geometry.coordinates[1], lng: editableItem.geometry.coordinates[0]}
        case "Polygon":
          return baricenter(editableItem.geometry.coordinates[0])
      }
    }
    const prevCenter = window.localStorage.getItem(props.map_id ? `${props.map_id}-center` : 'map-center');
    if(prevCenter) {
      try{
        return JSON.parse(prevCenter);
      } catch (e) {
        return props.fallbackMapCenter;
      }
    }

    return defaultCenter
  }) ()

  useEffect(() => {
    // @ts-ignore
    const s = subject.asObservable().pipe(debounceTime(props.debounceTimeMs ?? 150)).subscribe(async (v) => {
      if(v && props.fetchClusters) {
        const c = await props.fetchClusters(v.northEast.lat, v.northEast.lng, v.southWest.lat, v.southWest.lng, zoomValue === 18);
        if(typeof c !== "string") {

            // setCluster(fetched_clusters);
          clusters.next(c);

        }
      }
    })

    setTimeout(() => {
      const bounds = ref?.current?.leafletElement?.getBounds();
      if(bounds)
      subject.next({
        northEast: bounds._northEast,
        southWest: bounds._southWest,
        zoom: zoomValue
      })
    },100)

    getOwnerConfiguration().then((c) => {
      if(typeof c === "string") return;
      setDefaultCenter({lat: c.lat, lng: c.lng})
    });


    return () => s.unsubscribe();
  },[props.mapCenter])

  // @ts-ignore
  return (
    <Map
      key={JSON.stringify(editableItem)}
      style={{height: props.height ?? "100%", width: "100%"}}
      center={ center as {lat: number, lng: number} }
      zoom={zoomValue}
      onViewportChange={(vp) => {
        const bounds = ref.current.leafletElement.getBounds();
        vp.zoom && (zoomValue = vp.zoom)
        window.localStorage.setItem(props.map_id ?? 'map', vp?.zoom?.toString() ?? "12")
        //  @ts-ignore
        if(vp.center?.length ?? 0 > 1) window.localStorage.setItem(props.map_id ? `${props.map_id}-center` : 'map-center', JSON.stringify({lat: vp.center[0], lng: vp.center[1]}))
        subject.next({
          northEast: bounds._northEast,
          southWest: bounds._southWest,
          zoom: vp.zoom!
        })
      }}
      ref={ref}
    >

      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
      />
      <FeatureGroup>
        { ([...(props.staticMapItems ?? []), ]).map(RenderMapItem(zoomValue)) }
      </FeatureGroup>
      <ClusterRender
        subject={clusters}
        zoom={zoomValue}
      />
      <FeatureGroup key={"editor"}>
        {editableItem && RenderMapItem(zoomValue)({feature: editableItem, popUp: (_) => null})}
        {props.onEdit && RenderEditor(editableItem, props.onEdit, setEditableItem)}
      </FeatureGroup>


      <ScaleControl/>

    </Map>
  );
}

const baricenter = (coordinates: number[][]) => {

  if(coordinates.length === 1) return coordinates[0];

  const lat = coordinates.map(c => c[1]).reduce((a, b) => a + b) / coordinates.length;
  const lng = coordinates.map(c => c[0]).reduce((a, b) => a + b) / coordinates.length;
  return {lat, lng};
};

export function ClusterRender(props: {subject: BehaviorSubject<MapItem[]>, zoom: number}, ) {
  const [cl, setCl] = useState<MapItem[]>([]);

  useEffect(() => {
    const s = props.subject.subscribe((v) => {
      setCl(v);
    })
    return () => s.unsubscribe();
  })

  return <FeatureGroup key={JSON.stringify(cl)}>
    { cl.map(RenderMapItem(props.zoom)) }
  </FeatureGroup>
}

