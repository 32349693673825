import React from "react";

type Props = {
  className?: string
}

export function Divider(props: Props) {

  const style = props.className
    ? {width:"100%", backgroundColor:"gray", height:"1px" }
    : {width:"100%", backgroundColor:"gray", height:"1px", marginTop: "2rem", marginBottom: "1rem"}

  return <div style={ style } className={ props.className ?? "" }/>
}