import React, {useState} from 'react';
import {PaginatedListComponent} from "../../components/paginated_list/paginated_list_component";
import {User} from "../../models/user";
import {history} from "../../index";
import {Link} from "react-router-dom";
import {useIntl} from 'react-intl';
import {confirmAlert} from "react-confirm-alert";
import {MoreVerticalIcon, TrashIcon} from "../../components/icons";
import {deleteUser, fetchPaginatedUsers} from "../../repsitory/user_repository";
import {ErrorComponent} from "../../components/error_component";

export function UsersPage() {
    const intl = useIntl();
    const [error, setError] = useState("");
    const [initialPage, setInitialPage] = useState(0);
    return (
        <div>
            <div className={"row"}>
                <div className={"col-md-12 ellipsis"}>

                    <PaginatedListComponent headers={
                        ["username", "email", "actions"]
                    } rowBuilder={
                        (u: User) => [
                            <p>{u.username}</p>,
                            <p>{u.email || "--"}</p>,
                            <div style={{marginTop: "-6px"}} className={"d-flex"}>
                                <button className={"icon-button"} onClick={(e) => {
                                    e.stopPropagation();
                                    confirmAlert({
                                        title: intl.messages["confirm"].toString(),
                                        message: intl.messages["this_action_cant_be_undone"].toString(),
                                        buttons: [
                                            {
                                                label: 'Yes',
                                                onClick: async () => {
                                                    setError(await deleteUser(u.id!));
                                                    setInitialPage(0);
                                                    setInitialPage(1);
                                                }
                                            },
                                            {
                                                label: 'No',
                                                onClick: () => {}
                                            }
                                        ]
                                    });

                                }}>
                                    <TrashIcon/>
                                </button>
                                <button className={"icon-button"}>
                                    <MoreVerticalIcon/>
                                </button>
                            </div>,
                        ]
                    }
                    fetchCollection={
                        fetchPaginatedUsers("/api/v1/user")
                    }
                    perPage={8}
                    onTap={ (u: User) => {
                        history.push(`/users/${u.username}/edit`, u)
                    }}
                    initialPage={initialPage}
                    />
                </div>
            </div>
            {error !== "" && <ErrorComponent message={error}/>}
            <div className={"d-flex flex-row-reverse"}>
                <Link to={ "/users/new" } style={{ textDecoration: 'none', color: '#fff' }}>
                    <div className={"p3 primary-button"}>
                        {intl.messages['add_user']}
                    </div>
                </Link>
            </div>
        </div>
    );
}