import React, {ReactNode, useState} from 'react';
import {EyeIcon, EyeOffIcon} from './icons';

type Props = {
  hint?: string
  title?: string
  onChange: (s: string) => void
  value?: string
  hidden?: boolean
  className?: string
  searchButton?: ReactNode
};

export function InputText(props: Props) {

  const [hiddenValue, setHidden] = useState(props.hidden || false);

  return (
    <div className={`${ props.className ?? "mb-4" }`}>
      {props.title && <div className={"row"}>
          <div className={"col-md-12"}>
              <h3>{ props.title }</h3>
          </div>
      </div>}
      <div className={"row"}>
        <div className={"col-md-12"}>
          <div className={"d-flex justify-content-between"}>
            <input
              type={ hiddenValue ? "password" :"text"}
              className="form-control"
              aria-label="Text input with radio button"
              onChange={event => props.onChange(event.target.value)}
              placeholder={props.hint ?? ""}
              value={props.value ?? ""}
            />
            {props.hidden && <button className={"icon-button"} onClick={(e) => {
              e.stopPropagation();
              setHidden(!hiddenValue);
            }}>
              {hiddenValue && <EyeIcon/>}
              {!hiddenValue && <EyeOffIcon/>}
            </button>}
            {props.searchButton && props.searchButton}
          </div>
        </div>
      </div>
    </div>
  );
}
