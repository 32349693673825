import {Filter, FilterCollection, FilterName} from "../../../models/filter";


export type DataSource = "gateway_readings" | "meter_readings" | null;
export const dataSources:  DataSource[] = [
  "meter_readings",
  "gateway_readings"
]

export type ColumnName = "battery"| "storage"| "volume"| "rssi"
export const columnNamesPerDatasource: Record<string, ColumnName[]> = {
  "meter_readings": ["battery", "volume", "rssi"],
  "gateway_readings": ["battery", "storage"]
}

export type ReducerOperator = "sum" | "count" | "average";
export const reducerOperators: ReducerOperator[] = ["sum", "count", "average"]

export type ReduceChart = {
  type: "REDUCE_CHART"
  x_column_name: string
  y_column_name: ColumnName
  table: EventFilter
}

export type Reduce = {
  type: "REDUCE",
  column_name: ColumnName,
  operator: ReducerOperator,
  table: EventFilter
}

export type EventFilter = {
  type: "FILTER",
  source: DataSource,
  filter: BooleanFilter
}

export type BooleanFilter = And | Or | Condition | boolean

export type And = {
  type: "AND"
  left: BooleanFilter
  right: BooleanFilter
}

export type Or = {
  type: "OR"
  left: Condition
  right: BooleanFilter
}

export function or(left: Condition, right?: BooleanFilter): BooleanFilter {
  return {
    type: "OR",
    left: left,
    right: right ?? false
  }
}

export function and(left: BooleanFilter, right?: BooleanFilter): BooleanFilter {
  return {
    type: "AND",
    left: left,
    right: right ?? true
  }
}

export type Condition = {
  type: "CONDITION"
  name: string
  value: string
}

export function reducedEventFilterAst(fc: FilterCollection): EventFilter | Reduce | ReduceChart {
  if(!fc.reducer) return {filter: filtersAstFromFilterMatrix(fc.filters), source: fc.dataSource, type: "FILTER"}

  switch (fc.reducer.type) {
    case "FILTER_COLLECTION_REDUCER":
      return {
        type: "REDUCE",
        column_name: fc.reducer.column_name as ColumnName,
        table: eventFilterAst(fc),
        operator: fc.reducer.operator,
      }
    case "FILTER_COLLECTION_CHART_REDUCER":
      return {
        type: "REDUCE_CHART",
        x_column_name: fc.reducer.x_column_name,
        y_column_name: fc.reducer.y_column_name,
        table: eventFilterAst(fc),
      }
  }
}

export function eventFilterAst(fc: FilterCollection): EventFilter {
  return {filter: filtersAstFromFilterMatrix(fc.filters), source: fc.dataSource, type: "FILTER"}
}

export function filtersAstFromFilterMatrix(fs: Filter[][]) {
  return andCombine(fs.map(orCombine))
}

function orCombine(fs: Filter[]): BooleanFilter {
  if(fs.length === 0) return false;

  const [i, ...is] = fs;
  const i_bf: Condition = {name: i.name, type: "CONDITION", value: i.value};

  if(fs.length === 1) return i_bf;

  return or(
    {name: i.name, type: "CONDITION", value: i.value},
    orCombine(is)
  );
}

function andCombine(fs: BooleanFilter[]): BooleanFilter {
  if(fs.length === 0) return true;
  const [i, ...is] = fs;

  if(fs.length === 1) return i;

  return and(
    i,
    andCombine(is)
  );
}


export const filterPrototypes: Record<string, Filter[]> = {meter_readings: [
  {name: "gateway_id", value: ""},
  {name: "ldn", value: ""},
  {name: "pdr", value: ""},
  {name: "group", value: ""},
  {name: "with_errors", value: "true"},
  {name: "date_greater", value: ""},
  {name: "date_smaller", value: ""},
],
  gateway_readings: [
    {name: "with_errors", value: "true"},
    {name: "date_greater", value: ""},
    {name: "date_smaller", value: ""},
    {name: "gateway_id", value: ""},
    {name: "max_network_greater", value: ""},
    {name: "max_network_smaller", value: ""},
    {name: "min_network_greater", value: ""},
    {name: "min_network_smaller", value: ""},
  ],
  no_data_source: []
}

export function eventFilterToString(ef: EventFilter): string {
  if(ef.source === null) return "";
  return `${ef.source}: ${toString(ef.filter)}`
}

export function toString(bf: BooleanFilter): string {
  if(typeof bf === "boolean") return bf ? "all" : "none";
  switch (bf.type) {
    case "AND": return `( ${toString(bf.left)} ) AND ( ${toString(bf.right)} )`
    case "OR": return `( ${toString(bf.left)} ) OR ( ${toString(bf.right)} )`
    case "CONDITION": return `${bf.name}: ${bf.value}`;
  }
}

export function formatValueName(filterName: FilterName, intl: Record<string, string>) {
  switch (filterName) {
    case "gateway_id":
      return intl["value_gateway_id"]
    case "ldn":
      return intl["value_ldn"]
    case "pdr":
      return intl["value_pdr"]
    case "group":
      return intl["value_group"]
    case "with_errors":
      return intl["value_with_errors"]
    case "date_greater":
      return intl["value_date_greater"]
    case "date_smaller":
      return intl["value_date_smaller"]
    default: return intl[`value_${filterName}`]

  }
}

export function formatDataSource(dataSource: DataSource, intl: Record<string, string>) {
  switch (dataSource) {
    case "gateway_readings":
      return intl["gateway_readings"]
    case "meter_readings":
      return intl["meter_readings"]
  }
}
