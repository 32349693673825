import {UserPermission} from "../models/user_permission";
import {httpDelete, putStatusCode, remoteFetchData} from "./generic_repository";

export async function fetchAllUserPermissions(): Promise<UserPermission[]> {
    const response = await remoteFetchData<UserPermission[]>("/api/v1/user/permission");
    if(typeof response === "string") return []
    return response;
}

export async function fetchUserGroupPermissions(userGroupId: string): Promise<UserPermission[]> {
    const response = await remoteFetchData<UserPermission[]>(`/api/v1/user/group/${userGroupId}/permission`);
    if(typeof response === "string") return []
    return response;
}

export async function addUserPermissionToGroup(userPermissionId: string, groupId: string): Promise<boolean>  {
    console.log(`Adding ${userPermissionId} to group ${groupId}`);
    const status = await putStatusCode("", `/api/v1/user/group/${groupId}/permission/${userPermissionId}`);
    return status === 204;
}

export async function removeUserPermissionFromGroup(userPermissionId: string, groupId: string): Promise<boolean>  {
    console.log(`Removing ${userPermissionId} to group ${groupId}`);
    const status = (await httpDelete(`/api/v1/user/group/${groupId}/permission/${userPermissionId}`)).status;
    return status === 204;
}