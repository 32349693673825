
import {put, remoteFetchAttributes} from "./generic_repository";
import {OwnerConfiguration} from "../models/owner_configuration";

export function fetchOwnerConfiguration(): Promise<OwnerConfiguration | string> {
  return remoteFetchAttributes<OwnerConfiguration>("/api/v1/owner-configuration")
}

export async function updateOwnerConfiguration(ownerConfiguration: OwnerConfiguration): Promise<OwnerConfiguration | string> {
  const result = await put({
    "jsonapi": {
      "version": "1.0"
    },
    "data": {
      "type": "ownerConfig",
      "attributes": {
        ...ownerConfiguration
      }
    }
  },"/api/v1/owner-configuration");

  if(typeof result === "string") return result;

  return result.data.attributes;
}