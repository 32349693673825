import React, {useEffect, useState} from "react"
import {green, red} from "../../utils/colors";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
// @ts-ignore
import Loader from 'react-loader-spinner'

type Props = {
  intl: Record<string, string>
  key: any
  onTap: () => Promise<ConnectionButtonState>
}

export type ConnectionButtonState = "idle" | "loading" | "success" | "error"

export function TestConnectionButton(props: Props) {

  const [state, setState] = useState<ConnectionButtonState>("idle")

  useEffect(() => {
    setState("idle");
  }, [props.key])

  switch (state) {
    case "idle":
      return <button
        className={"outline-button"}
          onClick={async () => {
            setState("loading");
            setState(await props.onTap())
            // setTimeout(() => setState("success"), 1000);
          }}
      >
        {props.intl["test-connection"]}
      </button>
    case "loading":
      return <Loader type="ThreeDots" color="#3C4B64" height={30} width={40} />
    case "success":
      return <div className={"outline-button"} style={{color: green}}>{props.intl["success"]}</div>
    case "error":
      return <div className={"outline-button"} style={{color: red}}>{props.intl["error"]}</div>

  }

}