import {FilterCollection} from "../../../models/filter";
import React, {useState} from "react";
import {EditableRow} from "../../../components/InfoRow";
import {confirmAlert} from "react-confirm-alert";

type Props = {
  onCreate: (f: FilterCollection) => void
  onUpdate: (f: FilterCollection) => void
  filterCollection: FilterCollection
  intl: Record<string, string>
}


export function showFilterEditSaveDialog(props: Props) {

  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({onClose}) =>
      <FilterEditSaveDialog
        onCreate={ (i) => { props.onCreate(i); onClose(); } }
        onUpdate={ (i) => { props.onUpdate(i); onClose(); } }
        filterCollection={props.filterCollection}
        intl={props.intl}
      />
  });
}

function FilterEditSaveDialog(props: Props) {

  const [name, setName] = useState(props.filterCollection.name);

  const updateButtonVisible = props.filterCollection.id !== -1;

  return <div style={{backgroundColor: "white"}} className={"configuration-editor p-3 d-flex flex-column justify-content-between pt-4"}>
    <div className={"row"}>
      <div className={"col-md-12"}>
        <EditableRow title={props.intl["name"]} value={name}  onEdit={(s) => setName(s)}/>
      </div>
    </div>
     <div className={"row"}>
      <div className={"col-md-12 d-flex justify-content-between"}>

        { updateButtonVisible &&<button
            className={"primary-button"}
            onClick={() => props.onUpdate({...props.filterCollection, name})}
        >
          {props.intl["update"]}
        </button> }

          <button
              className={"primary-button"}
              onClick={() => props.onCreate({...props.filterCollection, name})}
          >
            {props.intl["create_new_with_name"]}
          </button>

      </div>
    </div>


  </div>
}