import {confirmAlert} from "react-confirm-alert";
import React, {useState} from "react";
import {InputText} from "../../../components/input_text";
import {FirmwareCreationModalState, uploadFirmware} from "./firmware_page_vm";

type Props = {
  intl: Record<string, string>
  onClose: () => void
}



function FirmwareCreationModal(props: Props) {

  const [state, setState] = useState<FirmwareCreationModalState>({
    error: "", firmware: null, loading: false, name: ""
  });

  return <div className={"configuration-editor"}>
    <div className={"row"}>
      <div className={"col-md-12"}>
        <h3 className={"mt-3"}>{props.intl["upload-firmware"].toString().toUpperCase()}</h3>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12"}>
        <h3 className={"mt-3"}>{props.intl["name"].toString().toUpperCase()}</h3>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12 pt-1"}>
        <InputText value={state.name} onChange={(s) => setState({...state, name: s})}/>
      </div>
    </div>
    <h3 className={"mt-3"}>{props.intl["firmware"].toString().toUpperCase()}</h3>
    <div className={"row"}>
      <div className={"col-md-12 d-flex flex-row"}>
        <div className={"flex-grow-1 pt-1"}>
          <p className={"outline-p"}>{state.firmware?.name || ""}</p>
        </div>
        <div className={"ml-3 pt-1"}>
          <div className="file">
            <label className="file-label">
              <input className="file-input" type="file" name="resume" onChange={(e) => setState({...state, firmware: (e?.target?.files?.item(0) || null) })}/>
              <span className="file-cta">
                  <span className="file-label">
                    Choose a file…
                  </span>
                </span>
            </label>
          </div>
        </div>
      </div>

    </div>


    {
      state.error !== "" && <p className={"error-message"}> {state.error} </p>
    }
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button className={"primary-button ml-3"} onClick={() => {
          uploadFirmware(state, setState, props.onClose, props.intl)
        }} >{"Upload"}</button>
        <button className={"outline-button"} onClick={props.onClose}>{"Cancel"}</button>
      </div>
    </div>
  </div>
}

export function showFirmwareCreationModal(props: Props) {
  confirmAlert({
    customUI: ({onClose}) =>
      <FirmwareCreationModal
        intl={props.intl}
        onClose={() => {
          props.onClose();
          onClose();
        }}
      />
  });
}
