import {confirmAlert} from "react-confirm-alert";
import React, {useState} from "react";
import Select from "react-select";
import _ from "lodash";

import {
  DataSource,
  columnNamesPerDatasource,
  ColumnName,
  ReducerOperator, reducerOperators
} from "../ast/ast";
import {FilterCollectionChartReducer, FilterCollectionReducer} from "../../../models/filter";

export async function showDataChartReducerCreation(intl: Record<string, string>, dataSource: DataSource, columnNames: string[], onCreate: (filterReducer: FilterCollectionChartReducer) => void, only?: string) {

  confirmAlert({
    customUI: ({onClose}) =>
      <div
        style={{minHeight: 150, backgroundColor: "white"}}
        className={"configuration-editor p-3 pt-4"}
      >
        <CreateDataChartReducerWidget intl={intl} columnNames={columnNames} dataSource={dataSource} onCreate={(f) => {
          onCreate(f);
          onClose();
        }} only={only} close={onClose}/>
      </div>
  });
}

function CreateDataChartReducerWidget(props: {intl: Record<string, string>, dataSource: DataSource, columnNames: string[], onCreate: (filterReducer: FilterCollectionChartReducer) => void, only?: string, close: () => void}, ) {

  const allowedColumns = columnNamesPerDatasource[props.dataSource!]
  const [selectedYColumn, setSelectedYColumn] = useState<ColumnName>(_.first(allowedColumns)!)
  const [selectedXColumn, setSelectedXColumn] = useState<string>(_.first(props.columnNames) ?? "")

  return <div>
    <div className={"row "}>
      <div className={"col-md-12 mb-4"}>
        <h3>{props.intl["y_column_name"].toString().toUpperCase()}</h3>
        <Select
          value={ {label: props.intl[selectedYColumn]!, value: selectedYColumn.toString()} }
          onChange={(t: any) => setSelectedYColumn(t.value)}
          isMulti={false}
          options={allowedColumns.map((ds => ({value: ds, label: props.intl[ds]})))}
          name="device type id"
          className="basic-multi-select mb-4"
          classNamePrefix="select"
        />
      </div>
      <div className={"col-md-12 mb-4"}>
        <h3>{props.intl["x_column_name"].toString().toUpperCase()}</h3>
        <Select
          value={ {label: selectedXColumn, value: selectedXColumn.toString()} }
          onChange={(t: any) => setSelectedXColumn(t.value)}
          isMulti={false}
          options={props.columnNames.map((ds => ({value: ds, label: ds})))}
          name="device type id"
          className="basic-multi-select mb-4"
          classNamePrefix="select"
        />
      </div>
    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.onCreate({x_column_name: selectedXColumn, y_column_name: selectedYColumn, type: "FILTER_COLLECTION_CHART_REDUCER"});
        }}>{props.intl["ok"]}</button>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.close()
        }}>{props.intl["close"]}</button>
      </div>
    </div>
  </div>
}
