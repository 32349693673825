import {StatConfig} from "../../models/stat_config";
import {StatConfigActions} from "../actions/stat_config_actions";

export type StatConfigState = {
  statConfigs: StatConfig[],
  error: string | null,
  loading: boolean
}

export default function(state: StatConfigState = { statConfigs: [], error:null, loading:false }, action: StatConfigActions): StatConfigState {
  switch (action.type) {
    case "FETCH_STATS_CONFIGS":
      return {...state, loading: true}
    case "SET_STATS_CONFIGS":
      return {...state, loading: false, error: null, statConfigs: action.statConfigs}
    case "SET_STATS_CONFIGS_LOADING_ERROR":
      return {...state, loading: false, error: action.error}
    default:
      return state
  }
}
