import {Gateway} from "../../models/gateway";
import {FETCH_GATEWAYS, GatewaysAction, SET_GATEWAYS, SET_GATEWAYS_ERROR} from "../actions/gateways_actions";

const initialState = {
  gateways: Array<Gateway>(),
  error: "",
  loading: false
};

export default function(state = initialState, action: GatewaysAction) {
  switch (action.type) {
    case SET_GATEWAYS: {
      return {
        gateways: action.gateways,
        error: "",
        loading: false
      };
    }
    case SET_GATEWAYS_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    }
    case FETCH_GATEWAYS: {
      return {
        ...state,
        loading: true
      }
    }
    default:
      return state;
  }
}
