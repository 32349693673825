import React from "react";
import _ from "lodash";
import {compact} from "lodash-es";

type Props = {
  left_arity: number
  right_arity: number
  name?: string
  rightCompact?: boolean
}


export function CustomConnector(props: Props) {
  return <div className={"and-connector"}>
    <Connector left={true} arity={props.left_arity}/>
    <div className={"and"}>
      <span className={"horizontal_line"}/>
      <p className={"ml-1 mr-1"}>{props.name ?? "AND"}</p>
      <span className={"horizontal_line"}/>
    </div>
    <Connector left={false} arity={props.right_arity} compact={props.rightCompact}/>
  </div>
}

const class_for_arity = (arity: number) => {
  switch (arity) {
    case 1: return "one-item";
    case 2: return `two-items`;
    case 3: return `three-items`;
    default: return ""
  }
}

export function Connector(props: {left: boolean, arity: number, compact?: boolean}) {

  if(props.compact) return <div className={`connector-container ${class_for_arity(props.arity)}-compact`}>
    <div className={`connector ${ props.arity > 1 ? (props.left ? "left" : "right") : ""}`}>
      {_.range(0, props.arity, 1).map((_) => <span className={"horizontal_line"}/>)}
    </div>
  </div>

  return <div className={`connector-container ${class_for_arity(props.arity)}`}>
    <div className={`connector ${ props.arity > 1 ? (props.left ? "left" : "right") : ""}`}>
      {_.range(0, props.arity, 1).map((_) => <span className={"horizontal_line"}/>)}
    </div>
  </div>
}