import {Group} from "../models/group";
import {BASE_URL} from "../index";
import {httpDelete} from "./generic_repository";

export const repoCreateGroup = async (group: Group) => {
  const response = await fetch(BASE_URL + '/api/v1/group', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/vnd.api+json'
    },
    body: JSON.stringify(group)
  });
  return await response.json();
};

export const deleteGroup = async (id: string) => {
  try {
    const result = await httpDelete('/api/v1/user/group/' + id.toString());
    if(result.status === 204) return "";
    return result.status.toString();

  } catch (e) {
    console.log(e);
    return e.toString();
  }
}

