import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useIntl} from "react-intl";
import {Group} from "../../../models/group";
import {Divider} from "../../../components/divider";
import {LogPanel} from "../../../components/log_panel";
import {Link} from "react-router-dom";
import {AltiorMeterType} from "../../../models/altior_meter_type";
import * as vm from "../edit_meter_page_vm";
import _ from "lodash";
import {LoadMetersState, uploadMeters} from "./load_meters_page_vm";
import {history} from "../../../index";
import {Log} from "../../../models/log";
import AsyncSelect from "react-select/async";
import {remoteFetchData} from "../../../repsitory/generic_repository";
import {defaultLogFormatter} from "../../../utils/object_formatting";

const importTypes = ["oms", "wize"];

export function LoadMetersPage() {
  const intl = useIntl();
  const [state, setState] = useState<LoadMetersState>({ metersInfo: null, metersKeys: null, error: "", importType: "oms" });
  const [altiorMeterTypes, setAltiorMeterTypes] = useState<AltiorMeterType[]>([]);
  const [importType, setImportType] = useState("oms");

  useEffect(() => {
    (async () => {
      vm.fetchMeterTypes().then((mt) => {
        if(typeof mt === "string") {
          setState({...state, error: mt});
        } else {
          setAltiorMeterTypes(mt);
        }
      })
    })()
  }, [0]);

  return (<div>
    <div className={"row"}>
      <div className={"col-md-2"}>
        <h3 className={"mt-3"}>{intl.messages["meters_info"].toString().toUpperCase()}</h3>
      </div>
      <div className={"col-md-8 pt-1"}>
        <p className={"outline-p"}>{state.metersInfo?.name || ""}</p>
      </div>
      <div className={"col-md-2 pt-1 d-flex flex-row-reverse"}>
        <div className="file">
          <label className="file-label">
            <input className="file-input" type="file" name="resume" onChange={(e) => setState({...state, metersInfo: (e?.target?.files?.item(0) || null) })}/>
            <span className="file-cta">
                  <span className="file-label">
                    Choose a file…
                  </span>
                </span>
          </label>
        </div>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-2"}>
        <h3 className={"mt-3"}>{intl.messages["meters_keys"].toString().toUpperCase()}</h3>
      </div>
      <div className={"col-md-8 pt-1"}>
        <p className={"outline-p"}>{state.metersKeys?.name || ""}</p>
      </div>
      <div className={"col-md-2 pt-1 d-flex flex-row-reverse"}>
        <div className="file">
          <label className="file-label">
            <input className="file-input" type="file" name="resume" onChange={(e) => setState({...state, metersKeys: (e?.target?.files?.item(0) || null) })}/>
            <span className="file-cta">
                  <span className="file-label">
                    Choose a file…
                  </span>
                </span>
          </label>
        </div>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-2"}>
        <h3 className={"mt-3"}>{intl.messages["default_group"].toString().toUpperCase()}</h3>
      </div>
      <div className={"col-md-10 pt-1 mb-2"}>
        <AsyncSelect
          defaultValue={{label: "", value: -1}}
          onChange={(g: any) => setState({...state, deviceGroupId: g.value })}
          isMulti={false}
          cacheOptions
          defaultOptions
          loadOptions={ (input: string) => {
            return remoteFetchData<Group[]>(`/api/v1/group?name=${input}`).then((gr) => {
              if(typeof gr !== "string") {
                return gr.map((g) => ({ value: g.attributes.id, label: g.attributes.name }));
              } else {
                setState({...state, error: intl.messages["cannot_fetch_meter_groups"].toString()})
                return [];
              }
            })
          } }

          name="groups"
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-2"}>
        <h3 className={"mt-3"}>{intl.messages["device_type_id"].toString().toUpperCase()}</h3>
      </div>
      <div className={"col-md-10 pt-1"}>
        <div className={"mb-2"}>

          <Select
            defaultValue={(state.deviceTypeId ?? _.first(altiorMeterTypes)?.deviceTypeId) as any}
            onChange={(g: any) => setState({...state, deviceTypeId: g.value})}
            isMulti={false}
            options={altiorMeterTypes?.map((g) => ({ value: g.deviceTypeId, label: g.name }))}
            name="device type id"
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-2"}>
        <h3 className={"mt-3"}>{intl.messages["import_type"].toString().toUpperCase()}</h3>
      </div>
      <div className={"col-md-10 pt-1"}>
        <div className={"mb-2"}>
          <Select
            defaultValue={ {label: importType, value: importType} }
            onChange={ (a: any) => {
              setImportType(a.value);
              setState({...state, importType: a.value});
            }}
            isMulti={false}
            options={ importTypes.map(a => ({label: a, value: a})) as any }
            name="device type id"
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </div>
    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button className={"primary-button"} onClick={() => uploadMeters(state, setState, history, intl.messages as any)} >{"Upload"}</button>
      </div>
    </div>
    <Divider/>
    <div className={"row"}>
      <div className={"col-md-12"}>
        <LogPanel
          filter={(a: Log) => a.channel === "oban_channel"}
          formatter={defaultLogFormatter}
        />
      </div>
    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <Link className={"outline-button"} to={"/meters"}>{"Cancel"}</Link>
        {
          state.error !== "" && <p className={"error-message"}> {state.error} </p>
        }
      </div>
    </div>
  </div>);
}