import React, {ReactNode, useState} from 'react';
import ReactPaginate from "react-paginate";
import '../App.css';

type Props<T> = {
  headers: string[],
  columns: T[],
  rowBuilder: (t: T) => ReactNode[],
  filter?: (ts: T) => boolean,
  onTap?: (t: T) => void,
  columnsPerPage?: number
  paginationVisible?: boolean
};

export function IndexTable<T>(props: Props<T>) {

  const filter = props.filter || ((_) => true);
  const onTap = props.onTap || ((_) => {});
  const paginationVisible = props.paginationVisible ?? true;
  const columnsPerPage = props.columnsPerPage ?? (paginationVisible ? 4 : 10000);
  const [currentPage, setCurrentPage] = useState(0);


  const filterdItems = props.columns.filter(filter);

  const paginated = (l: T[]) => {
    return l.slice(
      columnsPerPage * currentPage,
      Math.min((currentPage * columnsPerPage + columnsPerPage), filterdItems.length)
    );
  };

  return (
    <div className={"scroll-x"}>
      <table className={"table table-borderless"} style={{overflowX: "scroll"}}>
        <tbody>
        <tr>{ props.headers.map((s: string, i: number) => <th key={s} className={ className(i, props.headers.length) }>{s.toUpperCase()}</th>) }</tr>
        { paginated(filterdItems).map((item: T, index: number) => <tr key={JSON.stringify(item)} onClick={() => onTap(item)}>
          {
            props.rowBuilder(item).map((node: ReactNode, index: number) => <td key={ JSON.stringify(item) + index } className={ className(index, props.rowBuilder(item).length) }>{node}</td>)
          }
        </tr>) }
        </tbody>

      </table>
      {paginationVisible &&
        <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={ Math.ceil(filterdItems.length / columnsPerPage) }
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={({selected}) => setCurrentPage(selected)}
            containerClassName={'d-flex flex-row m-0 p-0'}
            activeClassName={'bold ml-2 mr-2'}
            pageClassName={"text ml-2 mr-2"}
            previousClassName={"text ml-2 mr-2"}
            nextClassName={"text ml-2 mr-2"}
        />
      }

    </div>

  );
}

const className = (index: number, length: number) => {
  // if(index > 0 && index !== length - 1) return "text-left ml-4";
  if(index === length - 1) return "actions-column";
  return "text-left pl-0";
};