import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Responsive as ResponsiveGridLayout} from 'react-grid-layout';
import {Dashboard} from "../../../models/dashboard/dashboard";
import {useDispatch, useSelector} from "react-redux";
import {buildWidget, statConfigsFromFilterCollection} from "./dashboard_detail_vm";
import {AppState} from "../../../store/store";
import "../dashboard.css";
import {refreshWebSocket} from "../../../repsitory/dashboards_repository";
import {StatConfig} from "../../../models/stat_config";
import {fetchFilterCollections} from "../../../repsitory/filters_repository";


type Props = {
  dashboard: Dashboard,
}

let width = 1000;

export function DashboardDetail(props: Props) {

  const intl = useIntl();

  const dispatch = useDispatch();
  const appState = useSelector((a: AppState) => a);

  const [dashboard, setDashboard] = useState<Dashboard>(props.dashboard);

  const ref = useRef<HTMLDivElement>(null);


  useEffect(() => {
    console.log("refreshed props")
    setDashboard(props.dashboard);
  }, [JSON.stringify(props.dashboard)])

  useEffect(() => {
    setTimeout(refreshWebSocket, 150)
  }, [dashboard])

  const [width, setWidth] = useState(1000);
  useEffect(() => {
    ref?.current?.clientWidth && setWidth(ref.current.clientWidth)
    const t = setInterval(() => {
      ref?.current?.clientWidth && setWidth(ref.current.clientWidth)
    }, 500)
    return () => clearInterval(t);
  }, [0])


  const [dexStatConfigs, setDexStatConfigs] = useState<StatConfig[]>([]);
  useEffect(() => {
    (async () => {
      const filterCollections = await fetchFilterCollections();
      if(typeof filterCollections !== "string") setDexStatConfigs(statConfigsFromFilterCollection(filterCollections))
    })();
  }, [0])

  return (
    <div >
      <div className={"row mt-2"} style={ {minHeight: 650, marginLeft: 0} } ref={ref}>
        <div className={"col-md-12"}>
          <ResponsiveGridLayout
            style={{marginLeft: -15}}
            compactType={null}
            width={ width - 15 }
            cols={{lg: 10, md: 10, sm: 10, xs: 10, xxs: 10}}
          >

               {/*Widget taken from dashboard   */}
            {dashboard.widgets.filter((w) => w.type !== "editor").map((w) =>
                <div className={ w.name.includes("gateway") ? "dashboard-gateway-widget" :"dashboard-widget"}
                     key={w.key}
                     data-grid={ {...w.data_grid, isDraggable: false, isResizable: false}}
                >
                { buildWidget(w, dispatch, appState, intl.messages, dexStatConfigs) }
              </div>
              )}

          </ResponsiveGridLayout>
        </div>
      </div>

    </div>
  );
}
