import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {EditableRow} from "../../components/InfoRow";

import {BASE_URL, fallbackMapCenter, history} from "../../index";

import {getOwnerConfiguration, OwnerConfigurationPageState, updateConfiguration} from "./admin_owner_config_page_vm";
import {GeoJSONPoint} from "../../models/geo_json";
import {gpsFromGeoJsonPoint} from "../../service/address_from_coordinates";
import {Leafleet} from "../../components/leafleet_map/leafleet_map";
import "../../styles/bulma.css";
import {updateOwnerConfiguration} from "../../repsitory/owner_configuration_repository";

// @ts-ignore
import Loader from 'react-loader-spinner'


export function OwnerConfigurationPage() {

  const [state, setState] = useState<OwnerConfigurationPageState>({
    address: "", lat: fallbackMapCenter.lat, lng: fallbackMapCenter.lng, logoUrl: "", error: "", coordinates: {
    type: "Feature",
      properties: {},
    geometry: {
      type: "Point",
        coordinates: [fallbackMapCenter.lng, fallbackMapCenter.lat]
    }
  },
  vat: "",
  companyName: "",
  logo: null,
  phoneNumber: "",
  loading: false,
  companyWebsite: "",
    logoFile: null,
  })

  useEffect(() => {
    getOwnerConfiguration().then(setState);
  }, [1])

  useEffect(() => {
    if(state.logoFile == null) return;
    setState({...state, loading: true});
    let reader = new FileReader();
    reader.readAsDataURL(state.logoFile);
    reader.onload = async () => {
      setState({...state, logo: reader?.result?.toString() ?? null, loading: false})
    };
  }, [state.logoFile])

  const intl = useIntl();
  return (
    <div>

      <EditableRow title={intl.messages["company-name"].toString()} value={ state.companyName } onEdit={(s) => setState({...state, companyName: s})}/>

      <div className={"row"}>
        <div className={ "col-md-9"}>

          {/*<EditableRow title={intl.messages["logo-url"].toString()} value={ state.logo_url } onEdit={(s) => setState({...state, logo_url: s})}/>*/}
          <h3 className={""}>{intl.messages["logo"].toString().toUpperCase()}</h3>
          <div className={"row"}>
            <div className={"col-md-12 d-flex flex-row"}>
              <div className={"flex-grow-1 pt-1"}>
                <p className={"outline-p"}>{state.logoFile?.name || ""}</p>
              </div>
              <div className={"ml-3 pt-1"}>
                <div className="file">
                  <label className="file-label">
                    <input className="file-input" type="file" name="resume" onChange={(e) => setState({...state, logoFile: (e?.target?.files?.item(0) || null) })}/>
                    <span className="file-cta">
                  <span className="file-label">
                    Choose a file…
                  </span>
                </span>
                  </label>
                </div>
              </div>
            </div>

          </div>
          <EditableRow title={intl.messages["vat"].toString()} value={ state.vat } onEdit={(s) => setState({...state, vat: s})}/>
          <EditableRow title={intl.messages["phone-number"].toString()} value={ state.phoneNumber } onEdit={(s) => setState({...state, phoneNumber: s})}/>
        </div>
        {/*{state.logo &&*/}
        <div className={"col-md-3"}>
            <h3 className={""}>{intl.messages["logo-preview"].toString().toUpperCase()}</h3>
            <img className={"img-preview "} src={state.logo ?? `${BASE_URL}/api/v1/owner-configuration/logo`}/>
        </div>
        {/*}*/}
      </div>



      <EditableRow title={intl.messages["company-website"].toString()} value={ state.companyWebsite } onEdit={(s) => setState({...state, companyWebsite: s})}/>
      <EditableRow title={intl.messages["address"].toString()} value={ state.address } onEdit={(s) => setState({...state, address: s})}/>

      <div className={"col-md-12 d-flex flex-row pl-0 pr-0"}>
        <div className={"flex-grow-1 ml-0"}>
          <h3>{ intl.messages["lat"].toString().toUpperCase()}</h3>
          <input
            type='number'
            className='form-control mb-2 '
            value={state?.coordinates?.geometry?.coordinates[1]?.toString() || ""}
            onChange= {(evt) => {
              if(state.coordinates) {
                const coordinates = {
                  ...state.coordinates,
                  geometry: {
                    ...state.coordinates.geometry,
                    coordinates: [state.coordinates.geometry.coordinates[0], Number(evt.target.value)]
                  }
                };
                setState({...state, coordinates})
                // gpsFromGeoJsonPoint(coordinates).then(location => setState({...state, coordinates, location}))
              }
            }}
          />
        </div>
        <div className={"flex-grow-1 mr-0"}>
          <h3>{ intl.messages["lng"].toString().toUpperCase()}</h3>
          <input
            type='number'
            className='form-control mb-2 flex-grow-1'
            value={state?.coordinates?.geometry?.coordinates[0]?.toString() || ""}
            onChange= {(evt) => {
              if(state.coordinates) {
                const coordinates = {
                  ...state.coordinates,
                  geometry: {
                    ...state.coordinates.geometry,
                    coordinates: [Number(evt.target.value), state.coordinates.geometry.coordinates[1]]
                  }
                };
                setState({...state, coordinates})
                // gpsFromGeoJsonPoint(coordinates).then(location => setState({...state, coordinates, location}))
              }
            }}
          />
        </div>
      </div>
      <Leafleet
        key={JSON.stringify(state.coordinates)}
        height={400}
        map_id={"meters-create"}
        editableItem={state.coordinates}

        fallbackMapCenter={fallbackMapCenter}
        onEdit={ async (dot) => {

          if(dot) {
            setState({...state, coordinates: dot as GeoJSONPoint, address: await gpsFromGeoJsonPoint(dot as GeoJSONPoint)});
          }

        } }/>

      <div className={"row mt-3"}>
        <div className={"col-md-12 ellipsis mt-1"}>

          <div className={"d-flex flex-row-reverse"}>
            <button className={"p3 primary-button"} onClick={() => {
              if(!state.loading) updateConfiguration(state, setState, intl.messages as any, history);
            }}>
              {state.loading && <Loader type="ThreeDots" color="#3C4B64" height={30} width={40} />}
              {intl.messages['save']}
            </button>
            <Link to={ "/admin" } style={{ textDecoration: 'none', color: '#fff' }}>
              <div className={"p3 outline-button mr-3"}>
                {intl.messages['cancel']}
              </div>
            </Link>
            {state.error && <p className={"error-message"}>{state.error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}