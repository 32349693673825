import {Log, LogLevel} from "../models/log";
import {gray, orange, red} from "./colors";
import _ from "lodash";

export function formatHeader(header: string) {
  return header.replace("_", " ").toUpperCase();
}

export function bigNumberFormatter(bigNumber: any) {
  if(bigNumber > 1000000000) return `${bigNumber / 1000000000}G`;
  if(bigNumber > 1000000) return `${bigNumber / 1000000}M`;
  if(bigNumber > 1000) return `${bigNumber / 1000}K`;

  return bigNumber;
}

export function titleCase(str: string) {
  let splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function formatObject(object: any) {
  if(typeof object === "number") return object;
  try {
    if(object.toString().includes("T")) {
      const d = new Date(object);
      const formattedDate = `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
      if(formattedDate !== "Invalid Date Invalid Date") return formattedDate;
    }

  } catch (e) {

  }
  if(typeof object === "string") return object;
  const value = JSON.stringify(object);

  if(value === "null" || value === "undefined") return "--"
  return value;
}


export function defaultLogFormatter(l: Log): {message: string, color: string} {

  const level: LogLevel = l.log.level ?? "info";
  const timestamp: string = l.log.timestamp ?? (new Date()).toISOString();

  const color: string = (() => {
    switch (level) {
      case "debug":
        return "#8d8d8d"
      case "info":
        return gray
      case "warning":
        return orange
      case "error":
        return red
    }
  })();

  const keys: string[] = _.difference(Object.keys(l.log), ["level", "timestamp"]);

  return {
    message: `[${timestamp}] [${level}]  ${keys.map((k: string) => `${k}: ${l.log[k]?.toString() ?? ""}`).join(" ")}`,
    color
  }

}

export function formatLogObject(object: any, except?: string[], depth: number = 1): string {

  if(object === null) return "";

  if(Array.isArray(object) && depth > 0) {
    return object.map((o) => formatLogObject(o, [], depth - 1)).join("\n")
  }



  const keys: string[] = _.difference(Object.keys(object), except ?? []);
  return `${keys.map((k: string) => `${k}: ${object[k]?.toString() ?? ""}`).join(" ")}`
}
