import {getNoBaseUrl} from "../repsitory/generic_repository";
import {GeoJSONPoint} from "../models/geo_json";

type Coordinates = {
  lat: number,
  lng: number
}


export const gpsFromCoordinates: (c: Coordinates) => Promise<string> = async (coordinates: Coordinates) => {
  console.log("asking coords");
  const resp = await getNoBaseUrl(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${coordinates.lat}&lon=${coordinates.lng}&addressdetails=1`);
  console.log(resp);
  return resp.display_name || "Error"
};

export const gpsFromGeoJsonPoint: (p: GeoJSONPoint) => Promise<string> = async (p) => {
  console.log("asking coords");
  const resp = await getNoBaseUrl(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${p.geometry.coordinates[1]}&lon=${p.geometry.coordinates[0]}&addressdetails=1`);
  console.log(resp);
  return resp.display_name || "Error"
};



