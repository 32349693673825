type MenuItemType =
  | "Dashboards"
  | "Network"
  | "Meters"
  | "Admin"
  | "Users"
  | "Groups"
  | "Data Explorer"


export const menuRoutes = {
  "Dashboards": "/dashboards",
  "Network": "/gateways",
  "Meters": "/meters",
  "Admin": "/admin",
  "Users": "/users",
  "Groups": "/groups",
  "Data Explorer": "/data-explorer"
};

export default MenuItemType;