import React from "react";
import * as h from "history";
import {scheduleJob} from "../../../repsitory/job_repository";

export type ScheduleFirmwareState = {
  start: Date,
  stop: Date,
  deviceTypeId?: string,
  jobSchedule: Date,
  firmwareId: number,
  groupId: number,
  meters: number,
  error: string
}

function validate(state: ScheduleFirmwareState, intl: Record<string, string>): string[] {
  let errors: string[] = [];

  if(state.firmwareId === -1) errors = [ intl["please_select_a_firmware"] ,...errors];
  if(state.groupId === -1) errors = [ intl["please_select_a_group"] ,...errors];

  return errors;
}

export async function createScheduleFirmwareJob(state: ScheduleFirmwareState, setState: React.Dispatch<React.SetStateAction<ScheduleFirmwareState>>, history: h.History, intl: Record<string, string>) {

  const errors = validate(state, intl).join(", ");

  setState({...state, error: errors});

  if(errors !== "") return;

  const result = await scheduleJob({date: state.jobSchedule, type: "job"}, {...state});


  if(result.status === 204) {
    history.goBack();
  } else {
    setState({...state, error: intl["error_scheduling_job"]})
  }
}