import {JobCron, JobSchedule, validateJobSchedule} from "../../components/job_schedule_form/job_schedule_form_vm";
import {scheduleJob} from "../../repsitory/job_repository";
import React from "react";

import * as h from 'history';
import {StorageConfiguration} from "../../models/storage_configuration";
import {updateStorageConfiguration} from "../../repsitory/storage_configuration_repository";
import {ConnectionButtonState} from "../../components/test_connection_button/test_connection_button";
import {postRaw} from "../../repsitory/generic_repository";


export type AdminFtpPageState = {
  address: string;
  user: string
  password: string
  upload: string,
  jobSchedule: JobCron,
  error: string,
  storageConfig: StorageConfiguration,
  date: Date

}

export async function createJob(state: AdminFtpPageState, setState: React.Dispatch<React.SetStateAction<AdminFtpPageState>>, history: h.History, intl: Record<string, string>) {

  //  saving configuration

  const update_reuslt = await updateStorageConfiguration(state.storageConfig, intl, true);

  if(typeof update_reuslt === "string") setState({...state, error: update_reuslt});

  const errors = validateJobSchedule(state.jobSchedule, intl).join(", ");

  setState({...state, error: errors});

  if(errors !== "") return;

  const result = await scheduleJob(state.jobSchedule, {...state}, 2);

  if(result.status === 204) {
    history.goBack();
  } else {
    setState({...state, error: intl["error_scheduling_job"]})
  }
}


export async function testSftpConnection(params: {
  "host_name": string,
  "port": number,
  "user": string,
  "password": string,
  "folder": string
}, intl: Record<string, string>): Promise<ConnectionButtonState> {


  switch (await testSftpConnectionRaw(params, intl)) {
    case "ok": return "success"
    case "err": return "error"
    case "setup_required":  return "success"

    default: return "error"
  }
}

export async function testSftpConnectionRaw(params: {
  "host_name": string,
  "port": number,
  "user": string,
  "password": string,
  "folder": string
}, intl: Record<string, string>): Promise<string> {
  const response = await postRaw(params, '/api/v1/test-sftp-connection');
  if(typeof response == "string") {
    return "error";
  }
  return await response.text();
}