import {UserGroup} from "../models/user_group";
import {fetchSinglePageCollection, get, httpDelete, putStatusCode} from "./generic_repository";
import {PaginatedList} from "../components/paginated_list/paginated_sublist";
import {User} from "../models/user";

export async function fetchAllGroups (): Promise<UserGroup[]>  {
    const resp = await fetchSinglePageCollection<UserGroup>("/api/v1/user/group")(1, 8);
    if(typeof resp === 'string') return [];
    return resp.sublist;
}

export async function fetchUserGroups(userId: string): Promise<UserGroup[]>  {
    const resp = await fetchSinglePageCollection<UserGroup>(`/api/v1/user/${userId}/group`)(1, 8);
    if(typeof resp === 'string') return [];
    return resp.sublist;
}


export async function addUserToGroup(userId: string, groupId: string): Promise<boolean>  {
    // console.log(`Adding ${userId} to group ${groupId}`);
    const status = await putStatusCode("", `/api/v1/user/${userId}/group/${groupId}`);
    return status === 204;
}

export async function removeUserToGroup(userId: string, groupId: string): Promise<boolean>  {
    // console.log(`Removing ${userId} to group ${groupId}`);
    const status = (await httpDelete(`/api/v1/user/${userId}/group/${groupId}`)).status;
    return status === 204;
}

export const fetchPaginatedUsers: (url: string) => (selectedPage: number, perPage: number) => Promise<PaginatedList<User> | string> = (url: string) => async (selectedPage: number, perPage: number) => {
    try {
        //  min page is 1 so offset in this case should be 0
        const offset = (perPage * selectedPage) - perPage;

        const responseCurrentPage = await get(`${url}?per_page=${perPage}&offset=${offset}`);
        if(responseCurrentPage.status > 300) {
            return `Error, status ${responseCurrentPage.status}`;
        }

        const nextPageCount = (await get(`${url}?per_page=${perPage}&offset=${offset + perPage}`))?.data?.length ?? 0;

        const sublist = (responseCurrentPage.data).map((a: { attributes: User }) => a.attributes) as User[];

        return {
            sublist,
            count: perPage + offset + nextPageCount,
            perPage,
            selectedPage
        }

    } catch (e) {
        return e.toString();
    }
};





export const deleteUser = async (id: string) => {
    try {
        const result = await httpDelete('/api/v1/user/' + id.toString());
        if(result.status === 204) return "";
        return result.status.toString();

    } catch (e) {
        console.log(e);
        return e.toString();
    }
}

