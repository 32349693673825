import {Channel, Socket} from "phoenix";

export type AddLog = {
  log: any, channelName: string, eventName: string, type: 'ADD_LOG'
}

export type SubscribeTo = {
  channelName: string, eventName: string, type: 'SUBSCRIBE_TO'
}

export type UpdateSubscriptions = {
  channelName: string, channel: Channel, eventName: string, type: 'UPDATE_SUBSCRIPTIONS'
}

export type UpdateSocket = {
  type: 'UPDATE_SOCKET',
  socket: Socket,
}

export type LogAction = AddLog | SubscribeTo | UpdateSubscriptions | UpdateSocket

export const addLogAction = (log: any, channelName: string, eventName: string) => ({
  type: 'ADD_LOG',
  log,
  channelName,
  eventName
});

export const subscribeToAction = (channelName: string, eventName: string) => ({
  type: 'SUBSCRIBE_TO',
  channelName,
  eventName
});

export const updateSubscriptionsAction = (channelName: string, eventName: string, channel: Channel) => ({
  channelName,
  channel,
  eventName,
  type: 'UPDATE_SUBSCRIPTIONS'
})

export const updateSocketAction = (socket: Socket) => ({
  socket,
  type: 'UPDATE_SOCKET'
})

