import {confirmAlert} from "react-confirm-alert";
import React, {useEffect, useRef, useState} from 'react'
import '../../../components/draggable_list/draggable_list.css';
import {DraggableList} from "../../../components/draggable_list/draggable_list";
import {EyeIcon, EyeOffIcon, Handle} from "../../../components/icons";
import _ from "lodash";
import {formatHeader} from "../../../utils/object_formatting";


export async function showColumnOrderModal(intl: Record<string, string>, columnNames: string[], onSave: (f: number[], vc: boolean[]) => void, visibleColumns: boolean[], permutation?: number[]) {

  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({onClose}) =>
      <div
        style={{minHeight: 150, backgroundColor: "white"}}
        className={"configuration-editor p-3 pt-4"}
      >
        <ColumnOrderModal intl={intl} close={onClose} columnNames={columnNames} onSave={(f, vc) => {
          onSave(f, vc);
          onClose();
        }}
        permutation={permutation}
        visibleColumns={visibleColumns}
        />
      </div>
  });
}

function ColumnOrderModal(props: {intl: Record<string, string>, close: () => void, onSave: (f: number[], vc: boolean[]) => void, columnNames: string[], permutation?: number[], visibleColumns?: boolean[]}, ) {

  let originalPermutation = props.permutation ?? props.columnNames.map((_, i) => i);
  if(originalPermutation.length !== props.columnNames.length) originalPermutation =  props.columnNames.map((_, i) => i);

  let originalHiddenColumns = props.visibleColumns ?? props.columnNames.map((_, i) => true);
  if(originalHiddenColumns.length !== props.columnNames.length) originalHiddenColumns = props.columnNames.map((_, i) => true);


  const [order, setOrder] = useState<number[]>(_.cloneDeep(originalPermutation));
  const [visibleColumns, setVisibleColumns] = useState<boolean[]>(originalHiddenColumns)
  useEffect(() => { if((visibleColumns.length ?? 0) !== order.length) setVisibleColumns(props.columnNames.map((_, i) => false))}, [props.visibleColumns])

  return <div>
    <div className={"row"} style={{paddingLeft: 24}}>
      <div className={"col-md-12 mb-4"}>
        <h2>{props.intl["columns_order"].toString()}</h2>
        <h3>{props.intl["drag_columns_to_change_their_order"].toString().toUpperCase()}</h3>
      </div>
    </div>
    <DraggableList
      items={
        props.columnNames.map((n, i) =>
          <DraggableItem
            key={n}
            name={`${formatHeader(n)}`}
            visible={visibleColumns[originalPermutation[i]]}
            onTap={() => {
              const hiddenCOls = _.cloneDeep(visibleColumns);
              const index = originalPermutation[i];
              hiddenCOls[index] = !hiddenCOls[index];
              setVisibleColumns(hiddenCOls);
            }}/>
        )
      }
      onOrderChanged={setOrder}
      permutation={props.permutation}
    />
    <div className={"row mt-4"} style={{paddingRight: 16}}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.onSave(order, visibleColumns);
        }}>{props.intl["ok"]}</button>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.close();
        }}>{props.intl["close"]}</button>
      </div>
    </div>

  </div>
}


function DraggableItem(props: {name: string, visible: boolean, onTap: () => void}) {
  return <div style={{display: "flex", flexDirection: "row", height: 50}}>
    <div className={"mr-4"} style={{paddingBottom: 1, color: "yellow"}}><Handle/></div>
    <div style={{ flexGrow: 1 }}>
      {props.name}
    </div>
    <div>
      <button className={"icon-button mr-3"} onClick={props.onTap}> {props.visible ? <EyeIcon color={"white"}/> : <EyeOffIcon color={"white"}/>} </button>
    </div>
  </div>
}
