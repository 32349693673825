import en from "./en.json"
import it from "./it.json"


export type Language = "it" | "en";

const messages = {
  'en': en,
  'it': it
};

export default messages;