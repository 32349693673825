import {FilterCollection} from "../../../models/filter";

export function setFavourite(fc: FilterCollection) {
  const favourites: number[] = JSON.parse(window.localStorage.getItem("favourite_filters") ?? "[]")
  window.localStorage.setItem("favourite_filters", JSON.stringify([fc.id,...favourites.filter((f) => f !== fc.id).slice(0,4)]))
}

export function removeFavourite(fc: FilterCollection) {
  const favourites: number[] = JSON.parse(window.localStorage.getItem("favourite_filters") ?? "[]")
  window.localStorage.setItem("favourite_filters", JSON.stringify(favourites.filter((f) => f !== fc.id)))
}

export function getFavourites() {
  const favourites: number[] = JSON.parse(window.localStorage.getItem("favourite_filters") ?? "[]")
  return favourites;
}