export type User = {
    access: string
    additionalAttributes: {
        department: string[],
        badge_id: string[],
        status: string[],
    }
    clientConsents: boolean
    clientRoles: string[]
    createdTimestamp: number
    credentials: any
    disableableCredentialTypes: string[]
    email: string
    emailVerified: boolean
    enabled: boolean
    federatedIdentities: any
    federationLink: string
    firstName: string
    groups: string[]
    id: string
    lastName: string
    notBefore: number
    origin: any
    realmRoles: any
    requiredActions: any[]
    serviceAccountClientId: string
    username: string
}

export const emptyUserGroup = () => ({
    access: "string",
    additionalAttributes: {
        department: []
    },
    clientConsents: false,
    clientRoles: [],
    createdTimestamp: 0,
    credentials: undefined,
    disableableCredentialTypes: [],
    email: "",
    emailVerified: false,
    enabled: false,
    federatedIdentities: undefined,
    federationLink: "",
    firstName: "",
    groups: [],
    id: "",
    lastName: "",
    notBefore: "",
    origin: "",
    realmRoles: undefined,
    requiredActions: [],
    serviceAccountClientId: "",
    username: ""
})