import * as h from "history";
import {fetchStorageConfiguration, updateStorageConfiguration} from "../../repsitory/storage_configuration_repository";
import {fetchOwnerConfiguration, updateOwnerConfiguration} from "../../repsitory/owner_configuration_repository";
import {fallbackMapCenter} from "../../index";
import {GeoJSONPoint} from "../../models/geo_json";
import {postFormData, putFormData} from "../../repsitory/generic_repository";

export type OwnerConfigurationPageState = {
  address: string;
  lat: number
  lng: number
  logoUrl: string,
  error: string,
  coordinates: GeoJSONPoint,
  vat: string,
  loading: boolean,
  logo: string | null,
  companyName: string
  phoneNumber: string
  companyWebsite: string
  logoFile: File | null
}

export async function getOwnerConfiguration(): Promise<OwnerConfigurationPageState> {
  const result = await fetchOwnerConfiguration();
  if(typeof result === "string") return {
    address: "",
    lat: fallbackMapCenter.lat,
    lng: fallbackMapCenter.lng,
    logoUrl: "",
    logoFile: null,
    loading: false,
    vat: "",
    logo: "",
    companyName: "",
    phoneNumber: "",
    companyWebsite: "",
    error: result,
    coordinates: {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Point",
        coordinates: [fallbackMapCenter.lng, fallbackMapCenter.lat]
      }
    }
  };

  return {
    error: "",
    coordinates: {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Point",
        coordinates: [result.lng, result.lat]
      }
    },
    logoFile: null,
    ...result,
    loading: false
  }
}

export async function updateConfiguration(state: OwnerConfigurationPageState, setState: React.Dispatch<React.SetStateAction<OwnerConfigurationPageState>>, messages: Record<string, string>,  history: h.History) {

  const config = {
    address: state.address,
    lat: state.coordinates.geometry.coordinates[1],
    lng: state.coordinates.geometry.coordinates[0],
    logoUrl: state.logoUrl,
    vat: state.vat,
    logo: "",
    companyName: state.companyName,
    phoneNumber: state.phoneNumber,
    companyWebsite: state.companyWebsite
  };




  const result = await updateOwnerConfiguration(config);
  if(typeof result === "string") return setState({...state, error: result});

  if(state.logoFile) {
    const dataKeys = new FormData();
    dataKeys.append("logo", state.logoFile);
    const keysResponse = await putFormData(dataKeys, "/api/v1/owner-configuration/logo");
    if(keysResponse.status !== 204 && keysResponse.status !== 201) { return setState({
      ...state,
      loading: false,
      error: keysResponse.status === 422 ? messages["File too big"] : messages["Error uploading file"]}
      ) }
    else {
      setState({...state, loading: false});
    }
  }

  history.goBack();
  setState({...state, ...result})

  let reader = new FileReader();

}
