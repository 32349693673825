import {JobSchedule, validateJobSchedule} from "../../../components/job_schedule_form/job_schedule_form_vm";
import React from "react";
import * as h from "history";
import {scheduleJob} from "../../../repsitory/job_repository";

export type ScheduleHighFrequencyReadState = {
  start: Date,
  stop: Date,
  defaultGroup: string
  meters: string
  samplingFreq: number,
  jobSchedule: JobSchedule,
  error: string,
}

export async function createScheduleHighFrequencyJob(state: ScheduleHighFrequencyReadState, setState: React.Dispatch<React.SetStateAction<ScheduleHighFrequencyReadState>>, history: h.History, intl: Record<string, string>) {

  const errors = validateJobSchedule(state.jobSchedule, intl).join(", ");

  setState({...state, error: errors});

  if(errors !== "") return;

  const result = await scheduleJob(state.jobSchedule, {...state});



  if(result.status === 204) {
    history.goBack();
  } else {
    setState({...state, error: intl["error_scheduling_job"]})
  }
}