import React, {useEffect, useState} from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {fetchChartVolumeRead} from "./volume_reads_vm";
import {useIntl} from "react-intl";
import {SaveIcon} from "../icons";
import {Meter} from "../../models/meter";
import {PaginatedListComponent} from "../paginated_list/paginated_list_component";
import {fetchPaginatedCollection, remoteFetchData} from "../../repsitory/generic_repository";
import {VolumeReading} from "../../models/volume_reading";
import Select from "react-select";
import {showExportFileDialog} from "../export_file_dialog";
import moment from "moment";
import {formatLogObject} from "../../utils/object_formatting";
import {useDispatch} from "react-redux";
import {subscribeSocket} from "../../store/effects/log_effects";
import store from "../../store/store";

type Props = {
  ldn: string,
  meter: Meter,
  width: number
}

type ChartDatum = {
  date: string,
  volume: number,
  rssi: number,
  metrologicalError: any
  systemError: any
}

export function VolumeReads(props: Props) {

  const [data, setData] = useState<ChartDatum[]>([]);
  const [days, setDays] = useState(7);
  const intl = useIntl();


  useEffect(() => {
    subscribeSocket(store);
  }, [0])

  useEffect(() => {
    (async () => {
      const data = await fetchChartVolumeRead(props.ldn, days);
      // @ts-ignore
      setData(data)
    })()
  }, [JSON.stringify(props.meter), days]);

  return (    <div>
    <h2>{intl.messages["volume-reads"].toString()}</h2>
    <div className={"row"}>
      <div className={"col-md-12 d-flex"}>
        <div className={"flex-grow-1"}>
          <Select
            defaultValue={{label: intl.messages['1 week'].toString(), value: 7}}
            onChange={(g: any) => setDays(g.value)}
            isMulti={false}
            options={[
              {label: intl.messages['1 day'].toString(), value: 1},
              {label: intl.messages['3 days'].toString(), value: 5},
              {label: intl.messages['1 week'].toString(), value: 7},
              {label: intl.messages['1 month'].toString(), value: 30},
              {label: intl.messages['3 months'].toString(), value: 92},
              {label: intl.messages['1 year'].toString(), value: 365}
            ]}
            defaultOptions
            name="groups"
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>

        <button className={"outline-button d-flex flex-row ml-4"} style={{padding: 0}} onClick={() => {

          showExportFileDialog(intl.messages as any, {
            type: "FILTER",
            source: "meter_readings",
            filter: {
            left: {
              type: "CONDITION",
              name: "ldn",
              value: props.ldn,
            },
            right: {
              type: "CONDITION",
              name: "date_greater",
              value: moment(new Date()).subtract(days, "days").toDate().toISOString(),
            },
            type: "AND"
          }});


          //downloadCsvFile(`/api/v1/reading?csv=true&days=${days}&ldn=${props.ldn}`, `readings_${props.ldn}.csv`)
        }}>
          <p style={{marginTop: 11, marginRight: 6, marginLeft: 8}}>{intl.messages["export-data"].toString()}</p>
          <SaveIcon/>
        </button>
      </div>
    </div>

    <div className={"row mt-4"}>

      <div className={"col-md-6"} key={days}>
        <PaginatedListComponent
          headers={["date", "metrological error", "system error", "rssi", "volume"]}

          fetchCollection={fetchPaginatedCollection<VolumeReading>(`/api/v1/reading?days=${days}&ldn=${props.ldn}`)}
          perPage={6}
          rowBuilder={
            (d) => [
              <p>{(new Date(d.readTime)).toLocaleString()}</p>,
              <p>{formatLogObject(d?.metrologicalError ?? {})}</p>,
              <p>{formatLogObject(d?.systemError ?? {})}</p>,
              <p>{d.rssi}</p>,
              <p>{d.volume}</p>,
            ]
          }
        />
      </div>
      <div className={"col-md-6"}>
        <ResponsiveContainer width={"100%"} height={300} className={"dashboard-chart"}>
          <LineChart data={data} margin={{ top: 16, right: 20, bottom: 5, left: -30 }}>
            <Line type="monotone" dataKey="volume" stroke="#3c4b64" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>

      </div>
    </div>



  </div>);
}
