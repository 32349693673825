import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Group} from "../../models/group";
import {useHistory} from "react-router-dom";
import {ErrorComponent} from '../../components/error_component';
import * as vm from './edit_meter_page_vm';
import {createEditMeter, mkMeterState} from './edit_meter_page_vm';
import {EditableRow} from "../../components/InfoRow";
import {gpsFromGeoJsonPoint} from "../../service/address_from_coordinates";
import {emptyMeter, Meter} from "../../models/meter";
import {VolumeReads} from "../../components/volume_reads/volume_reads";
import _ from "lodash";
import {AltiorMeterType} from "../../models/altior_meter_type";
import {CommandInvocationWidget} from "../../components/command_invocation_widget/command_invocation_widget";
import {
  execMeterCommand,
  fetchMeterCommands
} from "../../components/command_invocation_widget/command_invocation_view_model";
import {Command} from "../../models/configuration";
import {Leafleet} from "../../components/leafleet_map/leafleet_map";
import {fallbackMapCenter} from "../../index";
import {GeoJSONPoint} from "../../models/geo_json";
import {remoteFetchData} from "../../repsitory/generic_repository";
import AsyncSelect from "react-select/async";
import {formatLogObject} from "../../utils/object_formatting";


export function EditMeterPage() {

  const history = useHistory();
  const meter: Meter = (history?.location?.state as Meter) || emptyMeter();

  const [state, setState] = useState<vm.EditMeterPageState>(mkMeterState(meter));

  const intl = useIntl();

  const ref = useRef<HTMLDivElement>(null);

  const [altiorMeterTypes, setAltiorMeterTypes] = useState<AltiorMeterType[]>([]);
  const [deviceTypeSelectValue , setDeviceTypeSelectValue ] = useState<{value: string, label: string}>({value: state.deviceTypeId ?? "", label: "loading"})
  const [commands, setCommands] = useState<Command[]>([]);

  useEffect(() => {
    (async () => {
      vm.fetchMeterTypes().then((mt) => {
        if(typeof mt === "string") {
          setState({...state, error: mt});
        } else {
          setAltiorMeterTypes(mt);

          const deviceTypeId: string = (state.deviceTypeId ?? _.first(mt)?.deviceTypeId ?? "").toString();
          const label = _.first(mt?.filter((mt) => mt.deviceTypeId.toString() === deviceTypeId) ?? [])?.name ?? "Error fetching device types";

          setDeviceTypeSelectValue({ label, value: deviceTypeId})
          setState({...state, deviceTypeId: _.clone(state.deviceTypeId)})
        }
      });
      fetchMeterCommands(meter.id).then(cl => {
        if(typeof cl === "string") return setState({...state, error: cl})
        setCommands(cl);
      })
    })()
  }, [0]);

  if(state.error !== "") return <ErrorComponent message={state.error}/>;
  if(state.loading) return <p>Loading</p>;

  return (
    <div ref={ref}>

      <div className={"row"}>
        <div className={"col-md-9 d-flex flex-column justify-content-between"}>

          <div className={"row"}>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages['meter_id'].toString()} value={state?.id?.toString() || "--"} />
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={"serial"} value= {state.serial} />

            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["rf_config"].toString()}  value={state.rfConfig} />

            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-4"}>
              <EditableRow title={"battery"} value= {`${state.battery.toString()}%`}/>
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["fw_version"].toString()}  value={state.fwVersion} />
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["install_date"].toString()}  value={state.installDate ? (new Date(state.installDate)).toLocaleDateString() : "--"} />
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages['status'].toString()} value={state.status}/>
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["model_type"].toString()} value= {_.first(altiorMeterTypes?.filter((amt) => amt.deviceTypeId.toString() === state.deviceTypeId) ?? [])?.name ?? "--"} />
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages['ldn'].toString()} value={state.ldn} />
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages['valve'].toString()} value={state.valve}  />
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["rf_interface"].toString()} value= {state.rfInterface} />
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["current_key"].toString()}  value={state.currentKey} hidden  />

            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages['metrologicalError'].toString()} value={state.metrologicalError ? formatLogObject(state.metrologicalError) : "--"}  />
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["systemError"].toString()} value= {state.systemError ? formatLogObject(state.systemError) : "--"} />
            </div>

            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["add_date"].toString()} value= {state.addDate ? (new Date(state.addDate)).toLocaleDateString() : "--"}/>
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-8"}>
              <EditableRow title={intl.messages['manufacturer'].toString()} value={state.manufacturer}  onEdit={(s) => setState({...state, manufacturer: s})}/>
            </div>

            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["last_changed"].toString()}  value={state.lastChanged ? (new Date(state.lastChanged)).toLocaleDateString() : "--"} />
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages['initial_reading'].toString()} value={formatInitialReading(state.initialReading)}  onEdit={(s) => setState({...state, initialReading: s})}/>
            </div>
            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["pdr"].toString()}  value={state.pdr} onEdit={(s) => setState({...state, pdr: s})} />
            </div>


            <div className={"col-md-4"}>
              <EditableRow title={intl.messages["contract"].toString()}  value={state.contract} onEdit={(s) => setState({...state, contract: s})}/>
            </div>

          </div>

          <div className={"row"}>
            <div className={"col-md-12"}>
              <EditableRow title={intl.messages['description'].toString()} value={state.description} onEdit={(s) => setState({...state, description: s})}/>
            </div>
          </div>

          {/*<div className={"row"}>*/}
          {/*  <div className={"col-md-12"}>*/}
          {/*    <div className={"mb-2"}>*/}
          {/*      <h3>{intl.messages["device_type_id"].toString().toUpperCase()}</h3>*/}
          {/*      <Select*/}
          {/*        value={ deviceTypeSelectValue }*/}
          {/*        onChange={(g: any) => {*/}
          {/*          setState({...state, deviceTypeId: g.value});*/}
          {/*          setDeviceTypeSelectValue(g);*/}
          {/*        }}*/}
          {/*        isMulti={false}*/}
          {/*        options={altiorMeterTypes?.map((g: any) => ({ value: g.deviceTypeId, label: g.name }))}*/}
          {/*        name="device type id"*/}
          {/*        className="basic-multi-select"*/}
          {/*        classNamePrefix="select"*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className={"row mb-4"}>
            <div className={"col-md-12"}>
              <h3>{intl.messages["send_command"].toString().toUpperCase()}</h3>
              <CommandInvocationWidget
                commands={commands}
                intl={intl.messages as any}
                executor={ execMeterCommand(meter.id) }
              />
            </div>
          </div>

        </div>
        <div className={"col-md-3"}>

          <div className={"row"}>

            <div className={"col-md-12"}>
              <div className={"row"}>
                <div className={"col-md-12 d-flex flex-row"}>
                  <div className={"flex-grow-1"}>
                    <h3>{ intl.messages["lat"].toString().toUpperCase()}</h3>
                    <input
                      type='number'
                      className='form-control mb-2 '
                      value={state?.coordinates?.geometry?.coordinates[1]?.toString() || ""}
                      onChange= {(evt) => {
                        if(state.coordinates) {
                          const coordinates = {
                            ...state.coordinates,
                            geometry: {
                              ...state.coordinates.geometry,
                              coordinates: [state.coordinates.geometry.coordinates[0], Number(evt.target.value)]
                            }
                          };
                          setState({...state, coordinates})
                          // gpsFromGeoJsonPoint(coordinates).then(location => setState({...state, coordinates, location}))
                        }
                      }}
                    />
                  </div>
                  <div className={"flex-grow-1"}>
                    <h3>{ intl.messages["lng"].toString().toUpperCase()}</h3>
                    <input
                      type='number'
                      className='form-control mb-2 flex-grow-1'
                      value={state?.coordinates?.geometry?.coordinates[0]?.toString() || ""}
                      onChange= {(evt) => {
                        if(state.coordinates) {
                          const coordinates = {
                            ...state.coordinates,
                            geometry: {
                              ...state.coordinates.geometry,
                              coordinates: [Number(evt.target.value), state.coordinates.geometry.coordinates[1]]
                            }
                          };
                          setState({...state, coordinates})
                          // gpsFromGeoJsonPoint(coordinates).then(location => setState({...state, coordinates, location}))
                        }
                      }}
                    />
                  </div>
                  <div className={"flex-grow-1"}>
                    <h3>{ intl.messages["height"].toString().toUpperCase()}</h3>
                    <input
                      type='number'
                      className='form-control mb-2 flex-grow-1'
                      value={state?.height?.toString() || "0"}
                      onChange= {(evt) => {
                        setState({...state, height: Number(evt.target.value)})
                      }}
                    />
                  </div>




                </div>
              </div>
            </div>
          </div>

          <div className={"mb-4"}>
            <Leafleet
              key={JSON.stringify(state.coordinates)}
              height={600}
              map_id={"meters-create"}
              editableItem={state.coordinates}

              fallbackMapCenter={fallbackMapCenter}
              onEdit={ async (dot) => {

                if(dot) {
                  setState({...state, coordinates: dot as GeoJSONPoint, location: await gpsFromGeoJsonPoint(dot as GeoJSONPoint)});
                }

              } }/>
          </div>
        </div>
      </div>

      <div className={"row mb-4"}>
        <div className={"col-md-6"}>
          <div className={"mb-2"}>
            <h3>{intl.messages["groups"].toString().toUpperCase()}</h3>
            <AsyncSelect
              defaultValue={state.groups}
              onChange={(g: any) => setState({...state, groups: g})}
              isMulti
              cacheOptions
              defaultOptions
              loadOptions={ (input: string) => {
                return remoteFetchData<Group[]>(`/api/v1/group?name=${input}`).then((gr) => {
                  if(typeof gr !== "string") {
                    return gr.map((g) => ({ value: g.attributes.id, label: g.attributes.name }));
                  } else {
                    setState({...state, error: intl.messages["cannot_fetch_meter_groups"].toString()})
                    return [];
                  }
                })
              } }

              name="groups"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
        <div className={"col-md-6"}>
          <EditableRow title={ intl.messages["location"].toString() } value={state.location} onEdit={(s) => setState({...state, location: s})}/>
        </div>
      </div>


      <VolumeReads meter={meter} ldn={state.ldn} width={ref?.current?.clientWidth ?? 800}/>

      <div className={"row"}>
        <div className={"col-md-12 ellipsis mt-1"}>
          <div className={"d-flex flex-row-reverse"}>
            <button className={"p3 primary-button"}  onClick={() => { createEditMeter(state, setState, history, intl.messages as any) }}>
              {intl.messages['save']}
            </button>
            <button  className={"p3 outline-button mr-3"} onClick={ () => history.goBack() }>
              {intl.messages['cancel']}
            </button>
            <p className={"p-2 mr-5"}>{ state.validationErrors.join(", ") }</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function formatInitialReading(initialReading: string): string {
  try {
    return Number.parseFloat(initialReading).toFixed(2)
  } catch (e) {
    return initialReading;
  }
}