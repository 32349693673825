import {FilterCollection} from "../../../../models/filter";
import {formatValueName} from "../../ast/ast";
import {TrashIcon} from "../../../../components/icons";
import React, {useEffect, useState} from "react";
import {queryReducedValue, reducedValueQueryUrl} from "../../reduce_filter_vm";

export function ReduceNumberFilter(props: {filterCollection: FilterCollection, intl: Record<string, string>, onClose: () => void}) {

  const [value, setValue] = useState("");

  useEffect(() => {
    (async () => {
      setValue(await queryReducedValue(reducedValueQueryUrl(props.filterCollection)))
    })();
  }, [props.filterCollection])

  if(props.filterCollection.reducer?.type !== "FILTER_COLLECTION_REDUCER") return <p>Unsupported Reducer Type</p>

  return <div className={`filter-widget reduce-widget p-05 `} style={{width: 300}}>
    <div className={"d-flex flex-row justify-content-between"} style={{marginRight: -5}}>
      <h3 className={"filter-widget-content"}>
        {`${props.intl[props.filterCollection.reducer!.operator!]} ${props.intl[props.filterCollection.reducer!.column_name!]}`}
      </h3>
      <button className={"icon-button"} onClick={(e) => {
        e.stopPropagation();
        props.onClose();
      }}>
        <TrashIcon/>
      </button>
    </div>
    <h3 className={"reduce-widget-content"}>{
      Number(value).toFixed(2)
    }</h3>
  </div>
}