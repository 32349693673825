import React, {useEffect, useState} from "react";
import {
  createFilterCollection,
  deleteFilter,
  fetchFilterCollections, updateFilterCollection
} from "../../../repsitory/filters_repository";
import {emptyFilterCollection, Filter, FilterCollection} from "../../../models/filter";
import _ from "lodash";
import { useIntl } from "react-intl";
import {confirmAlert} from "react-confirm-alert";
import {showFilterEditSaveDialog} from "./filter_edit_save_dialog";
import {DataSource} from "../ast/ast";
import {getFavourites, removeFavourite, setFavourite} from "./data_explorer_page_header_vm";
import {Star, StarOutline} from "../../../components/icons";

type Props = {
  onCollectionSelected: (c: FilterCollection) => void
  filterCollection: FilterCollection
  fixedDataSource?: DataSource
}

type MeterReadingsPageHeaderState = {
  filterCollections: FilterCollection[],
  activeFilterCollection: FilterCollection,
}

//  if fixed data source is present is returned only if matcher
function emptyState(fixedDataSource?: DataSource): MeterReadingsPageHeaderState {


  const activeCollection: FilterCollection = window.localStorage.getItem("last_filter") ? JSON.parse(window.localStorage.getItem("last_filter")!) : emptyFilterCollection

  if(fixedDataSource && activeCollection.dataSource !== fixedDataSource) {
    return {
      filterCollections: [],
      activeFilterCollection: emptyFilterCollection,
    };
  }

  return {
    filterCollections: [],
    activeFilterCollection: activeCollection,
  };
}

//  if fixed data source is present is returned a filtered subset
async function fetchInitialState(fixedDataSource?: DataSource, defaultSelectedId?: number): Promise<MeterReadingsPageHeaderState | string> {
  let r = await fetchFilterCollections();
  const favorites = getFavourites();

  if(typeof r === "string") return r;

  r = r.filter((fc) => fc.dataSource === (fixedDataSource ?? fc.dataSource));

  if(r.length === 0) return emptyState(fixedDataSource);

  return {
    filterCollections: r,
    activeFilterCollection: _.first(r.sort((a, b) => {
      if(favorites.includes(a.id))
        return -1;
      return 1;
    }).filter((r => r.id === defaultSelectedId))) ?? r[0],
  }

}

function hasChanges(state: MeterReadingsPageHeaderState, editedFilters: FilterCollection) {
  return JSON.stringify(state.activeFilterCollection.filters) !== JSON.stringify(editedFilters.filters)
    || (state.activeFilterCollection.dataSource !== editedFilters.dataSource)
    || (JSON.stringify(state.activeFilterCollection.reducer) !== JSON.stringify(editedFilters.reducer))
    || (state.activeFilterCollection.columnTransform !== editedFilters.columnTransform);
}

export function DataExplorerPageHeader(props: Props) {

  const [state, setState] = useState<MeterReadingsPageHeaderState>(emptyState());
  const [update, setUpdate] = useState(0);
  const intl = useIntl();

  //  ___________INITIALIZATION__________________

  //  Load from storage
  useEffect(() => {
    props.onCollectionSelected(state.activeFilterCollection)
  }, [0])

  useEffect(() => {
    if(props.filterCollection?.filters?.length > 0) {
      window.localStorage.setItem("last_filter", JSON.stringify(props.filterCollection))
    }

  }, [JSON.stringify(props.filterCollection)])

  //  Remote fetch
  useEffect(() => {
    console.log("fetch");
    (async () => {
      const istate = await fetchInitialState(props.fixedDataSource);

      if(typeof istate !== "string") {
        setState(istate);
        props.onCollectionSelected(istate.activeFilterCollection)
        window.localStorage.removeItem("last_filter")
      }
    })();
  }, [update])

  const saveButtonEnabled = hasChanges(state, props?.filterCollection) || (state.filterCollections.length === 0 && window.localStorage.getItem("last_filter"))
  const deleteButtonEnabled = state.filterCollections.length > 0;
  const dropdownVisible = deleteButtonEnabled

  const [favorites, setFavourites] = useState(getFavourites());

  return <div className={"row"}>
    <div className={"col-md-12 d-flex justify-content-between"}>
      <h2>Data Explorer</h2>
      <div className={"d-flex"}>
        { dropdownVisible &&
        <div className="dropdown mr-4">

            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-expanded="false">
              { (state.activeFilterCollection.name || "")}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              { state.filterCollections.sort((a, b) => {
                if(favorites.includes(a.id))
                return -1;
                return 1;
              })
                .map((f: FilterCollection) =>
                <button key={`${f.id}${f.name}`}
                        className={"dropdown-item"}
                        onClick={() => {
                          setState({...state, activeFilterCollection: f});
                          props.onCollectionSelected(_.cloneDeep(f))
                        }}
                >
                  <div className={"d-flex"}>
                    {f.name}
                    <span className={"ml-2 mr-2"}/>
                    {favorites.includes(f.id) && <Star/>}
                  </div>
              </button>) }
            </div>
            <button className={"icon-button"} onClick={
              () => {
                if(favorites.includes(state.activeFilterCollection.id)) {
                  removeFavourite(state.activeFilterCollection)
                } else {
                  setFavourite(state.activeFilterCollection)
                }
                setFavourites(getFavourites());
              }
            }>
              {favorites.includes(state.activeFilterCollection.id) ? <Star/> : <StarOutline/> }
            </button>
        </div>
        }


        { deleteButtonEnabled &&
        <button
            onClick={() => {
              confirmAlert({
                title: intl.messages["confirm"].toString(),
                message: intl.messages["this_action_cant_be_undone"].toString(),
                buttons: [
                  {label: 'Yes',
                    onClick: () => {(async() => {
                      deleteFilter(state.activeFilterCollection);
                      setUpdate(update + 1);
                      window.localStorage.removeItem("last_filter")
                    })()}
                  },
                  {label: 'No', onClick: () => {}}
                ]
              });
            }}
            className={"outline-button"}
        >
          {intl.messages["delete"].toString()}
        </button>
        }


        { saveButtonEnabled &&
        <button
          onClick={() => {
            showFilterEditSaveDialog({
              filterCollection: props.filterCollection,
              intl: intl.messages as any,
              onCreate(f: FilterCollection): void { createFilterCollection(f).then(() => {
                setUpdate(update + 1);
                window.localStorage.removeItem("last_filter")
              }) },
              onUpdate(f: FilterCollection): void { updateFilterCollection(f).then(() => {
                setUpdate(update + 1);
                window.localStorage.removeItem("last_filter")
              }) }
            })
          }}

            className={"primary-button ml-4"}>
          {intl.messages["save"].toString()}
        </button>
        }
      </div>

    </div>
  </div>
}


