import {Meter} from "../../models/meter";
import {FETCH_METERS, MetersAction, SET_METERS, SET_METERS_ERROR} from "../actions/meters_actions";

const initialState = {
  meters: Array<Meter>(),
  error: "",
  loading: false
};

export default function(state = initialState, action: MetersAction) {
  switch (action.type) {
    case SET_METERS: {
      return {
        meters: action.meters,
        error: "",
        loading: false
      };
    }
    case SET_METERS_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    }
    case FETCH_METERS: {
      return {
        ...state,
        loading: true
      }
    }
    default:
      return state;
  }
}
