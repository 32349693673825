import React from "react";

export function AddReduceFilterWidget(props: {onTap: () => void, text: String, className?: string}) {
  return <div className={`and-button-item`} onClick={props.onTap} >
    <button className={"icon-button"} onClick={(e) => {
      e.stopPropagation();
      props.onTap();
    }}>
      <h2 className={"filter-widget-content"}>{props.text.toUpperCase()}</h2>
    </button>
  </div>
}
