import {GeoJSONPoint} from "./geo_json";
import {Group} from "./group";

export interface Attributes {
  height?: number;
  volume: number;
  updatedAt: Date;
  state: string;
  serialNumber?: string;
  contract: string;
  description: string;
  currentKey: string;
  fwVersion: string;
  initialReading: string;
  rfConfig: string;
  rfInterface: string;
  valve: string;
  rssi?: number;
  pdr: string;
  meterTypeId?: any;
  manufacturer: string;
  longitude: number;
  ldn: string;
  coordinates?: GeoJSONPoint
  latitude: number;
  lastSeen: Date;
  lastCommission?: Date;
  insertedAt: Date;
  id?: number;
  metrologicalError: any,
  systemError: any,
  deviceTypeId: string;
  battery?: number;
  address: string;
  activeKeys: string;
  active: string;
}

export interface Meter {
  type: string;
  id: string;
  attributes: Attributes;
  groups: Group[];
}

export const emptyMeter: () => Meter = () => ({
  type: "meter",
  id: "",
  groups: [],
  attributes: {
    volume: 0,
    updatedAt: new Date(),
    contract: "",
    valve: "",
    state: "",
    serialNumber: "",
    pdr: "",
    meterTypeId: "",
    manufacturer: "",
    longitude:  11.866667,
    ldn: "",
    latitude: 45.416668,
    lastSeen: new Date(),
    lastCommission:new Date(),
    insertedAt: new Date(),
    metrologicalError: {},
    systemError: {},
    deviceTypeId: "",
    address: "",
    activeKeys: "",
    active: "",
    description: "",
    currentKey: "",
    fwVersion: "",
    initialReading: "",
    rfConfig: "",
    rfInterface: "",
  }
});