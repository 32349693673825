import {FilterWidget} from "./filter_widget";
import {AddFilterWidget} from "./add_filter_widget";
import _ from "lodash";
import React from "react";
import {Filter} from "../../../models/filter";

export function FiltersOrWidget(props: {
  filters: Filter[],
  intl: Record<string, string>
  onClose: (f: Filter, index: number) => void,
  onClick: (f:Filter) => void,
  onAddSibling: (name: string) => void
}) {
  return <div >
    <div className={"d-flex flex-row position-relative"}>
      <div className={"filter-widget-or-group"}>
        {props.filters.map((f: Filter, i) => <FilterWidget
          key={f.name}
          filter={f} onClose={() => props.onClose(f, i)}
          onClick={(f) => console.log("f")}
          intl={props.intl}
          className={ i !== 2
            ? "bottom-line"
            : "" }
        />)}

      </div>
    </div>
    { props.filters.length >= 1 && props.filters.length < 3 &&
    <AddFilterWidget
        onAdd={() => props.onAddSibling(_.last(props.filters)!.name)}
        margin_top={false}
        text={"OR"}
    />
    }
  </div>
}