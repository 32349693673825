import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Gateway} from "../../models/gateway";
import {PaginatedListComponent} from "../../components/paginated_list/paginated_list_component";
import {fetchPaginatedCollection} from "../../repsitory/generic_repository";
import {LogPanel} from "../../components/log_panel";
import {Divider} from "../../components/divider";
import {fallbackMapCenter, history} from "../../index";
import {confirmAlert} from "react-confirm-alert";
import {LocateIcon, TrashIcon} from "../../components/icons";
import {deleteGateway} from "./edit_gateway_page_vm";
import {ErrorComponent} from "../../components/error_component";
import {Log} from "../../models/log";
import {Leafleet} from "../../components/leafleet_map/leafleet_map";
import {fetchMetersCluster} from "../../repsitory/meter_cluster_repository";
import _ from "lodash";
import {Popup} from "react-leaflet";
import {GeoJSONPoint} from "../../models/geo_json";

export function GatewaysPage() {
  const intl = useIntl();
  const [initialPage, setInitialPage] = useState(0);
  const [error, setError] = useState("");
  const [gateways, setGateways] = useState<Gateway[]>([]);
  const [mapCenter, setMapCenter] = useState<GeoJSONPoint | undefined>(undefined);

  return (
    <div>
      <div className={"row mb-4"}>
        <div className={"col-md-12"}>
          <Leafleet
            height={350}
            map_id={"gateways_index"}
            fallbackMapCenter={fallbackMapCenter}
            mapCenter={mapCenter}
            fetchClusters={fetchMetersCluster}
            staticMapItems={
              gateways
                .filter(g => (g?.coordinates ?? null) != null)
                .map(g => ({feature: {...g.coordinates!, properties: {...g.coordinates!.properties, markerColor: "#666666"}}, popUp: (_) => <Popup>
                    <button className={"flat-button"} onClick={() => history.push(`/gateways/${g.identifier}/edit`, g)}>
                      <div className={"row"} style={{textAlign: "left"}}>
                        <div className={"col-md-12"}>{g.serial}</div>
                        <div className={"col-md-12"}>{g.address}</div>
                        <div className={"col-md-12"}>{g.identifier}</div>
                      </div>
                    </button>


                  </Popup>}))
            }
          />
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12 ellipsis"}>
          <PaginatedListComponent
            headers={
            ["id", "name", "status", "serial", "position", "last seen", "actions"]
            }
            initialPage={initialPage}
            rowBuilder={
            (g: Gateway) => [
              <p>{g.identifier}</p>,
              <p>{g.name}</p>,
              <p>{g.status}</p>,
              <p>{g.serial}</p>,
              <p>{g.address}</p>,
              <p>{g.updatedAt}</p>,
              <div className={"d-flex"}>
                <button className={"icon-button"} title={intl.messages["locate"].toString()} onClick={(e) => {
                  e.stopPropagation();
                  setMapCenter(g.coordinates ?? undefined);
                }}>
                  <LocateIcon/>
                </button>

                <button className={"icon-button"} onClick={(e) => {
                  e.stopPropagation();
                  confirmAlert({
                    title: intl.messages["confirm"].toString(),
                    message: intl.messages["this_action_cant_be_undone"].toString(),
                    buttons: [
                      {
                        label: 'Yes',
                        onClick: async () => {
                          setError(await deleteGateway(g.id!.toString()));
                          setInitialPage(0);
                          setInitialPage(1);
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {}
                      }
                    ]
                  });

                }}>
                  <TrashIcon/>
                </button>
              </div>

            ]
          }
          fetchCollection={
            fetchPaginatedCollection<Gateway>("/api/v1/gateway", (gateways: Gateway[]) => {setGateways(gateways)})
          }
          perPage={8}
          onTap={ (g) => {
            history.push(`/gateways/${g.identifier}/edit`, g);
          } }
          />
        </div>
      </div>
      {error !== "" && <ErrorComponent message={error}/>}
      <div className={"row"}>
        <div className={"col-md-12 d-flex flex-row-reverse"}>
          <button
            className={"primary-button"}
            onClick={() => {
              history.push(`/gateways/new`);
            }}
          >
            {intl.messages["add_gateway"].toString()}
          </button>
        </div>
      </div>
      <Divider/>
      <div className={"row"}>
        <div className={"col-md-12"}>
          <LogPanel
          filter={(l: Log) => l.channel === "altior_messages_channel"}/>
        </div>
      </div>

    </div>


  );
}