export type JobSchedule = JobCron | JobOnce;

export type JobCron = {
  type: "cron"
  cron: string
}

export type JobOnce = {
  type: "job"
  date: Date
}

export function mkJobSchedule(type: "cron" | "job"): JobSchedule {
  switch (type) {
    case "cron": return {type, cron: ""}
    case "job": return {type, date: new Date()}
  }
}

export function validateJobSchedule(js: JobSchedule, messages: Record<string, string>): string[] {
  let errors: string[] = [];
  switch (js.type) {
    case "cron":
      if(!isCronValid(js.cron)) errors = [ messages["cron_expression_not_valid"], ...errors]
      break;
    case "job":
      break;
  }

  return errors;
}


function isCronValid(cron: string): boolean {
  const cronregex = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);
  return cronregex.test(cron);
}