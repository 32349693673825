import React, {Dispatch} from "react";
import {httpDelete} from "../../repsitory/generic_repository";
import * as H from "history";
import {seacrhMeter} from "./edit_meter_page_vm";
import {Log} from "../../models/log";
import _ from "lodash";


export type MetersPageState = {
  loading: boolean,
  query: string,
  error: string,
}


export const destroyGroup = async(id: number, dispatch: Dispatch<any>, state: MetersPageState, setState: React.Dispatch<React.SetStateAction<MetersPageState>>, intl: Record<string, string>) =>  {
  try {
    console.log("delete g");
    const result = await httpDelete('/api/v1/group/' + id.toString());

    if(result.status !== 204) {
      setState({...state, error: intl["cannot_delete_group"]})
    }
    // console.log(result);
  } catch (e) {
    console.log(e);
  }
};

export const searchMeter = async(state: MetersPageState, setState: React.Dispatch<React.SetStateAction<MetersPageState>>, history: H.History, intl: Record<string, string>) => {
  setState({...state, loading: true});

  const response = await seacrhMeter(state.query).catch((e) => e.toString());

  if(typeof response === "string") {
    setState({...state, loading: false, error: response});
  } else if(typeof response === "undefined") {
    setState({...state, loading: false, error: intl["nothing-found"]});
  } else {
    setState({...state, loading: false, error: ""});
    history.push(`/meters/${response.attributes.ldn}/edit`, response)
  }

};

export function getLastValue(logs: Log[], eventName:
  | "meter_count"
  | "meter_in_error"
  | "meter_installed"
  | "meter_managed"
  | "gateway_alarmed"
  | "gateway_offline"
  | "gateway_installed"
): string {
  const value = _.first(logs.filter((l) => l.eventName === eventName))?.log?.values[0][0]?.toString();
  try {
    if(value) window.sessionStorage.setItem(`${eventName}ad`, value)

    return value ?? window?.sessionStorage?.getItem(`${eventName}ad`) ?? "--"
  } catch (e) {
    return "--";
  }

}


export function getDefaultBlacklistedGroups(): string[] {
  try{return JSON.parse(window.localStorage.getItem("blackListedGroups")!)} catch (e) { return [] }
}

export function setDefaultBlacklistedGroups(g: string[]) {
  try{window.localStorage.setItem("blackListedGroups", JSON.stringify(g))} catch (e) { console.log(e); }
}