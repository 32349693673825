import {remoteFetchData} from "../../repsitory/generic_repository";

export const fetchChartVolumeRead = async (ldn: string, days: number) =>  {
  const data = await remoteFetchData(`/api/v1/chart_reading?ldn=${ldn}&days=${days}&limit=30`) as any;

  // @ts-ignore
  return data.map(d => ({
    date: `${new Date(d.attributes.readTime).toLocaleDateString()} ${new Date(d.attributes.readTime).toLocaleTimeString()}`,
    volume: d.attributes.volume,
    rssi: d.attributes.rssi,
    metrologicalError: d.attributes.metrologicalError ?? {},
    systemError: d.attributes.systemError ?? {}
  }));
};

// export const fetchVolumeRead = async (ldn: string, days: number) =>  {
//   const data = await remoteFetchData(`/api/v1/reading?ldn=${ldn}&days=${days}`) as any;
//
//   // @ts-ignore
//   return data.map(d => ({
//     date: `${new Date(d.attributes.readTime).toLocaleDateString()} ${new Date(d.attributes.readTime).toLocaleTimeString()}`,
//     volume: d.attributes.volume,
//     rssi: d.attributes.rssi,
//     metrologicalError: d.attributes.metrologicalError ?? {},
//     systemError: d.attributes.systemError ?? {}
//   }));
// };