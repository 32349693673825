import {GatewayConfig, GatewayHardwareConfig,} from "../../../models/gateway_config";
import {HardwareType} from "../../../models/hardware_type";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {IndexTable} from "../../../components/index_table";
import {showConfigurationEditor} from "../../../components/configuration_editor/configuration_editor";
import {gatewayHardwareConfigFromHardwareType, hardwareTypeFromGatewayHardwareConfig} from "./gateway_config_vm";
import {
  configToConfigurationValues,
  configurationValuesToConfig
} from "../../../components/configuration_editor/configuration_editor_vm";
import {ConfigurationValue} from "../../../models/configuration";
import {confirmAlert} from "react-confirm-alert";
import {TrashIcon} from "../../../components/icons";
import Select from "react-select";
import _ from "lodash";

type Props = {
  config: GatewayConfig,
  hardwareTypes: HardwareType[],
  onConfigurationChange: (gc: GatewayConfig) => void
}

export function GatewayHardwareConfigPanel(props: Props) {

  const intl = useIntl();
  const [creationMode, setCreationMode] = useState(false);

  return <div className={"col-md-6"}>
      <h3>{intl.messages["hw_config"].toString().toUpperCase()}</h3>
      <div className={"log-panel ellipsis mr-2"}>
        <IndexTable
          headers={['Port', 'Name', 'Version', 'Actions']}
          columns={props.config.hardware ?? []}
          paginationVisible={false}
          onTap={(hc: GatewayHardwareConfig) => {
            showConfigurationEditor({
              configurationSpecs: hardwareTypeFromGatewayHardwareConfig(hc, props.hardwareTypes)?.attributes.configurations ?? [],
              configurationValues: configToConfigurationValues(hc.configurations),
              onClose(): void {},
              intl: intl.messages as any,
              onSave(c: ConfigurationValue[]): void {

                const newHardwareConfig = {...hc, configurations: configurationValuesToConfig(c)}

                props.onConfigurationChange({
                  ...props.config,
                  hardware: [
                    ...(props?.config?.hardware?.filter((ghc) => JSON.stringify(ghc) !== JSON.stringify(hc)) ?? []),
                    newHardwareConfig
                  ]
                })
              },
              title: hc.name
            })
          }}
          rowBuilder={
            (h: GatewayHardwareConfig) => [
              <p> {h?.configurations?.port ?? ""} </p>,
              <p> {h.name} </p>,
              <p> {h.version} </p>,
              <button className={"icon-button"} onClick={(e) => {
                e.stopPropagation();
                confirmAlert({
                  title: intl.messages["confirm"].toString(),
                  message: intl.messages["this_action_cant_be_undone"].toString(),
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: async () => {
                        props.onConfigurationChange({
                          ...props.config,
                          hardware: props.config.hardware.filter((hc) => JSON.stringify(hc) !== JSON.stringify(h))
                        })
                      }
                    },
                    {
                      label: 'No',
                      onClick: () => {}
                    }
                  ]
                });

              }}>
                <TrashIcon/>
              </button>
            ]
          }
        />
        { creationMode &&
        <div className={"mb-5 mr-2"}>
            <h3>{intl.messages["select_config_type"].toString().toUpperCase()}</h3>
            <Select
                value={ {label: "", value: ""} }
                onChange={(g: any) => {
                  const type: HardwareType | undefined = _.first(props.hardwareTypes.filter((ht) => ht.id === g.value));
                  setCreationMode(false);
                  if(type) {
                    props.onConfigurationChange({
                      ...props.config,
                      hardware: [
                        ...(props?.config?.hardware ?? []),
                        gatewayHardwareConfigFromHardwareType(type)
                      ]
                    });
                  }
                }}
                isMulti={false}
                options={ props.hardwareTypes.map((ht) => ({ label: ht.attributes.name, value: ht.id })) }
                name="device type id"
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
        }

        <div className={"d-flex flex-row-reverse"}>
          {
            creationMode
              ? <button className={"p3 primary-button mr-2 mt-5"}  onClick={() => { setCreationMode(false) }}>
                {intl.messages['cancel']}
              </button>
              : <button className={"p3 primary-button mr-2"}  onClick={() => { setCreationMode(true) }}>
                {intl.messages['add']}
              </button>
          }
        </div>

      </div>
    </div>

}