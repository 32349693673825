import {ReactNode} from "react";

export type GeoJSONPolygon = {
  "type": "Feature",
  "properties": {
    color?: string;
  },
  "geometry": {
    "type": "Polygon",
    "coordinates": number[][][]
  }
}

export type GeoJSONPoint = {
  "type": "Feature",
  "properties": {
    "color"?: string,
    "markerColor"?: string
    "cluster"?: boolean
    "itemCount"?: number
    "rssi"?: number
    "radius"?: number
    "meter"?: boolean
  },
  "geometry": {
    "type": "Point",
    "coordinates": number[]
  }
};

export function geoJsonPointFromCoordinates(coordinates:{ lat: number, lng: number} | null): GeoJSONPoint | null {
  if(coordinates === null) return null;
  return {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": [coordinates.lng, coordinates.lat]
    }
  }
}

export type GeoJSONFeature = GeoJSONPoint | GeoJSONPolygon;

export type ViewPort = {
  zoom: number,
  northEast: {lat: number, lng: number},
  southWest: {lat: number, lng: number}
}

export type MapItem = {
  feature: GeoJSONFeature,
  popUp: (f: GeoJSONFeature) => ReactNode | null
}