import React, {ReactNode} from 'react';
import MenuItemType from "../models/menu_item_type";
import {Sidebar} from "./sidebar";
import {Header} from "./header";

type Props = {
  children: ReactNode,
  selectedItem: MenuItemType
};


export function Layout(props: Props) {
  return (
    <div className={"c-app"}>
      <div className={"app-body"}>
        <Sidebar selectedItem={props.selectedItem}/>
        <div className={"c-wrapper"}>
          <Header/>
          <div className={"c-body"}>
            <main className={"c-main"}>
              <div className={"main-container"} id={"main-container"} key={"main-container"}>
                  {props.children}
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};
