import {Meter} from "../../models/meter";

export const FETCH_METERS = 'FETCH_METERS';
export const SET_METERS = 'SET_METERS';
export const SET_METERS_ERROR = 'SET_METERS_ERROR';

export interface MetersAction {
  type: string,
  meters?: Meter[]
  error?: string
}

export const fetchMetersAction = () => ({
  type: FETCH_METERS,
});

export const setMetersAction = (meters: Meter[]) => ({
  type: SET_METERS,
  meters
});

export const setMetersErrorAction = (error: string) => ({
  type: SET_METERS_ERROR,
  error
});