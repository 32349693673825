import {confirmAlert} from "react-confirm-alert";
import React, {useState} from "react";


export async function showDBSetupDialog(onDone: () => Promise<any>, intl: Record<string, string>) {

  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({onClose}) =>
      <DBSetupDialog
        onDone={ async() => {
          if(onDone !== null) {
            const r = await onDone();
            onClose();
            return r;
          } else {
            onClose();
          }
        }}
        intl = {intl}
        close={onClose}
      />
  });
}

export async function showSuccess(intl: Record<string, string>) {

  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({onClose}) =>
      <div
        style={{minHeight: 150, backgroundColor: "white"}}
        className={"configuration-editor p-3 pt-4"}
      >
        <div className={"row "}>
          <div className={"col-md-12"}>
            <h3 className={"dashboard-editor-title"}>{intl["db-success"].toUpperCase()}</h3>
          </div>

        </div>
        <div className={"row mt-4"}>
          <div className={"col-md-12 d-flex flex-row-reverse"}>
            <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
                onClose();
            }}>{intl["ok"]}</button>

          </div>
        </div>

      </div>
  });
}

function DBSetupDialog(props: {onDone: () => Promise<any>, intl: Record<string, string>, close: () => void}, ) {
  const [error, setError] = useState("");
  return <div
    style={{minHeight: 150, backgroundColor: "white"}}
    className={"configuration-editor p-3 pt-4"}
  >
    <div className={"row "}>
      <div className={"col-md-12"}>
        <h3 className={"dashboard-editor-title"}>{props.intl["db-is-not-set-up"].toUpperCase()}</h3>

      </div>

    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          const response = await props.onDone();

          if(response.status !== 204) return setError(props.intl["cannot-setup-db"])
          showSuccess(props.intl);

        }}>{props.intl["setup-db"]}</button>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.close()
        }}>{props.intl["cancel"]}</button>
        <p className={"error-message"}>{error}</p>
      </div>
    </div>

  </div>

}