import {AuthAction} from "../actions/auth_actions";

export type AuthState = {
  token: string | null
}

export default function(state: AuthState = { token: window.sessionStorage.getItem("swarm-token") }, action: AuthAction): AuthState {
  switch (action.type) {
    case "SET_TOKEN":       return { token: action.token };
    case "DELETE_TOKEN":    return { token: null };
    default:                return state;
  }
}
