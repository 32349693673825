import {FilterCollection} from "../../../../models/filter";
import React from "react";
import {ReduceNumberFilter} from "./reduce_number_filter";
import {ReduceChartFilter} from "./reduce_chart_widget";

export function ReduceFilter(props: {filterCollection: FilterCollection, intl: Record<string, string>, onClose: () => void}) {
  switch (props.filterCollection.reducer?.type) {
    case "FILTER_COLLECTION_REDUCER":
      return <ReduceNumberFilter filterCollection={props.filterCollection} intl={props.intl} onClose={props.onClose}/>
    case "FILTER_COLLECTION_CHART_REDUCER":
      return <ReduceChartFilter filterCollection={props.filterCollection} intl={props.intl} onClose={props.onClose}/>
  }
  return <p>Unsupported reducer type</p>
}