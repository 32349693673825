import {PaginatedListComponent} from "./paginated_list_component";
import {PaginatedList} from "./paginated_sublist";
import React, {ReactNode, useEffect, useState} from "react";
import {permute, removeIds} from "../../utils/arrays";
import {formatObject} from "../../utils/object_formatting";

type Props<T> = {
  onTap?: (t: T) => void,
  perPage?: number,
  fetchCollection : (selectedPage: number, perPage: number) => Promise<PaginatedList<T> | string>
  initialPage?: number,
  showPaginationElement?: boolean;
  columnPermutations?: number[]
  visibleColumns?: boolean[]
  onHeaders?: (headers: string[]) => void
}


export function DynamicPaginatedListComponent<T>(props: Props<T>) {

  const [headers, setHeaders] = useState<string[]>([]);
  useEffect(() => props.onHeaders && props.onHeaders(headers), [headers, props.onHeaders]);

  let permutation = props.columnPermutations ?? headers.map((_, i) => i);
  if(permutation.length !== headers.length) permutation = headers.map((_, i) => i);

  let hiddenColumns = props.visibleColumns?.map((v, i) => {
    return { v, i: permutation.indexOf(i) }
  }).filter(({v}) => !v).map(({i}) => i);



  return <PaginatedListComponent
    headers={removeIds(permute(headers, permutation), hiddenColumns ?? [])}
    rowBuilder={(item: any) => {
      const newHeaders = Object.keys(item);
      if(JSON.stringify(headers) !== JSON.stringify(newHeaders)) setHeaders(newHeaders);


      const map = removeIds(permute(Object.values(item).map((v: any) => <p>{formatObject(v)}</p>), permutation), hiddenColumns ?? []);
      return map;
    }}
    onTap={props.onTap}
    perPage={props.perPage}
    fetchCollection={props.fetchCollection}
    initialPage={props.initialPage}
    showPaginationElement={props.showPaginationElement}
  />

}

export function formatHeader(header: string) {
  return header.replace("_", " ").toUpperCase();
}

