import {SET_LANG} from "../actions/i18n_actions";


const initialState = {
  lang: 'en',
};

export default function(state = initialState, action: {type: string, lang: string}) {
  switch (action.type) {
    case SET_LANG: {
      return {
        lang: action.lang
      };
    }
    default:
      return state;
  }
}
