import {Popup} from "react-leaflet";
import React from "react";
import {IndexTable} from "../index_table";
import {Meter} from "../../models/meter";
import {history} from "../../index";
import {fetchMeter} from "../../repsitory/meter_repository";

export function ClusterPopup(props: {clusterId: number, meters: Meter[]}) {
  return <Popup>
    <div className={"row"}>
      <div className={"col-md-12"}>

        <IndexTable headers={["id", "rssi"]} columns={props.meters} rowBuilder={
          (r: Meter) => [
            <p>{r.attributes.id}</p>,
            <p>{r.attributes.rssi ?? ''}</p>
          ]
        }
        onTap={(meter: Meter) => {
          fetchMeter(meter.attributes.id!).then((m) => {
            if(typeof m !== "string") history.push(`/meters/${m.attributes.ldn}/edit`, m);
          })

        }}/>
      </div>
    </div>
  </Popup>
}