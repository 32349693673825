import {confirmAlert} from "react-confirm-alert";
import React, {useState} from "react";
import Select from "react-select";
import _ from "lodash";

import {
  DataSource,
  formatDataSource,
  dataSources,
  columnNamesPerDatasource,
  ColumnName,
  ReducerOperator, reducerOperators
} from "../ast/ast";
import {FilterCollectionReducer} from "../../../models/filter";

export async function showDataReducerCreation(intl: Record<string, string>, dataSource: DataSource, onCreate: (filterReducer: FilterCollectionReducer) => void, only?: string) {

  confirmAlert({
    customUI: ({onClose}) =>
      <div
        style={{minHeight: 150, backgroundColor: "white"}}
        className={"configuration-editor p-3 pt-4"}
      >
        <CreateDataReducerWidget intl={intl} dataSource={dataSource} onCreate={(f) => {
          onCreate(f);
          onClose();
        }} only={only} close={onClose}/>
      </div>
  });
}

function CreateDataReducerWidget(props: {intl: Record<string, string>, dataSource: DataSource, onCreate: (filterReducer: FilterCollectionReducer) => void, only?: string, close: () => void}, ) {

  const allowedColumns = columnNamesPerDatasource[props.dataSource!]
  const [selectedColumnName, setSelectedColumnName] = useState<ColumnName>(_.first(allowedColumns)!)
  const [selectedOperator, setSelectedOperator] = useState<ReducerOperator>(_.first(reducerOperators)!)

  return <div>
    <div className={"row "}>
      <div className={"col-md-12 mb-4"}>
        <h3>{props.intl["column_name"].toString().toUpperCase()}</h3>
        <Select
          value={ {label: props.intl[selectedColumnName]!, value: selectedColumnName.toString()} }
          onChange={(t: any) => setSelectedColumnName(t.value)}
          isMulti={false}
          options={allowedColumns.map((ds => ({value: ds, label: props.intl[ds]})))}
          name="device type id"
          className="basic-multi-select mb-4"
          classNamePrefix="select"
        />
      </div>
      <div className={"col-md-12 mb-4"}>
        <h3>{props.intl["operator_name"].toString().toUpperCase()}</h3>
        <Select
          value={ {label: props.intl[selectedOperator]!, value: selectedOperator.toString()} }
          onChange={(t: any) => setSelectedOperator(t.value)}
          isMulti={false}
          options={reducerOperators.map((ds => ({value: ds, label: props.intl[ds]})))}
          name="device type id"
          className="basic-multi-select mb-4"
          classNamePrefix="select"
        />
      </div>
    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.onCreate({column_name: selectedColumnName, operator: selectedOperator, type: "FILTER_COLLECTION_REDUCER"});
        }}>{props.intl["ok"]}</button>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.close()
        }}>{props.intl["close"]}</button>
      </div>
    </div>
  </div>
}
