import React, {useState} from 'react';
import {useDispatch,} from "react-redux";
import {useIntl} from 'react-intl';
import {IndexTable} from "../../components/index_table";
import {emptyGroup, Group} from "../../models/group";
import {InputText} from "../../components/input_text";
import {FilterIcon, TrashIcon} from "../../components/icons";
import {Link, useHistory} from "react-router-dom";
import {ErrorComponent} from '../../components/error_component';
import * as vm from './edit_group_page_vm';
import {configToProperties, propertiesToConfig} from './edit_group_page_vm';
import {Leafleet} from "../../components/leafleet_map/leafleet_map";
import {fallbackMapCenter} from "../../index";
import {GeoJSONPolygon} from "../../models/geo_json";
import CirclePicker from 'react-color/lib/components/circle/Circle';
import {ClusterResponse} from "../../models/cluster_response";
import {fetchMeterGroupsCluster, fetchMetersCluster} from "../../repsitory/meter_cluster_repository";


export function EditGroupPage() {

  const history = useHistory();
  const group: Group = (history?.location?.state as Group) || emptyGroup();

  const [state, setState] = useState<vm.EditGroupPageState>({
    name: group.attributes.name,
    totalMeters: group.attributes.metersCount || 0,
    polygon: [],
    loading: false,
    error: "",
    validationErrors: [],
    id: group.attributes.id,
    geom: group.attributes.geom ?? null,
    color: group.attributes.geom?.properties?.color ?? "#3C4B64",
    tags: group.attributes.tags
  });

  const [newTag, setNewTag] = useState<vm.Property>({name: "", value: ""});

  const [clusters, setClusters] = useState<ClusterResponse>({
    attributes: {clusters: [], meters: []},
    id: "",
    type: "cluster"
  })

  const dispatch = useDispatch();


  const intl = useIntl();
  const mapHeight = 500;

  if(state.error !== "") return <ErrorComponent message={state.error}/>;
  if(state.loading) return <p>Loading</p>;

  return (
    <div>
      <InputText
        hint={ intl.messages["group_name"].toString() }
        onChange={ (name) => setState({...state, name}) }
        title={intl.messages['name'].toString().toUpperCase()}
        value={state.name}
      />
      {/*Left column*/}
      <div className={"row  mt-5"}>
        <div className={"col-md-3"}>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <h3>{ intl.messages["total_meters"].toString().toUpperCase() }</h3>
            </div>
            <div className={"col-md-6"}>
              <p>{ state.totalMeters.toString() }</p>
            </div>
          </div>
          <div className={"row mt-4"}>
            <div className={"col-md-12"}>
              <h3>{ intl.messages["define_a_polygon"].toString().toUpperCase() }</h3>
              <p>{ intl.messages["define_a_polygon_description"].toString() }</p>
            </div>
          </div>
          <div className={"row mt-3"}>
            <div className={"col-md-12"}>
              <div className={"d-flex flex-row-reverse"}>
                <button className={"p3 outline-button"} onClick={() => document.getElementsByClassName("leaflet-draw-draw-polygon")[0].dispatchEvent(new Event('click'))}>
                  { intl.messages['select_area'] }
                </button>
              </div>
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-12 mt-4"}>
              <h3>{ intl.messages["tags"].toString().toUpperCase() } <FilterIcon/></h3>
            </div>
          </div>
          <IndexTable
            headers={
              ["PROPERTY", "VALUE", ""]
            } columns={
            configToProperties(state.tags)
          } rowBuilder={
            (p: vm.Property) => [
              <p>{p.name}</p>,
              <p>{p.value}</p>,
              <button className={"icon-button"} onClick={() => setState({...state, tags: propertiesToConfig( configToProperties(state.tags).filter((pr) => pr.name !== p.name)) })}><TrashIcon/></button>
            ]
          }
            paginationVisible={false}
          />
          <div className={"row"}>
            <div className={"col-md-5"}>
              <InputText hint={"Property"} value={newTag.name} onChange={ name => setNewTag({...newTag, name}) }/>
            </div>
            <div className={"col-md-7"}>
              <InputText hint={"Value"} value={newTag.value} onChange={ value => setNewTag({...newTag, value}) }/>
            </div>
          </div>
          <div style={ {width:"100%", backgroundColor:"gray", height:"1px",  marginBottom: "1rem"} }/>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <div className={"d-flex flex-row-reverse"}>
                <button className={"p3 outline-button"} onClick={() => {
                  setState({...state, tags: propertiesToConfig([...configToProperties(state.tags), {name: newTag.name, value: newTag.value}]) });
                  setNewTag({name: "", value: ""});
                }}>
                  { intl.messages['add_tag'] }
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*Right column*/}
        <div className={"col-md-9"}>
          <Leafleet
            key={JSON.stringify(state.geom)}
            height={mapHeight}
            map_id={"edit_group"}
            fallbackMapCenter={fallbackMapCenter}
            editableItem={state.geom ?? undefined}
            fetchClusters={state.id ? fetchMeterGroupsCluster(state.id) : fetchMetersCluster}
            onEdit={(g) => setState({...state, geom: {...(g as GeoJSONPolygon), properties: {color: state.color}}})}
          />
        </div>
      </div>
      <div className={"row mt-3 justify-content-end"}>
        <div className={"col-md-9 ellipsis mt-1"}>
          <div className={"d-flex flex-row-reverse"}>
            <button className={"p3 primary-button"}  onClick={() => { vm.createEditGroup(state, setState, dispatch, history); }}>
              {intl.messages['save']}
            </button>
            <Link to={ "/meters" } style={{ textDecoration: 'none', color: '#fff' }}>
              <div className={"p3 outline-button mr-3"}>
                {intl.messages['cancel']}
              </div>
            </Link>
            <p className={"p-2 mr-5"}>{ state.validationErrors.join(", ") }</p>
            <CirclePicker
              circleSize={24}
              color={state.color}
              width={"100%"}
              onChange={(c) => {
              let geom = state.geom;
              if(geom) {
                geom = {...geom, properties: {color: c.hex}}
              }
              setState({
                ...state,
                color: c.hex,
                geom
              });
            }}/>
          </div>
        </div>
      </div>
    </div>
  );
}
