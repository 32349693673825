import {Dashboard} from "../../../models/dashboard/dashboard";
import {confirmAlert} from "react-confirm-alert";
import React, {useEffect} from "react";
import {IntlShape, useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {Dispatch} from "redux";
import {InputText} from "../../../components/input_text";
import store from "../../../store/store";
import {fetchDashboardsAction} from "../../../store/actions/dashboard_actions";
import {fetchStatsConfigAction} from "../../../store/actions/stat_config_actions";
import {subscribeSocket} from "../../../store/effects/log_effects";

type Props = {
  dashboards: Dashboard[],
  dashboard: Dashboard | null,
  setDashboard: React.Dispatch<React.SetStateAction<Dashboard | null>>,
  editable: boolean,
  setEditable: React.Dispatch<React.SetStateAction<boolean>>
}

export function DashboardsIndexHeader(props: Props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const dropdownVisible = !props.editable && props.dashboards.length > 0;
  const buttonsVisible = props.dashboards.length > 0;
  const editableRowVisible = props.editable;

  useEffect(()=> {
    store.dispatch(fetchDashboardsAction());
    store.dispatch(fetchStatsConfigAction());
    subscribeSocket(store);
  }, [0])

  return (<div className={"row"}>
    <div className={"col-md-12 pt-1 d-flex flex-row justify-content-between"}>
      <h3>{intl.messages["dashboards"].toString().toUpperCase()}</h3>

      <div className={"d-flex flex-row"}>
        {props.dashboard?.error && <p className={"dashboard-error-message"}>{props.dashboard?.error}</p> }

        { dropdownVisible &&
        <div className="dropdown mr-4">
            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-expanded="false">
              { (props.dashboard?.name || "")}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              { props.dashboards.map((d: Dashboard) => <button key={`${d.id}${d.name}` } className={"dropdown-item"} onClick={() => props.setDashboard(d)}>
                {d.name}
              </button>) }
            </div>
        </div>
        }

        { editableRowVisible &&
          <InputText
              value={props.dashboard?.name ?? "--"}
              className={"mr-4"}
              onChange={ (dashboardName) => {
                if(props.dashboard) {
                  props.setDashboard({...props.dashboard, name: dashboardName});
                }
              }}
          />
        }

        { buttonsVisible &&
        <EditDeleteButtons dispatch={dispatch} editable={props.editable} setEditable={props.setEditable} intl={intl} dashboard={props.dashboard}/>
        }

      </div>
    </div>






  </div>)

}

type EditDeleteButtonsProps = {
  dispatch: Dispatch<any>,
  editable: boolean,
  setEditable: React.Dispatch<React.SetStateAction<boolean>>
  intl: IntlShape,
  dashboard: Dashboard | null,
}

function EditDeleteButtons(props: EditDeleteButtonsProps) {
  return (
    <div className={"d-flex flex-row justify-content-end"}>
      { !props.editable && <button className={"p3 outline-button mr-2"}  onClick={() => { props.setEditable(true) }}>
        {props.intl.messages['edit']}
      </button>}
      <button className={"p3 outline-button"}  onClick={(e) => {
        e.stopPropagation();
        confirmAlert({
          title: props.intl.messages["confirm"].toString(),
          message: props.intl.messages["this_action_cant_be_undone"].toString(),
          buttons: [
            {
              label: 'Yes',
              onClick: () => props.dispatch({
                type: "DELETE_DASHBOARD",
                dashboard: props.dashboard
              })
            },
            {
              label: 'No',
              onClick: () => {}
            }
          ]
        });
      }}>
        {props.intl.messages['delete']}
      </button></div>
  );

}