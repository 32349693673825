import React from 'react';
import MenuItemType from "../models/menu_item_type";
import {SidebarItem} from "./sidebar_item";
import {MenuIcon} from "./icons";
import {BASE_URL} from "../index";
import {EventsExplorerFavouritePanel} from "../pages/data_explorer_page/events_explorer_favourite_panel";

type Props = {
  selectedItem: MenuItemType
}

export  function Sidebar(props: Props) {
  return (
    <div className={"c-sidebar c-sidebar-fixed c-sidebar-lg-show"} id="sidebar">
      <div className={"row d-lg-none"}>
        <div className={"col-md-12 d-flex flex-row justify-content-between"}>
          <img src={`${BASE_URL}/api/v1/owner-configuration/logo`} alt={"Product logo"} className={"mt-4 pt-3 mb-4 product-logo"}/>
          {/*<button className="c-header-toggler c-class-toggler d-lg-none menu-button m-2 mt-4 pt-1" type="button" data-target="#sidebar"*/}
          {/*        data-class="c-sidebar-show">*/}
          {/*  <MenuIcon white/>*/}
          {/*</button>*/}
        </div>
      </div>

      <div className={"c-sidebar-brand d-md-down-none sidebar-color-override mb-2"} id={"logo"}>
        <img src={`${BASE_URL}/api/v1/owner-configuration/logo`} alt={"Product logo"} className={"product-logo"}/>
      </div>
      <ul className={"c-sidebar-nav ps ps--active-y"}>
        <li className={"c-sidebar-nav-item"}>
          <SidebarItem
            active={props.selectedItem === "Users" || props.selectedItem === "Admin" || props.selectedItem === "Groups"}
            name={"Admin"}
          />
          <SidebarItem
            active={props.selectedItem === "Users"}
            visible={ props.selectedItem === "Users" || props.selectedItem === "Admin" || props.selectedItem === "Groups"}
            secondary
            name={"Users"}
          />
          <SidebarItem
            active={props.selectedItem === "Groups"}
            visible={ props.selectedItem === "Users" || props.selectedItem === "Admin" || props.selectedItem === "Groups"}
            secondary name={"Groups"}
            linkTo={"/user-groups"}
          />
          <SidebarItem active={props.selectedItem === "Dashboards"} name={"Dashboards"}/>
          <SidebarItem active={props.selectedItem === "Meters"} name={"Meters"} />
          <SidebarItem active={props.selectedItem === "Data Explorer"} name={"Data Explorer"} />
          {/*{props.selectedItem === "Data Explorer" && <EventsExplorerFavouritePanel/>}*/}
          <SidebarItem active={props.selectedItem === "Network"} name={"Network"}/>
        </li>
      </ul>
    </div>
  );
}

