import {GeoJSONFeature, GeoJSONPoint, GeoJSONPolygon} from "../../models/geo_json";

import React, {ReactElement} from "react";

import './leafleet_map.css'
// @ts-ignore
import {EditControl} from "react-leaflet-draw"

export function RenderEditor(
  item: GeoJSONFeature | null,
  onEdit: (g: GeoJSONFeature | null) => void,
  setter: React.Dispatch<React.SetStateAction<GeoJSONFeature | null>>,
  type: "Point" | "Polygon" = "Polygon"
): ReactElement {
  switch (item?.geometry?.type ?? type) {
    case "Point":
      return RenderPointEditor(item as GeoJSONPoint, onEdit, setter);
  }
  return RenderPolygonEditor(item as GeoJSONPolygon, onEdit, setter);
}

export function RenderPointEditor(
  item: GeoJSONPoint | null,
  onEdit: (g: GeoJSONFeature | null) => void,
  setter: React.Dispatch<React.SetStateAction<GeoJSONFeature | null>>
): ReactElement {
  return <EditControl
    position='topright'
    onEdited={(e: any) => {

      let feature: GeoJSONFeature | null = e?.layers?.toGeoJSON()?.features[0] ?? null;
      if(feature !== null) {
        feature = {...feature, properties: {...feature.properties, ...item?.properties}};
      }
      onEdit(feature);
      setter(feature);
    }}
    onCreated={(e: any) => {
      let feature: GeoJSONFeature | null = e?.layer?.toGeoJSON() ?? null;

      if(feature !== null) {
        feature = {...feature, properties: {...feature.properties, ...item?.properties}};
      }

      onEdit(feature);
      setter(feature);


    }}
    onDeleted={() => {
      const coords = null;
      onEdit(coords)
      setter(null)
    }}

    draw={{
      rectangle: false,
      circle: false,
      marker: true,
      polyline: false,
      polygon: false,
      circlemarker: false
    }}

    edit={{
      edit: false,
      remove: false,
    }}
  />
}

export function RenderPolygonEditor(
  item: GeoJSONPolygon | null,
  onEdit: (g: GeoJSONFeature | null) => void,
  setter: React.Dispatch<React.SetStateAction<GeoJSONFeature | null>>
): ReactElement {
  return  <EditControl
    position='topright'
    onEdited={(e: any) => {

      let feature: GeoJSONFeature | null = e?.layers?.toGeoJSON()?.features[0] ?? null;
      if(feature !== null) {
        feature = {...feature, properties: {...feature.properties, ...item?.properties}};
      }
      onEdit(feature);
      setter(feature);
    }}
    onCreated={(e: any) => {
      let feature: GeoJSONFeature | null = e?.layer?.toGeoJSON() ?? null;
      if(feature !== null) {
        feature = {...feature, properties: {...feature.properties, ...item?.properties}};
      }
      onEdit(feature);
      setter(feature);
    }}
    onDeleted={() => {
      const coords = null;
      onEdit(coords)
      setter(null)
    }}

    draw={{
      rectangle: false,
      circle: false,
      marker: false,
      polyline: false,
      polygon: true,
      circlemarker: false
    }}

    edit={{
      edit: true,
      remove: false,
    }}
  />
}