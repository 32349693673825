import React, {useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../store/store";
import {useIntl} from "react-intl";
import {UnderLineInputText} from "./under_line_input_text";
import {FilterIcon} from "./icons";
import {Log} from "../models/log";
import {defaultLogFormatter} from "../utils/object_formatting";


type Props = {
  filter?: (a: Log) => boolean
  eventName?: string
  formatter?: (a: Log) => {message: string, color: string}
}


export function LogPanel(props: Props) {

  const logs: Log[] = useSelector((s: AppState) => {
    return s.logs.items;
  });


  const [query, setQuery] = useState("");

  const formatter = props.formatter ?? defaultLogFormatter;

  const intl = useIntl();
  const externalFilter = props.filter || ((_: Log) => true);
  const eventNameFilter = (l: Log) => !props.eventName || l.eventName === props.eventName ;

  const filter = (log: Log) => externalFilter(log) && eventNameFilter(log) && JSON.stringify(log).toLowerCase().includes(query.toLowerCase());

  return (<div >
    <div className={"d-flex flex-row justify-content-between mb-1"}>
      <h3 className={"mt-2"}>LOGS</h3>
      <div className={"d-flex flex-row justify-content-start align-items-center"}>
        <div className={"mr-2"}><FilterIcon/></div>
        <UnderLineInputText hint={intl.messages["filter"].toString()} value={query} onChange={setQuery}/>
      </div>
    </div>

    <div className={"log-panel ellipsis"}>
      {
        logs.filter(filter).length === 0 && <p>{ intl.messages["no_data"].toString() }</p>
      }
      {
        logs.filter(filter).map(l => {
          const formatted = formatter(l);
          return <p style={{color: formatted.color}} key={JSON.stringify(l)}>{formatted.message}</p>;
        })
      }
    </div>
  </div>)

}