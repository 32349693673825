import {StatConfig} from "../../models/stat_config";

export type FetchStatConfigs = {
  type: "FETCH_STATS_CONFIGS"
}

export type SetStatConfigs = {
  type: "SET_STATS_CONFIGS",
  statConfigs: StatConfig[]
}

export type SetStatConfigError = {
  type: "SET_STATS_CONFIGS_LOADING_ERROR",
  error: string | null,
}

export type StatConfigActions =
  | FetchStatConfigs
  | SetStatConfigs
  | SetStatConfigError

export function fetchStatsConfigAction(): FetchStatConfigs {
  return {type: "FETCH_STATS_CONFIGS"};
}

export function setStatConfigsAction(statConfigs: StatConfig[]): SetStatConfigs {
  return  {
    type: "SET_STATS_CONFIGS",
    statConfigs
  }
}

export function setStatConfigErrorAction(error: string | null): SetStatConfigError {
  return  {
    type: "SET_STATS_CONFIGS_LOADING_ERROR",
    error,
  }
}
