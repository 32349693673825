import React from "react";
import {httpDelete, postFormData} from "../../../repsitory/generic_repository";

export type FirmwareCreationModalState = {
  firmware: File | null
  name: string
  error: string
  loading: boolean
}

function validate(state: FirmwareCreationModalState, intl: Record<string, string>): string[] {
  let errors: string[] = [];

  if(state.firmware === null) errors = [intl["firmware_must_be_present"], ...errors]
  if(state.name === "") errors = [intl["name_must_be_present"], ...errors]

  return [];
}

export async function uploadFirmware(state: FirmwareCreationModalState, setState:React.Dispatch<React.SetStateAction<FirmwareCreationModalState>>, callback: () => void, messages: Record<string, string>) {
  const errors = validate(state, messages);
  if(errors.length > 0) {
    setState({...state, error: errors.join(" ")});
    return
  }

  setState({...state, loading: true});

  const dataKeys = new FormData();
  dataKeys.append("name", state.name);
  dataKeys.append("firmware", state.firmware!);
  const keysResponse = await postFormData(dataKeys, "/api/v1/firmware");
  if(keysResponse.status !== 200 && keysResponse.status !== 201) { setState({...state, error: messages["cant_upload_firmware"], loading: false}) }
  else {
    setState({...state, loading: false});
    callback();
  }

}

export const deleteFirmware = async (id: string | number) => {
  try {
    const result = await httpDelete('/api/v1/firmware/' + id.toString());
    if(result.status === 204) return "";
    return result.status.toString();

  } catch (e) {
    console.log(e);
    return e.toString();
  }
}