export type GatewayConfig = {
  hardware: GatewayHardwareConfig[],
  application: GatewayApplicationConfig[],
}

export function emptyGatewayConfig(): GatewayConfig {
  return {
    hardware: [],
    application: [],
  }
}

export type GatewayHardwareConfig = {
  version: string,
  port: string,
  name: string,
  configurations: any
}

export type GatewayApplicationConfig = {
  version: string,
  name: string,
  configurations: any
}

