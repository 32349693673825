import {Dispatch, Middleware, MiddlewareAPI} from "redux"
import store from "../store";
import {subscribeSocket} from "./log_effects";
import {fetchDashboardsAction} from "../actions/dashboard_actions";
import {fetchStatsConfigAction} from "../actions/stat_config_actions";
import {AuthAction} from "../actions/auth_actions";

export const authEffects: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch) => ((action: AuthAction) => {


  switch (action.type) {
    case "SET_TOKEN":
      const result = next(action);
      window.sessionStorage.setItem("swarm-token", action.token);

      store.dispatch(fetchDashboardsAction());
      store.dispatch(fetchStatsConfigAction());
      subscribeSocket(store);

    default: next(action)
  }

}) as Dispatch;