import React, {useState} from "react";
import Select from "react-select";
import {useIntl} from "react-intl";
import {Group} from "../../../models/group";
import {Link} from "react-router-dom";
import moment from "moment";
import {JobScheduleForm} from "../../../components/job_schedule_form/job_schedule_form";
import {JobSchedule} from "../../../components/job_schedule_form/job_schedule_form_vm";
import {createScheduleHighFrequencyJob, ScheduleHighFrequencyReadState} from "./schedule_high_frequency_page_vm";
import {history} from "../../../index";
import AsyncSelect from "react-select/async";
import {remoteFetchData} from "../../../repsitory/generic_repository";


export function ScheduleHighFrequencyRead() {
  const intl = useIntl();
  const [state, setState] = useState<ScheduleHighFrequencyReadState>({
    defaultGroup: "",
    meters: "",
    start: new Date(),
    stop: new Date(),
    samplingFreq: 15,
    jobSchedule: {
      type: "job",
      date: new Date(),
    },
    error: ""
  });

  return (<div>
    <div className={"row"}>
      <div className={"col-md-12 pt-1"}>
        <h3 className={"mt-3"}>{intl.messages["default_group"].toString().toUpperCase()}</h3>
        <AsyncSelect
          defaultValue={{label: "", value: -1}}
          onChange={(g: any) => setState({...state, defaultGroup: g.value })}
          isMulti={false}
          cacheOptions
          defaultOptions
          loadOptions={ (input: string) => {
            return remoteFetchData<Group[]>(`/api/v1/group?name=${input}`).then((gr) => {
              if(typeof gr !== "string") {
                return gr.map((g) => ({ value: g.attributes.id, label: g.attributes.name }));
              } else {
                setState({...state, error: intl.messages["cannot_fetch_meter_groups"].toString()})
                return [];
              }
            })
          } }

          name="groups"
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12 pt-1"}>
        <h3 className={"mt-3"}>{intl.messages["meters"].toString().toUpperCase()}</h3>
        <p className={"mt-2"}>{state.meters.toString()}</p>
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12"}>
        <h3 className={"mt-3"}>{intl.messages["start"].toString().toUpperCase()}</h3>
        <input
          className="form-control"
          type="date"
          data-date-format="DD/MM/YYYY"
          defaultValue={state.start.toDateString()}
          value={moment(state.start).format("YYYY-MM-DD")}
          onChange={ (s) => setState({...state, start: new Date(s.target.value)}) }
        />
      </div>
    </div>
    <div className={"row mt-2"}>
      <div className={"col-md-12"}>
        <h3 className={"mt-3"}>{intl.messages["stop"].toString().toUpperCase()}</h3>
        <input
          className="form-control"
          type="date"
          data-date-format="DD/MM/YYYY"
          defaultValue={state.stop.toDateString()}
          value={moment(state.stop).format("YYYY-MM-DD")}
          onChange={ (s) => setState({...state, stop: new Date(s.target.value)}) }
        />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-md-12 pt-1 mb-3"}>
        <h3 className={"mt-3"}>{intl.messages["sampling_freq"].toString().toUpperCase()}</h3>
        <Select
          defaultValue={[state.samplingFreq]}
          onChange={(g) => setState({...state, defaultGroup: g?.toString() || ""})}
          isMulti={false}
          options={[15, 30, 45, 60].map((g) => ({ value: g, label: `${g} min` }))}
          name="groups"
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
    </div>
    <JobScheduleForm jobSchedule={state.jobSchedule} setJobSchedule={(jobSchedule: JobSchedule) => setState({...state, jobSchedule})}/>

    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button
          className={"primary-button ml-2"}
          onClick={() => {
            createScheduleHighFrequencyJob(state, setState, history, intl.messages as any)
          }}
        >
          {intl.messages["schedule"].toString()}
        </button>
        <Link className={"outline-button"} to={"/meters"}>{"Cancel"}</Link>
        {state.error !== "" && <p className={"error-message"}>{state.error}</p>}
      </div>
    </div>
  </div>);
}