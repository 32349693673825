import {MetersStats} from "../../models/meter_stats";

export const FETCH_METERS_STATS = 'FETCH_METERS_STATS';
export const SET_METERS_STATS = 'SET_METERS_STATS';
export const SET_METERS_STATS_ERROR = 'SET_METERS_STATS_ERROR';

export interface MeterStatsAction {
  type: string,
  meterStats?: MetersStats
  error?: string
}

export const fetchMeterStatsAction = () => ({
  type: FETCH_METERS_STATS,
});

export const setMeterStatsAction = (meterStats: MetersStats) => ({
  type: SET_METERS_STATS,
  meterStats
});

export const setMeterStatsErrorAction = (error: string) => ({
  type: SET_METERS_STATS_ERROR,
  error
});