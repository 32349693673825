import {confirmAlert} from "react-confirm-alert";
import React, {useState} from "react";

import Select from "react-select";
import _ from "lodash";
import {EditableRow} from "../../../components/InfoRow";
import {Filter, FilterName} from "../../../models/filter";
import {DataSource, filterPrototypes, formatValueName} from "../ast/ast";
import {Group} from "../../../models/group";
import AsyncSelect from "react-select/async";
import {remoteFetchData} from "../../../repsitory/generic_repository";
import DatePicker from "react-datepicker";

export async function showFilterCreation(intl: Record<string, string>, onCreate: (filter: Filter) => void, dataSource: DataSource, only?: string) {
  confirmAlert({
    customUI: ({onClose}) =>
      <div
        style={{minHeight: 150, backgroundColor: "white"}}
        className={"configuration-editor p-3 pt-4"}
      >
        <CreateFilterWidget intl={intl} onCreate={(f) => {
          onCreate(f);
          onClose();
        }} only={only} close={onClose} dataSource={dataSource}/>
      </div>
  });
}

function CreateFilterWidget(props: {intl: Record<string, string>,  onCreate: (filter: Filter) => void, dataSource: DataSource, only?: string, close: () => void}, ) {


  const filteredFilterPrototypes = filterPrototypes[props.dataSource ?? "no_data_source"].filter((p) => p.name === (props.only ?? p.name))

  const [selectedPrototype, setSelectedPrototype] = useState<Filter>(_.first(filteredFilterPrototypes)!);
  const [error, setError] = useState("");

  return <div>
    <div className={"row "}>
      <div className={"col-md-12 mb-4"}>
        <h3>{props.intl["type"].toString().toUpperCase()}</h3>
        <Select
          value={ {label: formatValueName(selectedPrototype.name, props.intl), value: selectedPrototype} }
          onChange={(t: any) => setSelectedPrototype(t.value)}
          isMulti={false}
          options={filteredFilterPrototypes.map((fp => ({value: fp, label: formatValueName(fp.name, props.intl)})))}
          name="device type id"
          className="basic-multi-select mb-4"
          classNamePrefix="select"
        />
        <InputForFilterName
          filterName={selectedPrototype.name}
          value={selectedPrototype.value}
          onValue={(s) => setSelectedPrototype({...selectedPrototype, value: s})}
          intl={props.intl}
        />
      </div>
    </div>
    <div className={"row mt-4"}>
      <div className={"col-md-12 d-flex flex-row-reverse"}>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          if(selectedPrototype.value === "") { setError(props.intl["please-select-a-value"]); return; }
          props.onCreate(_.cloneDeep(selectedPrototype));
        }}>{props.intl["ok"]}</button>
        <button className={"outline-button dashboard-editor-icon"} onClick={async () => {
          props.close()
        }}>{props.intl["close"]}</button>
        <p className={"error-message"}>{error}</p>
      </div>
    </div>
  </div>
}



function InputForFilterName(props: {filterName: FilterName, value: string, onValue: (s: string) => void, intl: Record<string, string>},) {
  switch (props.filterName) {
    case "max_network_greater":
            return <EditableRow
      title={formatValueName(props.filterName, props.intl)}
      value={ props.value }
      onEdit={(v) => {
        if(!isNaN(Number(v))) props.onValue(v)
        else props.onValue("0")
      }}
    />
    case "max_network_smaller":
            return <EditableRow
      title={formatValueName(props.filterName, props.intl)}
      value={ props.value }
      onEdit={(v) => {
        if(!isNaN(Number(v))) props.onValue(v)
        else props.onValue("0")
      }}
    />
    case "min_network_greater":
            return <EditableRow
      title={formatValueName(props.filterName, props.intl)}
      value={ props.value }
      onEdit={(v) => {
        if(!isNaN(Number(v))) props.onValue(v)
        else props.onValue("0")
      }}
    />
    case "min_network_smaller":
            return <EditableRow
      title={formatValueName(props.filterName, props.intl)}
      value={ props.value }
      onEdit={(v) => {
        if(!isNaN(Number(v))) props.onValue(v)
        else props.onValue("0")
      }}
    />
    case "gateway_id":
      return <EditableRow
      title={formatValueName(props.filterName, props.intl)}
      value={ props.value }
      onEdit={props.onValue}
    />
    case "ldn": return <EditableRow
      title={formatValueName(props.filterName, props.intl)}
      value={ props.value }
      onEdit={props.onValue}
    />

    case "pdr": return <EditableRow
      title={formatValueName(props.filterName, props.intl)}
      value={ props.value }
      onEdit={props.onValue}
    />

    case "group":
      return <AsyncSelect
        defaultValue={{label: "", value: -1}}
        onChange={(g: any) => props.onValue(g.label)}
        isMulti={false}
        cacheOptions
        defaultOptions
        loadOptions={ (input: string) => {
          return remoteFetchData<Group[]>(`/api/v1/group?name=${input}`).then((gr) => {
            if(typeof gr !== "string") {
              return gr.map((g) => ({ value: g.attributes.id, label: g.attributes.name }));
            } else {
              return [];
            }
          })
        } }
        name="groups"
        className="basic-multi-select"
        classNamePrefix="select"
      />
    case "with_errors":
      return <Select
        onChange={(g: any) => {
          props.onValue(g.label);
        }}
        defaultValue={{label: "true", value: "true"}}
        isMulti={false}
        options={[{label: "true", value: "true"}, {label: "false", value: "false"}]}
        name="true false"
        className="basic-multi-select select-sizing"
        classNamePrefix="select"
      />
    case "date_greater":
      return <div className={"row mt-4"}>
        <div className={"col-md-12"}>
          <h3> {formatValueName(props.filterName, props.intl).toUpperCase()}</h3>
          <DatePicker
            className={"form-control"}
            selected={props.value !== "" ? new Date(props.value): null}
            onChange={ (date: Date) => props.onValue(date.toISOString())}
            showTimeSelect
            dateFormatCalendar={"dd-MM-yyyy"}
            timeFormat="HH:mm"
            timeIntervals={5}
            timeCaption="time"
            dateFormat="dd-MM-yyyy h:mm aa"
          />
        </div>
      </div>
    case "date_smaller":
      return <div className={"row mt-4"}>
        <div className={"col-md-12"}>
          <h3> {formatValueName(props.filterName, props.intl).toUpperCase()} </h3>
          <DatePicker
            className={"form-control"}
            selected={props.value !== "" ? new Date(props.value): null}
            onChange={ (date: Date) => props.onValue(date.toISOString())}
            showTimeSelect
            dateFormatCalendar={"dd-MM-yyyy"}
            timeFormat="HH:mm"
            timeIntervals={5}
            timeCaption="time"
            dateFormat="dd-MM-yyyy h:mm aa"
          />
        </div>
      </div>
  }
}
