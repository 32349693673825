import React from "react";
import {GatewayConfig,} from "../../../models/gateway_config";
import {HardwareType} from "../../../models/hardware_type";
import {ApplicationType} from "../../../models/application_type";
import {GatewayHardwareConfigPanel} from "./gateway_hardware_config_panel";
import {GatewayApplicationConfigPanel} from "./gateway_application_config_panel";

type Props = {
  config: GatewayConfig,
  hardwareTypes: HardwareType[],
  applicationTypes: ApplicationType[],
  onConfigurationChange: (gc: GatewayConfig) => void
}

export function GatewayConfigPanel(props: Props) {

  return <div className={"row"}>

      <GatewayHardwareConfigPanel
        config={props.config}
        hardwareTypes={props.hardwareTypes}
        onConfigurationChange={props.onConfigurationChange}
      />

      <GatewayApplicationConfigPanel
        config={props.config}
        applicationTypes={props.applicationTypes}
        onConfigurationChange={props.onConfigurationChange}
      />
  </div>
}