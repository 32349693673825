import React from "react";
import {AddReduceFilterWidget} from "./add_reduce_filter_widget";

export function AddOrReduceFilterWidget(props: {
  onAdd: () => void,
  onReduce: () => void,
  onReduceChart: () => void
  addText: string
  reduceText: string
  reduceChartText: string
}) {
  return <div className={"d-flex flex-column"} style={{height: 111}}>
    <AddReduceFilterWidget onTap={props.onAdd} text={props.addText}/>
    <AddReduceFilterWidget onTap={props.onReduce} text={props.reduceText}/>
    <AddReduceFilterWidget onTap={props.onReduceChart} text={props.reduceChartText}/>
  </div>
}