import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {EditableRow} from "../../components/InfoRow";

import {history} from "../../index";
import {AdminStoragePageState, getInitialState, testConnection, updateConfiguration} from "./admin_storage_page_vm";
import {remoteFetchData} from "../../repsitory/generic_repository";
import Select from 'react-select';
import {TestConnectionButton} from "../../components/test_connection_button/test_connection_button";

// @ts-ignore
import Loader from 'react-loader-spinner'


export function AdminStoragePage() {

  const [state, setState] = useState<AdminStoragePageState>({
    address: "", password: "", user: "", error: "", type: "", dbName: "", loading: false
  })

  const [types, setTypes] = useState<{value: string, label: string}[]>([]);

  useEffect(() => {
    getInitialState().then(setState);
    remoteFetchData<{attributes: {type: string}}[]>("/api/v1/storage-configuration-type").then((r) => {
      if(typeof r === "string") setState({...state, error: r});
      else {
        setTypes(r.map(i => ({label: i.attributes.type, value: i.attributes.type})))
      }
    })

  }, [1])

  const intl = useIntl();
  return (
    <div>
      <EditableRow title={intl.messages["dest_address"].toString()} value={ state.address } onEdit={(s) => setState({...state, address: s})}/>
      <EditableRow title={intl.messages["db_name"].toString()} value={ state.dbName } onEdit={(s) => setState({...state, dbName: s})}/>
      {/*<EditableRow title={intl.messages["type"].toString()} value={ state.type } onEdit={(s) => setState({...state, type: s})}/>*/}
      <div className={"row mb-4"}>
        <div className={"col-md-12"}>
          <h3>{intl.messages["type"].toString().toUpperCase()}</h3>
          <Select
            value={ {label: state.type, value: state.type} }
            onChange={(t: any) => setState({...state, type: t.value})}
            isMulti={false}
            options={types as any}
            name="device type id"
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </div>

      <EditableRow title={intl.messages["user"].toString()} value={ state.user } onEdit={(s) => setState({...state, user: s})}/>
      <EditableRow title={intl.messages["password"].toString()} value={ state.password } onEdit={(s) => setState({...state, password: s})} hidden/>
      <div className={"row mt-3"}>
        <div className={"col-md-12 ellipsis mt-1 d-flex justify-content-between"}>
          <TestConnectionButton
            intl={intl.messages as any}
            key={`${state.address}${state.user}${state.password}${state.dbName}`}
            onTap={() => testConnection({address: state.address, db_name: state.dbName, password: state.password, user: state.user}, intl.messages as any)}
          />
          <div className={"d-flex flex-row-reverse"}>
            {!state.loading ? <button className={"p3 primary-button"}  onClick={async () => {
              setState({...state, loading: true})
              setState(await updateConfiguration(state, history, intl.messages as any))
            }}>
              {intl.messages['save']}
            </button> : <Loader type="ThreeDots" color="#3C4B64" height={30} width={40} />}
            <Link to={ "/admin" } style={{ textDecoration: 'none', color: '#fff' }}>
              <div className={"p3 outline-button mr-3"}>
                {intl.messages['cancel']}
              </div>
            </Link>
            {state.error && <p className={"error-message mt-2 mr-4"}>{state.error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}