import {AltiorMeterType} from "../../models/altior_meter_type";
import {AltiorGatewayType} from "../../models/altior_gateway_type";
import {IndexTable} from "../../components/index_table";
import React, {useEffect, useState} from "react";
import {fetchAltiorGatewayTypes} from "../gateways_page/edit_gateway_page_vm";
import {fetchMeterTypes} from "../meters_page/edit_meter_page_vm";
import {TestConnectionButton} from "../../components/test_connection_button/test_connection_button";
import {createJob} from "./admin_ftp_page_vm";
import {history} from "../../index";
import {Link} from "react-router-dom";
import { useIntl } from "react-intl";

type Props = {

}

export function AdminDigitalTwinPage(props: Props) {

  const [altiorMeterTypes, setAltiorMeterTypes] = useState<AltiorMeterType[]>([]);
  const [altiorGatewayTypes, setAltiorGatewayTypes] = useState<AltiorGatewayType[]>([]);
  const intl = useIntl();

  useEffect(() => {
    (async () => {
      const altiorGatewayTypes = await fetchAltiorGatewayTypes();
      if(typeof altiorGatewayTypes !== "string" && altiorGatewayTypes) { setAltiorGatewayTypes(altiorGatewayTypes); }

      const altiorMeterTypes = await fetchMeterTypes();
      if(typeof altiorMeterTypes !== "string" && altiorMeterTypes) { setAltiorMeterTypes(altiorMeterTypes);  }

    })();

  }, [0])

  return <div>
    <h2>Meters</h2>
    <IndexTable
      headers={[
        "name",
        "description",
        "class",
        "protocol",
        "version"
      ]}
      columns={altiorMeterTypes}
      rowBuilder={
        (amt) => [
          <p>{amt.name}</p>,
          <p>{amt.description}</p>,
          <p>{amt.deviceClass}</p>,
          <p>{amt.protocol}</p>,
          <p>{amt.version}</p>
        ]
      }
      paginationVisible={false}
    />
    <h2>Gateways</h2>
    <IndexTable
      headers={[
        "name",
        "description",
        "class",
        "protocol",
        "version"
      ]}
      columns={altiorGatewayTypes}
      rowBuilder={
        (amt) => [
          <p>{amt.attributes.name}</p>,
          <p>{amt.attributes.description}</p>,
          <p>{amt.attributes.deviceClass}</p>,
          <p>{amt.attributes.protocol}</p>,
          <p>{amt.attributes.version}</p>
        ]
      }
      paginationVisible={false}
    />
    <div className={"row mt-3"}>
      <div className={"col-md-12 ellipsis mt-1"}>

        <div className={"d-flex flex-row-reverse"}>

          <Link to={ "/admin" } style={{ textDecoration: 'none', color: '#fff', marginRight: -1 }}>
            <div className={"p3 outline-button mr-3"}>
              {intl.messages['close']}
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
}