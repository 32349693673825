import {Dispatch, Middleware, MiddlewareAPI} from "redux"
import {FETCH_METERS, setMetersAction, setMetersErrorAction} from "../actions/meters_actions";
import {Meter} from "../../models/meter";
import {remoteFetchData} from "../../repsitory/generic_repository";


export const metersEffects: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch) => ((action: any) => {


  switch (action.type) {
    case FETCH_METERS:
      console.log("[Meters effect] fetch meters");
      remoteFetchData<Meter[]>("/api/v1/meter")
        .then((m) => {
          if(typeof m === "string") {
            console.log("[Meters effect] error" + m);
            api.dispatch(setMetersErrorAction(m));
          } else {
            api.dispatch(setMetersAction(m));
            console.log("[Meters effect] success");
          }
          next(action)
        })
        .catch(e => {
          api.dispatch(setMetersErrorAction(e));
          console.log("[Meters effect] error" + e);
          next(action)
        }); break;

    default: next(action)
  }

}) as Dispatch;