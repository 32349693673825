import React, {Component} from "react";
import {WidgetConfig} from "../../../../../models/dashboard/widgetConfig";
import {
  queryReducedChartValue,
  queryReducedValue,
  reducedValueQueryUrl
} from "../../../../data_explorer_page/reduce_filter_vm";
import {bigNumberFormatter, formatHeader, formatObject} from "../../../../../utils/object_formatting";
import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {prune_max_len} from "../../../../../utils/arrays";
import {LoaderComponent} from "../../../../../components/loading_widget";
import {timer} from "rxjs";
import {Subscription} from "@reactivex/rxjs/dist/package";

type Props = {
  widget: WidgetConfig,
  intlMessages: Record<string, any>
  x_column_name: string
  y_column_name: string
}

type NumericTypeWidgetState = {
  value: any
  handle?: Subscription
}

export function DEXChartWidget(props: Props) {
  return <ChartWidgetClass intlMessages={props.intlMessages} widget={props.widget} x_column_name={props.x_column_name} y_column_name={props.y_column_name}/>
}

class ChartWidgetClass extends Component<Props, NumericTypeWidgetState>{

  constructor(props: Props) {
    super(props);
    this.setState({value: undefined});
  }

  async update(widget: WidgetConfig, x_name: string) {
    this.setState({...this.state, value: await extractValue(widget, x_name)})
  }

  componentDidMount() {
    const t = timer(25, 30000);
    const handle = t.subscribe(() => this.update(this.props.widget, this.props.x_column_name))
    this.setState({
      handle
    })
  }

  componentWillUnmount() {
    this?.state?.handle?.unsubscribe();
    this.setState({...this.state, handle: undefined})
  }

  render() {
    const name = this.props.intlMessages[this.props.widget.name] ?? this.props.widget.name;


    if(!this?.state?.value) return (<div className={"d-flex flex-column justify-content-between title-value-widget"}>
      <div className={"row"}>
        <div className={"col-md-12 mb-2"}>
          <h3 style={{color: "#fff"}} className={"m-2"}> {name.toUpperCase().replace("_", " ") ?? ""}

          </h3>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12 d-flex justify-content-center align-items-center"} style={{marginBottom: 40}}>
          <LoaderComponent/>
        </div>
      </div>
    </div>);

    let values = this?.state?.value ?? [];

    if(typeof values === "string") return (<div className={"d-flex flex-column justify-content-between title-value-widget"}>
      <div className={"row"}>
        <div className={"col-md-12 mb-2"}>
          <h3 style={{color: "#fff"}} className={"m-2"}> {name.toUpperCase().replace("_", " ") ?? ""} </h3>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12"} style={{marginBottom: 32}}>
          <p className={"error-message"} style={{color: "#ff6f6f"}}>{values}</p>
        </div>
      </div>
    </div>);

    return (<div className={"d-flex flex-column justify-content-between title-value-widget"}>
      <div className={"row"}>
        <div className={"col-md-12 mb-2"}>
          <h3 style={{color: "#fff"}} className={"m-2"}>
            { `${name.toUpperCase().replace("_", " ") ?? ""}  |    ${formatHeader(this.props.y_column_name)} vs ${formatHeader(this.props.x_column_name)}` }

          </h3>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12"} style={{marginBottom: 16}}>
          <ResponsiveContainer width={"100%"} height={220} className={"dashboard-chart"}>
            <LineChart data={prune_max_len(values, 10)}>
              <Line type="monotone" dataKey={this.props.y_column_name} stroke="#eee" />
              <XAxis dataKey={this.props.x_column_name} stroke={"#eee"} />
              <YAxis stroke={"#eee"} tickFormatter={bigNumberFormatter} />
              <Tooltip
                labelStyle={{color: "black"}}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>);
  }

}

export async function extractValue(widgetConfig: WidgetConfig, x_column_name: string): Promise<string | any[]> {

  const source = widgetConfig.source;

  if(source.type !== "WEBSOCKET") {
    const result = await queryReducedChartValue(source.pollingUrl);
    if(typeof result === "string") return result.replace("_", " ");
    if(typeof result.attributes === "string") return result.attributes.replace("_", " ");
    return (result.map((i: any) => i.attributes).map((i: any) => {
      //  @ts-ignore
      i[x_column_name] = formatObject(i[x_column_name])
      return i
    }));
  }
  return '--'
}
