import React, {Dispatch} from "react";
import {post, put} from "../../repsitory/generic_repository";
import * as H from 'history'
import {GeoJSONPolygon} from "../../models/geo_json";

//  Page state
export type EditGroupPageState = {
  name: string,
  totalMeters: number,
  polygon: number[][],
  loading: boolean,
  error: string,
  geom: GeoJSONPolygon | null,
  validationErrors: string[],
  tags: any
  color: string
  id?: number
}

export type Property = {name: string, value: string,}


const validate = (state: EditGroupPageState) => {
  let errors: string[] = [];

  if(state.geom === null) errors.push("Polygon must be set");
  if(state.name.length === 0) errors.push("Name must be set");

  return errors;
};

//  Page effects
export const createEditGroup = async(state: EditGroupPageState, setState: React.Dispatch<React.SetStateAction<EditGroupPageState>>, dispatch: Dispatch<any>, history: H.History) =>  {

  const validation = validate(state);

  if(validation.length > 0) {
    setState({...state, validationErrors: validation});
    return;
  } else {
    setState({...state, loading: true})
  }



  const data = {
    "data": {
    "type": "group",
      "attributes": {
      "name": state.name,
      "polygon": state.polygon.flatMap(id => {const [x, y] = id; return [y, x]}),
      "geom": state.geom ?? null,
      "tags": state.tags
    }
  }};


  try {
    let result;

    if(state.id === undefined) {
      result = await post(data,  '/api/v1/group');
    } else {
      result = await put(data,  `/api/v1/group/${state.id}`);
    }

    console.log(result);

    if(result.errors !== undefined) {
      setState({...state, error: JSON.stringify(result), loading: false})
    } else {
      setState({...state, loading: false});
      history.push('/meters');
    }
  } catch (e) {
    setState({...state, error: e.toString(), loading: false})
  }
};

export function configToProperties(config: Object): Property[] {
  let properties: Property[] = [];

  for(const index in Object.keys(config ?? {})) {
    properties = [
      ...properties,
      {
        name: Object.keys(config)[index],
        value: Object.values(config)[index].toString()
      }
    ];
  }

  return properties;
}

export function propertiesToConfig(properties: Property[]): Object {
  let config: any = {}

  for(const cv of properties) {
    config[cv.name] = cv.value;
  }

  return config;
}