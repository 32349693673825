import {ColumnTransform} from "../models/filter";
import {permute} from "../utils/arrays";

export function applyColumnTransform<T>(items: T[], columnTransform: ColumnTransform) {
  let safeColumnTransform = columnTransform;
  if(safeColumnTransform?.length === 0) safeColumnTransform = items.map((_, i) => i);
  let p = items;
  try{
    p = permute(items, safeColumnTransform);
  } catch (e) {

  }
  return p
}
