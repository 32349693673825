import {Dispatch, Middleware, MiddlewareAPI} from "redux"
import {remoteFetchAttributes} from "../../repsitory/generic_repository";
import {setGatewaysStatsAction, setGatewaysStatsErrorAction} from "../actions/gateways_stats_actions";


export const gatewayStatsEffects: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch) => ((action: any) => {


  switch (action.type) {
    case "FETCH_GATEWAYS_STATS":
      console.log("[GatewayStats effect] fetch metersStats");
      remoteFetchAttributes<any>("/api/v1/gateway_stats")
        .then((m) => {
          if(typeof m === "string") {
            console.log("[GatewayStats effect] error" + m);
            api.dispatch(setGatewaysStatsErrorAction(m));
          } else {
            api.dispatch(setGatewaysStatsAction(m));
            console.log("[Meterstats effect] success");
          }
          next(action)
        })
        .catch(e => {
          api.dispatch(setGatewaysStatsErrorAction(e.toString()));
          console.log("[GatewayStats effect] error" + e);
          next(action)
        }); break;

    default: next(action)
  }

}) as Dispatch;